import { PaymentMethodInfo, PaymentMethodResponse } from '../paymentapp';
import { getPaymentMethod } from '@fpc/api/customerapp/GetPaymentMethods';
import { useAuthContextStore } from '../../stores/auth-context.store';
import { getApiBaseUrl } from '../../helpers/http';
import { AppDomain } from '../../types/app-domain';
import httpClient, { getHeadersByAuthTokens } from '@fpc/common/HttpClient';
import { HttpHeader } from '../../types/http';

export async function getPaymentMethodDetails(
  paymentMethodId: string,
  merchantAccountId: string | null = null
): Promise<PaymentMethodInfo | null> {
  const { bffBaseUrl, customerAuthTokenType } = useAuthContextStore.getState();
  const baseUrl = getApiBaseUrl(
    AppDomain.CUSTOMER_APP,
    customerAuthTokenType,
    bffBaseUrl
  );

  const url = `${baseUrl}/customers/payment-methods/${paymentMethodId}`;

  const headers = getHeadersByAuthTokens();

  if (merchantAccountId) {
    headers[HttpHeader.MERCHANT_ACCOUNT_ID] = merchantAccountId;
  }

  const data: PaymentMethodResponse =
    await httpClient.get<PaymentMethodResponse>(url, headers);
  const paymentMethod = getPaymentMethod(data);

  return paymentMethod
    ? {
        paymentMethodId: data.paymentMethodId,
        isDefault: data.isDefault,
        type: data.type,
        paymentMethod: paymentMethod
      }
    : null;
}
