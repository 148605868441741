import { PAY_BY_BANK_METHOD } from '../../checkout';
import { useAuthContextStore } from '../../stores/auth-context.store';
import { getApiBaseUrl } from '../../helpers/http';
import { AppDomain } from '../../types/app-domain';
import httpClient, { getHeadersByAuthTokens } from '@fpc/common/HttpClient';
import { HttpHeader } from '../../types/http';

export interface CreateCardSetupIntentParams {
  stripeCustomerId: string;
  paymentMethodTypes?: string[];
  merchantAccountId?: string;
  consumerId?: string | null;
  metadata?: Map<string, string> | null;
  isConnectCustomer?: boolean;
}

export async function createCardSetupIntent(
  createCardSetupRequest: CreateCardSetupIntentParams,
  isGuestFlow: boolean | null = null
): Promise<string> {
  const { bffBaseUrl, bearerToken, customerAuthTokenType } =
    useAuthContextStore.getState();

  let paymentMethodTypes = !createCardSetupRequest.paymentMethodTypes
    ? ['CARD']
    : createCardSetupRequest.paymentMethodTypes
        .map((pm) => pm.toUpperCase())
        .filter((pm) => pm !== PAY_BY_BANK_METHOD);

  const baseUrl = getApiBaseUrl(
    AppDomain.CUSTOMER_APP,
    customerAuthTokenType,
    bffBaseUrl
  );
  const url = `${baseUrl}/customers/setup-intent`;

  const payload = {
    ...createCardSetupRequest,
    paymentMethodTypes
  };

  const headers = getHeadersByAuthTokens();

  if (isGuestFlow) {
    // This is a temporary solution for guest flows only, until we can migrate them to another auth type
    // TODO - migrate this away from using the bearerToken
    headers[HttpHeader.AUTHORIZATION] = `Bearer ${bearerToken}`;
  }

  const { client_secret: clientSecret } = await httpClient.post<{
    client_secret: string;
  }>(url, payload, headers);
  return clientSecret;
}
