import { enUS, TRANSLATION_MISSING } from './en-US';

export const frLU: typeof enUS = {
  common: {
    default: 'par d\u00e9faut',
    endingIn: 'se terminant par',
    close: 'Fermer',
    makeDefaultCard: 'D\u00e9finir comme carte par d\u00e9faut',
    makeDefaultInfoHover:
      "S'agissant de la premi\u00e8re carte de votre portefeuille, celle-ci sera automatiquement d\u00e9finie comme votre carte par d\u00e9faut.",
    processing: 'En cours de traitement...',
    saveCardCheck:
      'Enregistrer la carte pour des op\u00e9rations ult\u00e9rieures',
    saveCardSuccess: 'Carte ajout\u00e9e au portefeuille Ford.com.',
    technicalErrorPayment:
      'D\u00e9sol\u00e9s ! Un probl\u00e8me technique est survenu et votre paiement a \u00e9chou\u00e9. Veuillez r\u00e9essayer.',
    timeoutSorry: 'D\u00e9sol\u00e9s...',
    timeoutError:
      'Nous rencontrons en ce moment quelques difficult\u00e9s techniques.',
    timeoutTryAgain: 'Veuillez r\u00e9essayer',
    savePaymentMethodCheck:
      'Enregistrer le mode de paiement pour les op\u00e9rations ult\u00e9rieures',
    makeDefaultPaymentMethod:
      'D\u00e9finir comme mode de paiement par d\u00e9faut',
    savePaymentMethodButton: 'Enregistrer le mode de paiement',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      "Cela deviendra automatiquement votre moyen de paiement par d\u00e9faut, car il s'agit du premier moyen de paiement de votre portefeuille.",
    savePaymentMethodSuccess:
      'Le mode de paiement a \u00e9t\u00e9 ajout\u00e9 \u00e0 Wallet.'
  },
  checkout: {
    payButton: 'Payer {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'D\u00e9sol\u00e9s\u00a0! Un probl\u00e8me technique est survenu et votre paiement a \u00e9chou\u00e9. Veuillez actualiser la page et r\u00e9essayer.\u00a0',
    technicalErrorPaymentDetachFail:
      'D\u00e9sol\u00e9s ! Un probl\u00e8me technique est survenu et votre paiement a \u00e9chou\u00e9. Votre carte a \u00e9t\u00e9 enregistr\u00e9e dans votre portefeuille. Veuillez r\u00e9essayer.',
    tosTextBefore:
      'En cliquant sur le bouton \u00ab\u00a0Payer\u00a0\u00bb ci-dessous, vous acceptez les ',
    tosTextBeforeForFordPayGuestId:
      'En cliquant sur le bouton {{paymentButtonText}} ci-dessous, vous acceptez les ',
    tosLinkText:
      'Conditions g\u00e9n\u00e9rales de paiement et la Politique de confidentialit\u00e9',
    tosTextAfter: '.',
    useNewCard: 'Utiliser une nouvelle carte',
    useNewCardDropdownText: 'Ajouter une nouvelle carte',
    useNewCardTextBefore:
      'Proc\u00e9der au paiement avec la carte enregistr\u00e9e ou ',
    useNewCardLinkText: 'utiliser un nouveau mode de paiement',
    useNewCardTextAfter: '.',
    cardSelect: 'S\u00e9lection de la carte de cr\u00e9dit',
    checkoutFaster: 'Proc\u00e9der au paiement plus rapidement avec FordPay.',
    checkout: 'Paiement',
    nameOnCard: 'Nom figurant sur la carte',
    fullName: 'Nom complet',
    cardNumber: 'Num\u00e9ro de la carte',
    expirationDate: "Date d'exp.",
    securityCode: 'Code de s\u00e9curit\u00e9',
    invalidCCNMessage: "Votre num\u00e9ro de carte n'est pas valide.",
    invalidCVVMessage: "Votre code de s\u00e9curit\u00e9 n'est pas valide.",
    invalidExpDateMessage: "Votre date d'expiration n'est pas valide.",
    emptyCCNMessage: 'Votre num\u00e9ro de carte est vide.',
    emptyCVVMessage: 'Votre code de s\u00e9curit\u00e9 est vide.',
    emptyExpDateMessage: "Votre date d'expiration est vide.",
    unsupportedCardMessage:
      "Votre num\u00e9ro de carte n'est pas pris en charge. Veuillez renseigner une carte VISA, MasterCard ou AMEX.",
    paymentNotProcessed: "Votre paiement n'a pas pu \u00eatre trait\u00e9",
    redirectStatusFailure:
      "Votre paiement n'a pas pu \u00eatre trait\u00e9. Veuillez r\u00e9essayer.\u00a0",
    continue: 'Continuer {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'En cliquant sur le bouton \u00ab\u00a0Continuer\u00bb ci-dessous, vous consentez aux ',
    authCheckoutStatement:
      'Payez avec une carte enregistr\u00e9e, utilisez un nouveau mode de paiement ou utilisez Pay By Bank.',
    useNewCardTextBeforePayByBank: 'Payez avec une carte enregistr\u00e9e, ',
    usePayByBank: ', ou utilisez ',
    payByBankLinkText: 'Pay By Bank',
    payByBankHeader: 'Utilisez Pay By Bank.',
    use: 'Utiliser ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Utiliser un nouveau mode de paiement',
    useNewPaymentDropdownText: 'utiliser un nouveau mode de paiement',
    useNewPaymentTextBefore:
      'Payez avec le mode de paiement enregistr\u00e9 ou ',
    useNewPaymentLinkText: 'utiliser un nouveau mode de paiement',
    useNewPaymentTextBeforePayByBank:
      'Payez avec le mode de paiement enregistr\u00e9, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      "Vous n'avez actuellement aucun mode de paiement dans votre portefeuille. Commencez en ajoutant le premier mode de paiement \u00e0 votre portefeuille.",
    addPaymentMethod: 'Ajouter un mode de paiement',
    makeDefault: 'D\u00e9finir comme mode de paiement par d\u00e9faut',
    changeDefaultPMSuccess:
      'Votre mode de paiement par d\u00e9faut a \u00e9t\u00e9 modifi\u00e9.',
    makeDefaultSuccessPM:
      'M\u00e9thode de paiement ajout\u00e9e en tant que d\u00e9faut.',
    makeDefaultErrorPM:
      'Erreur lors de la d\u00e9finition de la m\u00e9thode de paiement par d\u00e9faut.',
    removePMSuccess:
      'M\u00e9thode de paiement supprim\u00e9e du portefeuille Ford.com',
    removePMError:
      'Erreur lors de la suppression de la m\u00e9thode de paiement.',
    removePM: 'Supprimer la m\u00e9thode de paiement',
    removePMQuestion: 'Supprimer la m\u00e9thode de paiement?',
    removePMText:
      '\u00cates-vous s\u00fbr(e) de vouloir supprimer la m\u00e9thode de paiement {{cardNumber}} de votre portefeuille ? Tous les abonnements actifs associ\u00e9s \u00e0 ce mode de paiement \u00e9choueront si le mode de paiement est supprim\u00e9.',
    defaultCard: 'D\u00e9finir comme carte par d\u00e9faut\u00a0',
    defaultPM: 'M\u00e9thode de paiement par d\u00e9faut',
    defaultCardQuestion: 'D\u00e9finir comme carte par d\u00e9faut\u00a0?',
    defaultPMQuestion: 'M\u00e9thode de paiement par d\u00e9faut ?',
    defaultCardText:
      '\u00cates-vous s\u00fbr(e) de vouloir remplacer votre carte par d\u00e9faut par la carte {{cardNumber}}\u00a0?',
    defaultPMText:
      '\u00cates-vous s\u00fbr(e) de vouloir changer votre m\u00e9thode de paiement par d\u00e9faut en {{cardNumber}} ?',
    navigationLabel: 'Navigation de gestion de compte',
    removeDefaultPMSuccess:
      'M\u00e9thode de paiement par d\u00e9faut supprim\u00e9e du portefeuille Ford.com',
    removeDefaultCardError:
      'Erreur lors de la suppression de la carte par d\u00e9faut.',
    removeDefaultPMError:
      'Erreur lors de la suppression de la m\u00e9thode de paiement par d\u00e9faut.',
    removeDefaultCard: 'Supprimer votre carte par d\u00e9faut',
    removeDefaultPM:
      'Supprimer votre m\u00e9thode de paiement par d\u00e9faut ?',
    removeDefaultPMText:
      'La m\u00e9thode de paiement {{cardNumber}} est votre m\u00e9thode de paiement par d\u00e9faut. Veuillez choisir une nouvelle m\u00e9thode de paiement par d\u00e9faut ci-dessous. Tous les abonnements actifs associ\u00e9s \u00e0 ce mode de paiement \u00e9choueront si le mode de paiement est supprim\u00e9.',
    removeDefaultPMButton:
      'Supprimer la m\u00e9thode de paiement et mettre \u00e0 jour le d\u00e9faut',
    removeDefaultSelectPM:
      'S\u00e9lectionner une nouvelle m\u00e9thode de paiement par d\u00e9faut',
    title: 'Portefeuille',
    expiration: 'Exp.\u00a0{{date}}',
    expires: 'Expire le {{date}}',
    stripeDefaultError:
      'Un probl\u00e8me technique est survenu. Veuillez r\u00e9essayer.\u00a0',
    paymentMethod: 'Mode de paiement',
    tosTextBefore:
      'En cliquant sur le bouton \u00ab\u00a0Enregistrer la carte\u00a0\u00bb ci-dessous, vous acceptez les ',
    tosTextBeforePM:
      'S\u00e9lectionner une nouvelle m\u00e9thode de paiement par d\u00e9faut',
    tosLinkText:
      'Conditions g\u00e9n\u00e9rales de paiement et la Politique de confidentialit\u00e9',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Tous',
    amount: 'Somme',
    date: 'Date',
    description: 'Description',
    displayingAll: 'Affichage de toutes les transactions.',
    displayingDays:
      'Affichage des transactions des {{numberOfDays}} derniers jours.',
    displayingYear: 'Affichage des transactions \u00e0 partir de {{year}}.',
    filter: '{{numberOfDays}} derniers jours',
    loadAll: 'Afficher toutes les pages',
    loadMore: 'Afficher {{x}} pages de plus',
    noTransactions: 'Aucune transaction \u00e0 afficher.',
    paymentMethod: 'Mode de paiement',
    refund: 'Remboursement',
    searchBar: 'Rechercher par description',
    timeFilter:
      "S\u00e9lectionner la p\u00e9riode de l'historique des transactions",
    title: 'Transactions',
    pending: 'En attente'
  },
  mit: {
    confirmPurchase: "Confirmer l'achat",
    paymentSuccessful: 'Paiement r\u00e9ussi',
    saveCardAlert:
      "Pour utiliser cette carte pour vos paiements ult\u00e9rieurs, vous devez l'enregistrer dans votre portefeuille Ford.com.",
    title: 'S\u00e9lection du mode de paiement',
    tosTextBefore:
      'En cliquant sur le bouton {{paymentButtonText}} ci-dessous, vous acceptez les ',
    tosLinkText:
      'Conditions g\u00e9n\u00e9rales de paiement et la Politique de confidentialit\u00e9',
    tosTextAfter: '.',
    useNewCard: 'Utiliser une nouvelle carte',
    useNewCardDropDownItem: 'Utiliser une nouvelle carte',
    useNewCardTextBefore: 'S\u00e9lectionner la carte enregistr\u00e9e ou ',
    useNewCardLinkText: 'utiliser une nouvelle carte',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Utiliser un nouveau mode de paiement',
    useNewPaymentDropdownText: 'utiliser un nouveau mode de paiement',
    useNewPaymentTextBefore:
      'Payez avec le mode de paiement enregistr\u00e9 ou ',
    useNewPaymentLinkText: 'utiliser un nouveau mode de paiement',
    successful: 'R\u00e9ussi',
    savePaymentMethodAlert:
      'Pour utiliser ce mode de paiement pour de futurs paiements, il doit \u00eatre enregistr\u00e9 dans votre portefeuille.'
  }
};
