export const SUPPORTED_CARDS_FOR_PH = ['VISA', 'MASTERCARD', 'JCB', 'UNIONPAY'];
export const CARD_NUMBER_MINIMUM_LENGTH = 13;
export const CARD_NUMBER_MAXIMUM_LENGTH = 16;
export const CARD_CVV_MINIMUM_LENGTH = 3;
export const ALLOWED_KEYS = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'];
export const CARD_BRAND_PREFIXES: { [key: string]: string[] } = {
  visa: ['4'],
  mastercard: [
    '51',
    '52',
    '53',
    '54',
    '55',
    '2221',
    '2222',
    '2223',
    '2224',
    '2225',
    '2226',
    '2227',
    '2228',
    '2229',
    '223',
    '224',
    '225',
    '226',
    '227',
    '228',
    '229',
    '23',
    '24',
    '25',
    '26',
    '270',
    '271',
    '2720'
  ],
  jcb: ['35'],
  unionpay: ['62']
};
export const PAY_WITH_CARD_TEXT = 'Or Pay with Card';
