import {
  parseTransactionDetails,
  parseTransactionDetailsForMultiMerchant
} from './HandleTransactionDetails';
import {
  StripeTransactionDetails,
  TransactionDetails
} from '@fpc/common/transactionInterfaces';
import { MultiMerchantStripeTransactionDetails } from 'checkout/features/multi-merchant/types/PaymentInfoToken';
import httpClient, { getHeadersByAuthTokens } from '@fpc/common/HttpClient';
import { useAuthContextStore } from '../../stores/auth-context.store';
import { getApiBaseUrl } from '../../helpers/http';
import { AppDomain } from '../../types/app-domain';

export async function decodePaymentInfoToken(
  paymentInfoToken: string,
  isPreAuth?: boolean
): Promise<
  | TransactionDetails
  | StripeTransactionDetails
  | MultiMerchantStripeTransactionDetails
> {
  const { bffBaseUrl, customerAuthTokenType } = useAuthContextStore.getState();

  const baseUrl = getApiBaseUrl(
    AppDomain.PAYMENT_APP,
    customerAuthTokenType,
    bffBaseUrl
  );

  const res = await httpClient
    // TODO - Define proper types and add them here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .post<any>(
      `${baseUrl}/payment-info-decode`,
      {
        paymentInfoData: paymentInfoToken
      },
      getHeadersByAuthTokens()
    );
  // Check whether the returned data is a v2 Payment Info Token that supports multi-merchant flows
  if (res.version) {
    return parseTransactionDetailsForMultiMerchant(res);
  }
  return parseTransactionDetails(res, isPreAuth);
}
