import styled from 'styled-components/macro';
import { Style } from '../Styles';

export const Th = styled.th`
  && {
    margin: 2px 0;
    text-align: start;
    padding-inline: 1.25em;
    vertical-align: middle;
  }
`;

export const Tr = styled.tr`
  height: 3.125em;
  vertical-align: top;

  @media (max-width: 360px) {
    height: 48px;
  }
`;

export const Td = styled.td`
  && {
    padding-inline-start: 1.5em; // Reduced padding
    padding-block: 0.5em; // Reduced padding to decrease row height
    vertical-align: middle;

    &.truncated:hover {
      color: ${Style.color.hover};
    }
  }
`;
