import { AttachPaymentMethod } from '@fpc/api/paymentapp';
import { getApiBaseUrl } from '../../helpers/http';
import { AppDomain } from '../../types/app-domain';
import { HttpHeaders } from '../../types/http';
import httpClient, { getHeadersByAuthTokens } from '@fpc/common/HttpClient';
import { useAuthContextStore } from '../../stores/auth-context.store';

export async function attachPaymentMethod(attachPayment: AttachPaymentMethod) {
  const { customerAuthTokenType, bffBaseUrl } = useAuthContextStore.getState();
  const baseUrl = getApiBaseUrl(
    AppDomain.CUSTOMER_APP,
    customerAuthTokenType,
    bffBaseUrl
  );
  let requestUrl = `${baseUrl}/customers/payment-methods/attach`;

  const headers: HttpHeaders = getHeadersByAuthTokens();

  let body = {
    paymentMethodId: attachPayment.paymentMethodId,
    connectCustomerId: attachPayment.connectCustomerId,
    merchantAccountId: attachPayment.merchantAccountId
  };

  return httpClient.post(requestUrl, body, headers);
}
