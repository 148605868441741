import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import { traceId } from '@fpc/reactutils/TraceIdContext';
import { HttpError, HttpHeader, HttpHeaders, HttpMethod } from '../types/http';
import { APIGEE_AUTH_ERROR } from '@fpc/common';

class HttpService {
  public axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      headers: {
        [HttpHeader.CONTENT_TYPE]: 'application/json',
        [HttpHeader.TRACEPARENT]: traceId
      }
    });
  }

  public async get<T>(url: string, headers?: HttpHeaders): Promise<T> {
    return this.request<T>({ method: HttpMethod.GET, url, headers });
  }

  public async post<T, D = any>(
    url: string,
    data?: D,
    headers?: HttpHeaders
  ): Promise<T> {
    return this.request<T>({ method: HttpMethod.POST, url, data, headers });
  }

  public async put<T, D = any>(
    url: string,
    data?: D,
    headers?: HttpHeaders
  ): Promise<T> {
    return this.request<T>({ method: HttpMethod.PUT, url, data, headers });
  }

  public async delete<T>(url: string): Promise<T> {
    return this.request<T>({ method: HttpMethod.DELETE, url });
  }

  public addRequestInterceptor(
    onFulfilled?: (
      config: InternalAxiosRequestConfig
    ) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>,
    onRejected?: (error: AxiosError) => Promise<AxiosError>
  ) {
    this.axiosInstance.interceptors.request.use(onFulfilled, onRejected);
  }

  public addResponseInterceptor(
    onFulfilled?: (
      value: AxiosResponse
    ) => AxiosResponse | Promise<AxiosResponse>,
    onRejected?: (error: AxiosError) => Promise<AxiosError>
  ) {
    this.axiosInstance.interceptors.response.use(onFulfilled, onRejected);
  }

  private handleError(error: any): HttpError {
    const statusCode = error.status ?? -1;
    const isUnrecoverable =
      statusCode === 401 || error.message === APIGEE_AUTH_ERROR;
    let message =
      error.message ?? error.statusText ?? 'An unknown error occurred';

    let traceId = '';
    let pspErrorCode = undefined;
    let declineCode = undefined;

    if (axios.isAxiosError(error) || error instanceof AxiosError) {
      const data = error.response?.data;
      const responseDescription = data?.description;
      message = `${error.response?.statusText ?? error.message}${
        responseDescription ? ' ' + responseDescription : ''
      }`;
      traceId = data?.traceId ?? '';
      pspErrorCode = data?.pspErrorCode;
      declineCode = data?.declineCode;
    }

    return HttpError.from({
      code: statusCode,
      message,
      unrecoverable: isUnrecoverable,
      cause: error,
      traceId,
      pspErrorCode,
      declineCode
    });
  }

  private async request<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.axiosInstance.request(
        config
      );
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }
}

export default HttpService;
