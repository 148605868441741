import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';
import { CustomerAuthTokenType } from '@fpc/reactutils/accountContextProvider';

export { createPaymentIntent } from './CreatePaymentIntent';
export { decodePaymentInfoToken } from './DecodePaymentInfoToken';
export { getAuthTransaction } from './GetAuthTransaction';
export { verifyDigitalSignature } from './VerifyDigitalSignature';

export interface NewTransaction {
  paymentIntentClientSecret: string;
  transaction: StripeTransactionDetails;
}

export interface PaymentMethodResponse {
  paymentMethodId: string;
  cardType?: string;
  cardLastFourDigits?: string;
  expireMonth?: number;
  expireYear?: number;
  isDefault: boolean;
  type: string;
  card?: PaymentMethodCard;
  usBankAccount?: PaymentMethodUsBank;
  acssDebit?: PaymentMethodAchAcssDebit;
  sepaDebit?: PaymentMethodSepaDebit;
  merchantAccountId?: string;
}

export interface PaymentMethodInfo {
  paymentMethodId: string;
  isDefault: boolean;
  type: string;
  paymentMethod: PaymentMethod;
  merchantAccountId?: string;
}

type PaymentMethod =
  | PaymentMethodCard
  | PaymentMethodUsBank
  | PaymentMethodAchAcssDebit
  | PaymentMethodSepaDebit;

export interface PaymentMethodCard {
  type: string;
  lastFourDigits: string;
  expireMonth: number;
  expireYear: number;
  walletType?: string;
}

export interface PaymentMethodUsBank {
  bankName: string;
  lastFourDigits: string;
}

export interface PaymentMethodPumaBank {
  name: string;
  providerCode: string;
}

export interface PaymentMethodAchAcssDebit {
  bankName: string;
  lastFourDigits: string;
}

export interface PaymentMethodSepaDebit {
  bankCode: string;
  lastFourDigits: string;
}

export interface AuthTransaction {
  paymentMethods: PaymentMethodInfo[];
  transaction?: StripeTransactionDetails;
  pspCustomerId: string;
  fordCustomerId: string;
}

export interface AttachPaymentMethod {
  merchantAccountId: string | undefined;
  paymentMethodId?: string;
  connectCustomerId?: string;
}

export const NEW_PAYMENT_METHOD_INFO: PaymentMethodInfo = {
  paymentMethodId: 'useNewCard',
  isDefault: false,
  type: 'useNewCard',
  paymentMethod: {
    bankName: '',
    lastFourDigits: ''
  }
};
