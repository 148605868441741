import { enUS, TRANSLATION_MISSING } from './en-US';

export const viVN: typeof enUS = {
  common: {
    default: 'm\u1eb7c \u0111\u1ecbnh',
    endingIn: 'k\u1ebft th\u00fac b\u1eb1ng',
    close: '\u0110\u00f3ng',
    makeDefaultCard: 'T\u1ea1o th\u1ebb m\u1eb7c \u0111\u1ecbnh',
    makeDefaultInfoHover:
      'Th\u1ebb n\u00e0y s\u1ebd t\u1ef1 \u0111\u1ed9ng tr\u1edf th\u00e0nh th\u1ebb m\u1eb7c \u0111\u1ecbnh c\u1ee7a b\u1ea1n v\u00ec \u0111\u00e2y l\u00e0 th\u1ebb \u0111\u1ea7u ti\u00ean trong v\u00ed c\u1ee7a b\u1ea1n.',
    processing: 'X\u1eed l\u00fd ...',
    saveCardCheck:
      'L\u01b0u th\u1ebb cho c\u00e1c giao d\u1ecbch trong t\u01b0\u01a1ng lai',
    saveCardSuccess:
      'Th\u1ebb \u0111\u00e3 \u0111\u01b0\u1ee3c th\u00eam v\u00e0o V\u00ed Ford.com.',
    technicalErrorPayment:
      'Giao d\u1ecbch c\u1ee7a b\u1ea1n \u0111\u00e3 th\u1ea5t b\u1ea1i. H\u00e3y \u0111\u1ea3m b\u1ea3o r\u1eb1ng th\u1ebb c\u1ee7a b\u1ea1n \u0111\u01b0\u1ee3c k\u00edch ho\u1ea1t 3DS. Vui l\u00f2ng ki\u1ec3m tra v\u1edbi t\u1ed5 ch\u1ee9c ph\u00e1t h\u00e0nh th\u1ebb c\u1ee7a b\u1ea1n \u0111\u1ec3 bi\u1ebft th\u00eam chi ti\u1ebft.',
    timeoutSorry: 'L\u1ea5y l\u00e0m ti\u1ebfc ...',
    timeoutError:
      'Hi\u1ec7n t\u1ea1i ch\u00fang t\u00f4i \u0111ang g\u1eb7p kh\u00f3 kh\u0103n v\u1ec1 m\u1eb7t k\u1ef9 thu\u1eadt.',
    timeoutTryAgain: 'Th\u1eed l\u1ea1i',
    savePaymentMethodCheck:
      'L\u01b0u ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n cho c\u00e1c giao d\u1ecbch trong t\u01b0\u01a1ng lai',
    makeDefaultPaymentMethod:
      '\u0110\u1eb7t ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n m\u1eb7c \u0111\u1ecbnh',
    savePaymentMethodButton:
      'L\u01b0u ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n',
    technicalErrorDuplicatePayment:
      'Thanh to\u00e1n c\u1ee7a b\u1ea1n \u0111\u00e3 \u0111\u01b0\u1ee3c x\u1eed l\u00fd',
    makeDefaultInfoHoverPaymentMethod:
      '\u0110\u00e2y s\u1ebd t\u1ef1 \u0111\u1ed9ng tr\u1edf th\u00e0nh ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n m\u1eb7c \u0111\u1ecbnh c\u1ee7a b\u1ea1n v\u00ec \u0111\u00e2y l\u00e0 ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n \u0111\u1ea7u ti\u00ean trong v\u00ed c\u1ee7a b\u1ea1n.',
    savePaymentMethodSuccess:
      'Ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n \u0111\u00e3 \u0111\u01b0\u1ee3c th\u00eam v\u00e0o V\u00ed.'
  },
  checkout: {
    payButton: 'Thanh to\u00e1n {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'L\u1ea5y l\u00e0m ti\u1ebfc! Ch\u00fang t\u00f4i \u0111\u00e3 g\u1eb7p s\u1ef1 c\u1ed1 k\u1ef9 thu\u1eadt v\u00e0 kho\u1ea3n thanh to\u00e1n c\u1ee7a b\u1ea1n kh\u00f4ng \u0111\u01b0\u1ee3c x\u1eed l\u00fd. H\u00e3y l\u00e0m m\u1edbi trang v\u00e0 th\u1eed l\u1ea1i.',
    technicalErrorPaymentDetachFail:
      'L\u1ea5y l\u00e0m ti\u1ebfc! Ch\u00fang t\u00f4i \u0111\u00e3 g\u1eb7p s\u1ef1 c\u1ed1 k\u1ef9 thu\u1eadt v\u00e0 kho\u1ea3n thanh to\u00e1n c\u1ee7a b\u1ea1n kh\u00f4ng \u0111\u01b0\u1ee3c x\u1eed l\u00fd. Th\u1ebb c\u1ee7a b\u1ea1n \u0111\u00e3 \u0111\u01b0\u1ee3c l\u01b0u v\u00e0o v\u00ed c\u1ee7a b\u1ea1n. H\u00e3y l\u00e0m m\u1edbi trang v\u00e0 th\u1eed l\u1ea1i.',
    tosTextBefore:
      "B\u1eb1ng c\u00e1ch nh\u1ea5p v\u00e0o n\u00fat 'Thanh to\u00e1n' b\u00ean d\u01b0\u1edbi, b\u1ea1n \u0111\u1ed3ng \u00fd v\u1edbi ",
    tosTextBeforeForFordPayGuestId: TRANSLATION_MISSING,
    tosLinkText:
      '\u0110i\u1ec1u kho\u1ea3n v\u00e0 \u0110i\u1ec1u ki\u1ec7n thanh to\u00e1n',
    tosTextAfter: '.',
    useNewCard:
      'S\u1eed d\u1ee5ng ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n m\u1edbi',
    useNewCardDropdownText:
      'S\u1eed d\u1ee5ng ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n m\u1edbi',
    useNewCardTextBefore:
      'Thanh to\u00e1n b\u1eb1ng ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n \u0111\u00e3 l\u01b0u ho\u1eb7c',
    useNewCardLinkText:
      's\u1eed d\u1ee5ng ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n m\u1edbi',
    useNewCardTextAfter: '.',
    cardSelect: 'Ch\u1ecdn th\u1ebb t\u00edn d\u1ee5ng',
    checkoutFaster: 'Thanh to\u00e1n nhanh h\u01a1n v\u1edbi FordPay.',
    checkout: 'Th\u1ee7 t\u1ee5c thanh to\u00e1n',
    nameOnCard: 'T\u00ean tr\u00ean th\u1ebb',
    fullName: 'H\u1ecd v\u00e0 t\u00ean',
    cardNumber: 'S\u1ed1 th\u1ebb',
    expirationDate: 'ng\u00e0y h\u1ebft h\u1ea1n',
    securityCode: 'M\u00e3 b\u1ea3o m\u1eadt',
    invalidCCNMessage: 'S\u1ed1 th\u1ebb kh\u00f4ng \u0111\u00fang',
    invalidCVVMessage:
      'M\u00e3 b\u1ea3o m\u1eadt c\u1ee7a b\u1ea1n kh\u00f4ng h\u1ee3p l\u1ec7.',
    invalidExpDateMessage:
      'Ng\u00e0y h\u1ebft h\u1ea1n c\u1ee7a b\u1ea1n kh\u00f4ng h\u1ee3p l\u1ec7.',
    emptyCCNMessage:
      '\u0110\u00e2y l\u00e0 tr\u01b0\u1eddng b\u1eaft bu\u1ed9c',
    emptyCVVMessage:
      '\u0110\u00e2y l\u00e0 tr\u01b0\u1eddng b\u1eaft bu\u1ed9c',
    emptyExpDateMessage:
      '\u0110\u00e2y l\u00e0 tr\u01b0\u1eddng b\u1eaft bu\u1ed9c',
    unsupportedCardMessage:
      'Th\u1ebb kh\u00f4ng \u0111\u01b0\u1ee3c h\u1ed7 tr\u1ee3. Vui l\u00f2ng s\u1eed d\u1ee5ng Visa, Mastercard ho\u1eb7c Napas.',
    paymentNotProcessed:
      'Kh\u00f4ng th\u1ec3 x\u1eed l\u00fd kho\u1ea3n thanh to\u00e1n c\u1ee7a b\u1ea1n',
    redirectStatusFailure:
      'Thanh to\u00e1n c\u1ee7a b\u1ea1n kh\u00f4ng th\u1ec3 \u0111\u01b0\u1ee3c x\u1eed l\u00fd. Vui l\u00f2ng th\u1eed l\u1ea1i.',
    continue: 'Ti\u1ebfp t\u1ee5c {{formattedCurrency}}',
    disclaimer: 'Tuy\u00ean b\u1ed1 t\u1eeb ch\u1ed1i tr\u00e1ch nhi\u1ec7m',
    tosTextBeforeContinue:
      "B\u1eb1ng c\u00e1ch nh\u1ea5p v\u00e0o n\u00fat 'Ti\u1ebfp t\u1ee5c' b\u00ean d\u01b0\u1edbi, b\u1ea1n \u0111\u1ed3ng \u00fd v\u1edbi",
    authCheckoutStatement:
      'Thanh to\u00e1n b\u1eb1ng th\u1ebb \u0111\u00e3 l\u01b0u, s\u1eed d\u1ee5ng ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n m\u1edbi ho\u1eb7c s\u1eed d\u1ee5ng Thanh to\u00e1n qua ng\u00e2n h\u00e0ng.',
    useNewCardTextBeforePayByBank:
      'Thanh to\u00e1n b\u1eb1ng th\u1ebb \u0111\u00e3 l\u01b0u,',
    usePayByBank: ', ho\u1eb7c d\u00f9ng',
    payByBankLinkText: 'Thanh to\u00e1n b\u1eb1ng ng\u00e2n h\u00e0ng',
    payByBankHeader:
      'S\u1eed d\u1ee5ng thanh to\u00e1n qua ng\u00e2n h\u00e0ng',
    use: 'S\u1eed d\u1ee5ng',
    achOnlyInfoTitle:
      'Ch\u1ec9 ch\u1ea5p nh\u1eadn thanh to\u00e1n ng\u00e2n h\u00e0ng',
    achOnlyInfoMsg:
      'Th\u1ebb trong v\u00ed c\u1ee7a b\u1ea1n kh\u00f4ng th\u1ec3 \u0111\u01b0\u1ee3c s\u1eed d\u1ee5ng cho giao d\u1ecbch mua n\u00e0y.',
    useNewPayment:
      'S\u1eed d\u1ee5ng ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n m\u1edbi',
    useNewPaymentDropdownText:
      's\u1eed d\u1ee5ng ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n m\u1edbi',
    useNewPaymentTextBefore:
      'Thanh to\u00e1n b\u1eb1ng ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n \u0111\u00e3 l\u01b0u ho\u1eb7c',
    useNewPaymentLinkText:
      's\u1eed d\u1ee5ng ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n m\u1edbi',
    useNewPaymentTextBeforePayByBank:
      'Ki\u1ec3m tra b\u1eb1ng ph\u01b0\u01a1ng th\u1ee9c thanh to\u00e1n \u0111\u00e3 l\u01b0u,',
    emptyNameMessage:
      '\u0110\u00e2y l\u00e0 tr\u01b0\u1eddng b\u1eaft bu\u1ed9c',
    unsupportedCardErrorMessage:
      'S\u1ed1 th\u1ebb c\u1ee7a b\u1ea1n kh\u00f4ng \u0111\u01b0\u1ee3c h\u1ed7 tr\u1ee3. Vui l\u00f2ng th\u1eed v\u1edbi b\u1ea5t k\u1ef3 th\u1ebb n\u00e0o trong s\u1ed1 c\u00e1c th\u1ebb n\u00e0y - {{supportedCards}} ho\u1eb7c {{lastSupportedCard}}\n',
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: 'THANH TO\u00c1N \u0110\u1eb6T C\u1eccC'
  },
  wallet: {
    addNewPaymentMethodText: TRANSLATION_MISSING,
    addPaymentMethod: TRANSLATION_MISSING,
    makeDefault: TRANSLATION_MISSING,
    changeDefaultPMSuccess: TRANSLATION_MISSING,
    makeDefaultSuccessPM: TRANSLATION_MISSING,
    makeDefaultErrorPM: TRANSLATION_MISSING,
    removePMSuccess: TRANSLATION_MISSING,
    removePMError: TRANSLATION_MISSING,
    removePM: TRANSLATION_MISSING,
    removePMQuestion: TRANSLATION_MISSING,
    removePMText: TRANSLATION_MISSING,
    defaultCard: TRANSLATION_MISSING,
    defaultPM: TRANSLATION_MISSING,
    defaultCardQuestion: TRANSLATION_MISSING,
    defaultPMQuestion: TRANSLATION_MISSING,
    defaultCardText: TRANSLATION_MISSING,
    defaultPMText: TRANSLATION_MISSING,
    navigationLabel: TRANSLATION_MISSING,
    removeDefaultPMSuccess: TRANSLATION_MISSING,
    removeDefaultCardError: TRANSLATION_MISSING,
    removeDefaultPMError: TRANSLATION_MISSING,
    removeDefaultCard: TRANSLATION_MISSING,
    removeDefaultPM: TRANSLATION_MISSING,
    removeDefaultPMText: TRANSLATION_MISSING,
    removeDefaultPMButton: TRANSLATION_MISSING,
    removeDefaultSelectPM: TRANSLATION_MISSING,
    title: TRANSLATION_MISSING,
    expiration: TRANSLATION_MISSING,
    expires: TRANSLATION_MISSING,
    stripeDefaultError: TRANSLATION_MISSING,
    paymentMethod: TRANSLATION_MISSING,
    tosTextBefore: TRANSLATION_MISSING,
    tosTextBeforePM: TRANSLATION_MISSING,
    tosLinkText: TRANSLATION_MISSING,
    tosTextAfter: TRANSLATION_MISSING
  },
  transaction: {
    all: TRANSLATION_MISSING,
    amount: TRANSLATION_MISSING,
    date: TRANSLATION_MISSING,
    description: TRANSLATION_MISSING,
    displayingAll: TRANSLATION_MISSING,
    displayingDays: TRANSLATION_MISSING,
    displayingYear: TRANSLATION_MISSING,
    filter: TRANSLATION_MISSING,
    loadAll: TRANSLATION_MISSING,
    loadMore: TRANSLATION_MISSING,
    noTransactions: TRANSLATION_MISSING,
    paymentMethod: TRANSLATION_MISSING,
    refund: TRANSLATION_MISSING,
    searchBar: TRANSLATION_MISSING,
    timeFilter: TRANSLATION_MISSING,
    title: TRANSLATION_MISSING,
    pending: TRANSLATION_MISSING
  },
  mit: {
    confirmPurchase: TRANSLATION_MISSING,
    paymentSuccessful: TRANSLATION_MISSING,
    saveCardAlert: TRANSLATION_MISSING,
    title: TRANSLATION_MISSING,
    tosTextBefore: TRANSLATION_MISSING,
    tosLinkText: TRANSLATION_MISSING,
    tosTextAfter: TRANSLATION_MISSING,
    useNewCard: TRANSLATION_MISSING,
    useNewCardDropDownItem: TRANSLATION_MISSING,
    useNewCardTextBefore: TRANSLATION_MISSING,
    useNewCardLinkText: TRANSLATION_MISSING,
    useNewCardTextAfter: TRANSLATION_MISSING,
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: TRANSLATION_MISSING,
    useNewPaymentDropdownText: TRANSLATION_MISSING,
    useNewPaymentTextBefore: TRANSLATION_MISSING,
    useNewPaymentLinkText: TRANSLATION_MISSING,
    successful: TRANSLATION_MISSING,
    savePaymentMethodAlert: TRANSLATION_MISSING
  }
};
