import { Dispatch } from 'react';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { getLocalizedStripeError } from '@fpc/api/stripe/StripeErrors';
import i18next from '@fpc/common/i18n';
import {
  dispatchAuthorizationFailureEvent,
  DispatchEventTarget
} from '@fpc/utils/dispatchEvent';
import { STRIPE_GENERIC_DECLINE } from '@fpc/common';
import { IHttpError } from '../types/http';
import usePaymentContextStore from '../stores/payment-context.store';

export function handleHttpError(
  error: IHttpError,
  errorMessage: string,
  errorDispatch: Dispatch<ErrorCondition>
): string {
  if (error.unrecoverable) {
    const eventTarget = usePaymentContextStore.getState().elementName;
    dispatchAuthorizationFailureEvent(eventTarget as DispatchEventTarget);
    errorDispatch(ErrorCondition.Unrecoverable);
  } else if (error.declineCode || error.pspErrorCode) {
    errorMessage = getLocalizedErrorMessage(error) ?? errorMessage;
  }
  return i18next.t<string>(errorMessage);
}

function getLocalizedErrorMessage(error: IHttpError): string | undefined {
  const declineMsg =
    getLocalizedStripeError(error.declineCode!) ??
    getLocalizedStripeError(STRIPE_GENERIC_DECLINE);

  return error.declineCode
    ? declineMsg
    : getLocalizedStripeError(error.pspErrorCode!);
}
