import { statusReject } from '@fpc/api/statusReject';
import { CheckoutTokens } from '@fpc/reactutils/checkoutContextProvider';
import { HeadersInitBuilder } from '@fpc/utils/headerBuilder';
import { observedFetch } from '@fpc/common/monitoring/utils';

export interface PaymentMethodDetails {
  paymentMethodId: string | null;
  paymentMethodType: string;
}

export interface ConfirmationMethodDetails {
  confirmationId: string | null;
  paymentMethodType: string;
}

export interface PaymentRequest {
  paymentMethodDetails: PaymentMethodDetails | ConfirmationMethodDetails;
  isPreAuth: boolean;
  redirectUrl: string | null;
}

export interface PaymentRequestJWT extends PaymentRequest {
  paymentInfoToken: string;
}

export interface PaymentRequestDigitalSig extends PaymentRequest {
  signature: string;
  paymentInfo: string;
}

export interface PspSpecificInfo {}

export interface StripeSpecificInfo extends PspSpecificInfo {
  paymentIntentClientSecret: string;
}

export interface PaymentSuccessResponse {
  paymentStatus: string;
  paymentId: string;
  merchantAccountId: string;
  pspSpecificInfo?: PspSpecificInfo;
}

export async function processPayment(
  tokens: CheckoutTokens,
  paymentMethodId: string | null,
  paymentMethodType: string,
  isPreAuth?: boolean,
  bffBaseUrl?: string | null | undefined,
  digitalSignature?: string | null,
  redirectUrl?: string | null,
  confirmationId?: string | undefined
): Promise<PaymentSuccessResponse> {
  let targetServer, authHeader;
  let targetEndpoint = 'process-payment';
  let paymentMethodDetails: PaymentMethodDetails | ConfirmationMethodDetails = {
    paymentMethodId: null,
    paymentMethodType: paymentMethodType
  };

  if (paymentMethodId) {
    paymentMethodDetails = {
      paymentMethodId: paymentMethodId,
      paymentMethodType: paymentMethodType
    };
  } else if (confirmationId) {
    paymentMethodDetails = {
      confirmationId: confirmationId,
      paymentMethodType: paymentMethodType
    };
  }

  let body: PaymentRequestJWT | PaymentRequestDigitalSig = {
    paymentInfoToken: tokens.paymentInfoToken,
    paymentMethodDetails: paymentMethodDetails,
    isPreAuth: isPreAuth ?? false,
    redirectUrl: redirectUrl ?? null
  };

  if (digitalSignature && !bffBaseUrl) {
    targetEndpoint = 'process-payment-digital-sig';
    authHeader = digitalSignature;
    targetServer = process.env.PAYMENT_DIGITAL_SIGNATURE_SERVER;
    let paymentInfo = JSON.parse(tokens.paymentInfoToken).paymentInfo;
    body = {
      paymentInfo: paymentInfo,
      paymentMethodDetails: paymentMethodDetails,
      signature: digitalSignature,
      isPreAuth: isPreAuth ?? false,
      redirectUrl: redirectUrl ?? null
    };
  } else if (bffBaseUrl) {
    targetServer = `${bffBaseUrl}${process.env.PAYMENT_APP_APIGEE_BASE_PATH}`;
    authHeader = '';
  } else {
    targetServer = process.env.PAYMENT_APP_SERVER;
    authHeader = 'Bearer ' + tokens.bearerToken;
  }

  return observedFetch(`${targetServer}/api/${targetEndpoint}`, {
    method: 'POST',
    headers: {
      ...HeadersInitBuilder(),
      Authorization: `${authHeader}`
    },
    body: JSON.stringify(body)
  })
    .then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          return data;
        });
      } else if (res.status === 401) {
        return Promise.reject(statusReject(res));
      } else {
        return res.json().then((data) => {
          return Promise.reject(data);
        });
      }
    })
    .catch((err) => {
      console.warn('Error submitting payment request: ', err);
      return Promise.reject(err);
    });
}
