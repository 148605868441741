import { enUS, TRANSLATION_MISSING } from './en-US';

export const itIT: typeof enUS = {
  common: {
    default: 'predefinita',
    endingIn: 'termina in',
    close: 'Chiudi',
    makeDefaultCard: 'Imposta come carta predefinita',
    makeDefaultInfoHover:
      'Questa diventer\u00e0 automaticamente la tua carta predefinita poich\u00e9 \u00e8 la prima del tuo portafoglio.',
    processing: 'Elaborazione in corso...',
    saveCardCheck: 'Salva carta per transazioni future',
    saveCardSuccess: 'Carta aggiunta al Portafoglio Ford.com.',
    technicalErrorPayment:
      'Spiacenti! Non \u00e8 stato possibile elaborare il pagamento a causa di un problema tecnico. Riprova.',
    timeoutSorry: 'Spiacenti...',
    timeoutError: 'Al momento stiamo riscontrando difficolt\u00e0 tecniche.',
    timeoutTryAgain: 'Riprova',
    savePaymentMethodCheck: 'Salva metodo di pagamento per transazioni future',
    makeDefaultPaymentMethod: 'Imposta come metodo di pagamento predefinito',
    savePaymentMethodButton: 'Salva metodo di pagamento',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Questo diventer\u00e0 automaticamente il tuo metodo di pagamento predefinito, perch\u00e9 \u00e8 il primo metodo di pagamento nel tuo portafoglio.',
    savePaymentMethodSuccess:
      'Il metodo di pagamento \u00e8 stato aggiunto a Wallet.'
  },
  checkout: {
    payButton: 'Paga {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Spiacenti! Non \u00e8 stato possibile elaborare il pagamento a causa di un problema tecnico. Aggiorna la pagina e riprova. ',
    technicalErrorPaymentDetachFail:
      'Spiacenti! Non \u00e8 stato possibile elaborare il pagamento a causa di un problema tecnico. La carta \u00e8 stata salvata nel tuo portafoglio. Riprova.',
    tosTextBefore: 'Facendo clic sul pulsante "Paga" di seguito, accetti i ',
    tosTextBeforeForFordPayGuestId:
      'Facendo clic sul pulsante {{paymentButtonText}} di seguito, accetti i ',
    tosLinkText:
      "Termini e le condizioni di pagamento e l'Informativa sulla privacy",
    tosTextAfter: '.',
    useNewCard: 'Usa nuova carta',
    useNewCardDropdownText: 'Aggiungi nuova carta',
    useNewCardTextBefore: 'Paga con la carta salvata o ',
    useNewCardLinkText: 'usa un nuovo metodo di pagamento',
    useNewCardTextAfter: '.',
    cardSelect: 'Selezione carta di credito',
    checkoutFaster: 'Pagamento pi\u00f9 rapido con FordPay.',
    checkout: 'Pagamento',
    nameOnCard: 'Nome sulla carta',
    fullName: 'Nome completo',
    cardNumber: 'Numero carta',
    expirationDate: 'Data di scadenza',
    securityCode: 'Codice di verifica',
    invalidCCNMessage: 'Numero carta non valido.',
    invalidCVVMessage: 'Codice di verifica non valido.',
    invalidExpDateMessage: 'Data di scadenza non valida.',
    emptyCCNMessage: 'Campo numero carta vuoto.',
    emptyCVVMessage: 'Campo codice di verifica vuoto.',
    emptyExpDateMessage: 'Campo data di scadenza vuoto.',
    unsupportedCardMessage:
      'Carta non supportata. Inserisci una carta VISA, MasterCard o AMEX.',
    paymentNotProcessed: 'Impossibile elaborare il pagamento',
    redirectStatusFailure: 'Impossibile elaborare il pagamento. Riprova.\u00a0',
    continue: 'Continua {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Facendo clic sul pulsante "Continua" di seguito, accetti i ',
    authCheckoutStatement:
      'Effettua il pagamento con la carta salvata, utilizza un nuovo metodo di pagamento o utilizza il pagamento tramite banca.',
    useNewCardTextBeforePayByBank:
      'Effettua il pagamento con la carta salvata, ',
    usePayByBank: ', o utilizza ',
    payByBankLinkText: 'il pagamento tramite banca',
    payByBankHeader: 'Utilizza il pagamento tramite banca.',
    use: 'Utilizzo ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Usa un nuovo metodo di pagamento',
    useNewPaymentDropdownText: 'usa un nuovo metodo di pagamento',
    useNewPaymentTextBefore:
      'Effettua il pagamento con il metodo di pagamento salvato o ',
    useNewPaymentLinkText: 'usa un nuovo metodo di pagamento',
    useNewPaymentTextBeforePayByBank:
      'Effettua il pagamento con il metodo di pagamento salvato, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Al momento non hai alcun metodo di pagamento nel tuo portafoglio. Inizia aggiungendo il primo metodo di pagamento al tuo portafoglio.',
    addPaymentMethod: 'Aggiungi metodo di pagamento',
    makeDefault: 'Crea predefinita',
    changeDefaultPMSuccess:
      'Il tuo metodo di pagamento predefinito \u00e8 cambiato.',
    makeDefaultSuccessPM: 'Metodo di pagamento aggiunto come predefinito.',
    makeDefaultErrorPM:
      "Errore durante l'impostazione del metodo di pagamento come predefinito.",
    removePMSuccess: 'Metodo di pagamento rimosso dal portafoglio Ford.com',
    removePMError: 'Errore durante la rimozione del metodo di pagamento.',
    removePM: 'Rimuovi il metodo di pagamento',
    removePMQuestion: 'Rimuovere il metodo di pagamento?\u00a0',
    removePMText:
      'Sei sicuro di voler rimuovere il metodo di pagamento {{cardNumber}} dal tuo portafoglio? Tutti gli abbonamenti attivi associati a questo metodo di pagamento falliranno se il metodo di pagamento viene rimosso.',
    defaultCard: 'Carta predefinita',
    defaultPM: 'Metodo di pagamento predefinito',
    defaultCardQuestion: 'Carta predefinita?',
    defaultPMQuestion: 'Metodo di pagamento predefinito?\u00a0',
    defaultCardText:
      'Confermi di voler modificare la tua carta predefinita in {{cardNumber}}?',
    defaultPMText:
      'Sei sicuro di voler cambiare il tuo metodo di pagamento predefinito in {{cardNumber}}?',
    navigationLabel: "Gestione dell'account",
    removeDefaultPMSuccess:
      'Metodo di pagamento predefinito rimosso dal portafoglio Ford.com',
    removeDefaultCardError:
      'Errore durante la rimozione della carta predefinita.',
    removeDefaultPMError:
      'Errore durante la rimozione del metodo di pagamento predefinito.',
    removeDefaultCard: 'Rimuovi la tua carta predefinita',
    removeDefaultPM: 'Rimuovere il tuo metodo di pagamento predefinito?',
    removeDefaultPMText:
      'Il metodo di pagamento {{cardNumber}} \u00e8 il tuo metodo di pagamento predefinito. Scegli un nuovo metodo di pagamento predefinito qui sotto. Tutti gli abbonamenti attivi associati a questo metodo di pagamento falliranno se il metodo di pagamento viene rimosso.',
    removeDefaultPMButton:
      'Rimuovi il metodo di pagamento e aggiorna il predefinito',
    removeDefaultSelectPM: 'Seleziona un nuovo metodo di pagamento predefinito',
    title: 'Portafoglio',
    expiration: 'Scad.\u00a0{{date}}',
    expires: 'Scadenza {{date}}',
    stripeDefaultError:
      'Si \u00e8 verificato un problema tecnico. Riprova.\u00a0',
    paymentMethod: 'Metodo di pagamento',
    tosTextBefore:
      'Facendo clic sul pulsante "Salva carta" di seguito, accetti i ',
    tosTextBeforePM:
      'Cliccando sul pulsante "Salva metodo di pagamento" qui sotto, accetti di',
    tosLinkText:
      "Termini e le condizioni di pagamento e l'Informativa sulla privacy",
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Tutte',
    amount: 'Importo',
    date: 'Data',
    description: 'Descrizione',
    displayingAll: 'Visualizza tutte le transazioni.',
    displayingDays:
      'Visualizza le transazioni degli ultimi {{numberOfDays}} giorni.',
    displayingYear: 'Visualizza le transazioni dal {{year}}.',
    filter: 'Ultimi {{numberOfDays}} giorni',
    loadAll: 'Carica tutte',
    loadMore: 'Carica altre {{x}}',
    noTransactions: 'Nessuna transazione da visualizzare.',
    paymentMethod: 'Metodo di pagamento',
    refund: 'Rimborso',
    searchBar: 'Cerca per descrizione',
    timeFilter:
      'Seleziona intervallo temporale per la cronologia delle transazioni',
    title: 'Transazioni',
    pending: 'In attesa di'
  },
  mit: {
    confirmPurchase: "Confermare l'acquisto",
    paymentSuccessful: 'Pagamento andato a buon fine',
    saveCardAlert:
      'Per utilizzare questa carta in transazioni future, \u00e8 necessario salvarla nel portafoglio Ford.com.',
    title: 'Selezione metodo di pagamento',
    tosTextBefore:
      'Facendo clic sul pulsante {{paymentButtonText}} di seguito, accetti i ',
    tosLinkText:
      "Termini e le condizioni di pagamento e l'Informativa sulla privacy",
    tosTextAfter: '.',
    useNewCard: 'Usa nuova carta',
    useNewCardDropDownItem: 'Usa nuova carta',
    useNewCardTextBefore: 'Seleziona carta salvata o ',
    useNewCardLinkText: 'usa nuova carta',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Usa un nuovo metodo di pagamento',
    useNewPaymentDropdownText: 'usa un nuovo metodo di pagamento',
    useNewPaymentTextBefore:
      'Effettua il pagamento con il metodo di pagamento salvato o ',
    useNewPaymentLinkText: 'usa un nuovo metodo di pagamento',
    successful: 'Riuscito',
    savePaymentMethodAlert:
      'Per utilizzare questo metodo di pagamento per pagamenti futuri, \u00e8 necessario salvarlo nel tuo portafoglio.'
  }
};
