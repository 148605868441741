import { CustomerAuthTokenType } from '@fpc/reactutils/accountContextProvider';
import { BluesnapCheckoutWrapper } from './bluesnap/BluesnapCheckoutWrapper';
import { StripeCheckoutWrapper } from './stripe/StripeCheckoutWrapper';
import { PayuCheckoutWrapper } from './payu/PayuCheckoutWrapper';
import { CheckoutLoadError } from './CheckoutLoadError';
import { isNotProd } from '../flags';
import { LoadingSpinner, TechnicalError } from '@fpc/common';
import { Psp } from '@fpc/common/Psp';
import { Language } from '@fpc/common/Language';
import { FordPayUIStyle, loadingSpinnerBig } from '@fpc/common/Styles';
import { useGetTransactionDetails } from '@fpc/reactutils/useGetTransactionDetails';
import {
  isPayUTransaction,
  isStripeTransaction,
  isTwocTwopTransaction,
  PaymentObjectType
} from '@fpc/common/transactionInterfaces';
import { PumaCheckoutWrapper } from './puma/PumaCheckoutWrapper';
import { TwocTwopCheckoutWrapper } from '@fpc/checkout/twoctwop/TwocTwopCheckoutWrapper';

export interface CheckoutProps {
  bearerToken?: string | null;
  paymentInfoToken: string | null;
  paymentObjectType: PaymentObjectType;
  redirectUrl: string | null;
  customerAuthToken: string | null;
  customerAuthTokenType: CustomerAuthTokenType | null;
  isPreAuth: boolean;
  noCreditCards: boolean;
  merchantCurrency: string | null;
  bffBaseUrl: string | null;
  language: Language | null;
  redirectStatus: string | null;
  paymentMethodDisplayOrder: string | null;
  achNoPay?: boolean;
  fordPayGuestId: string | null;
  paymentButtonText: string | null;
  isFromMitComponent?: boolean;
}

export function CheckoutWrapper(props: CheckoutProps) {
  const {
    isValidSecurityConfig,
    decodedPaymentToken,
    customerAuthToken,
    bffBaseUrl,
    digitalSignature,
    isPaymentDecodeError
  } = useGetTransactionDetails(
    props.bffBaseUrl,
    props.bearerToken ?? null,
    props.customerAuthToken,
    props.paymentObjectType,
    props.paymentInfoToken,
    props.customerAuthTokenType,
    props.isPreAuth
  );

  const isBluesnap = decodedPaymentToken?.psp?.toUpperCase() === Psp.BLUESNAP;
  const isPuma = decodedPaymentToken?.psp?.toUpperCase() === Psp.Puma;

  function getCheckoutLoadError() {
    if (isNotProd()) {
      return (
        <CheckoutLoadError
          bearerToken={props.bearerToken!}
          customerAuthToken={props.customerAuthToken}
          customerAuthTokenType={props.customerAuthTokenType}
          isPreAuth={props.isPreAuth}
          paymentInfoToken={props.paymentInfoToken}
          redirectUrl={props.redirectUrl}
          isValidAuthParams={isValidSecurityConfig}
          bffBaseUrl={props.bffBaseUrl ?? null}
          language={props.language}
          redirectStatus={props.redirectStatus}
        />
      );
    } else {
      return (
        <TechnicalError resetFromError={() => undefined} unrecoverable={true} />
      );
    }
  }

  if (!isValidSecurityConfig) {
    return <FordPayUIStyle>{getCheckoutLoadError()}</FordPayUIStyle>;
  } else if (isBluesnap && !isStripeTransaction(decodedPaymentToken)) {
    return (
      <BluesnapCheckoutWrapper
        bearerToken={props.bearerToken!}
        paymentInfoToken={props.paymentInfoToken!}
        paymentObjectType={props.paymentObjectType}
        customerAuthToken={customerAuthToken ?? undefined}
        customerAuthTokenType={props.customerAuthTokenType!}
        redirectUrl={props.redirectUrl!}
        merchantCurrency={props.merchantCurrency ?? undefined}
        bffBaseUrl={bffBaseUrl ?? null}
        transactionDetails={decodedPaymentToken}
        noCreditCards={props.noCreditCards}
        digitalSignature={digitalSignature}
        language={props.language}
        fordPayGuestId={props.fordPayGuestId}
        paymentButtonText={props.paymentButtonText}
      />
    );
  } else if (
    decodedPaymentToken != null &&
    isPayUTransaction(decodedPaymentToken)
  ) {
    return (
      <PayuCheckoutWrapper
        bearerToken={props.bearerToken!}
        paymentInfoToken={props.paymentInfoToken!}
        paymentObjectType={props.paymentObjectType}
        customerAuthTokenType={props.customerAuthTokenType!}
        language={props.language}
        noCreditCards
        redirectUrl={props.redirectUrl!}
        transactionDetails={decodedPaymentToken}
        digitalSignature={digitalSignature}
        fordPayGuestId={props.fordPayGuestId}
        paymentButtonText={props.paymentButtonText}
      />
    );
  } else if (
    decodedPaymentToken != null &&
    isTwocTwopTransaction(decodedPaymentToken)
  ) {
    return (
      <TwocTwopCheckoutWrapper
        bearerToken={props.bearerToken!}
        paymentInfoToken={props.paymentInfoToken!}
        paymentObjectType={props.paymentObjectType}
        customerAuthTokenType={props.customerAuthTokenType!}
        language={props.language}
        noCreditCards
        redirectUrl={props.redirectUrl!}
        transactionDetails={decodedPaymentToken}
        digitalSignature={digitalSignature}
        fordPayGuestId={props.fordPayGuestId}
        paymentButtonText={props.paymentButtonText}
      />
    );
  } else if (
    decodedPaymentToken != null &&
    isStripeTransaction(decodedPaymentToken)
  ) {
    return (
      <StripeCheckoutWrapper
        bearerToken={props.bearerToken!}
        paymentInfoToken={props.paymentInfoToken!}
        paymentObjectType={props.paymentObjectType}
        customerAuthToken={customerAuthToken ?? undefined}
        customerAuthTokenType={props.customerAuthTokenType!}
        redirectUrl={props.redirectUrl!}
        isPreAuth={props.isPreAuth}
        noCreditCards={props.noCreditCards}
        achNoPay={props.achNoPay}
        merchantCurrency={props.merchantCurrency ?? undefined}
        bffBaseUrl={bffBaseUrl ?? null}
        transactionDetails={decodedPaymentToken}
        digitalSignature={digitalSignature}
        language={props.language}
        redirectStatus={props.redirectStatus}
        paymentMethodDisplayOrder={props.paymentMethodDisplayOrder}
        fordPayGuestId={props.fordPayGuestId}
        paymentButtonText={props.paymentButtonText}
        isFromMitComponent={props.isFromMitComponent}
      />
    );
  } else if (isPuma) {
    return (
      <PumaCheckoutWrapper
        paymentInfoToken={props.paymentInfoToken!}
        paymentObjectType={props.paymentObjectType}
        customerAuthTokenType={props.customerAuthTokenType!}
        redirectUrl={props.redirectUrl!}
        noCreditCards={props.noCreditCards}
        merchantCurrency={props.merchantCurrency ?? undefined}
        bffBaseUrl={bffBaseUrl ?? null}
        transactionDetails={decodedPaymentToken}
        digitalSignature={digitalSignature}
        language={props.language}
        fordPayGuestId={props.fordPayGuestId}
        paymentButtonText={props.paymentButtonText}
      />
    );
  } else if (
    decodedPaymentToken != null &&
    !isStripeTransaction(decodedPaymentToken) &&
    !isBluesnap
  ) {
    return <FordPayUIStyle>{getCheckoutLoadError()}</FordPayUIStyle>;
  } else if (isPaymentDecodeError) {
    return (
      <TechnicalError resetFromError={() => undefined} unrecoverable={true} />
    );
  } else {
    return (
      <FordPayUIStyle>
        <LoadingSpinner style={loadingSpinnerBig} />
      </FordPayUIStyle>
    );
  }
}
