import { enUS, TRANSLATION_MISSING } from './en-US';

export const esES: typeof enUS = {
  common: {
    default: 'predeterminada',
    endingIn: 'terminada en',
    close: 'Cerrar',
    makeDefaultCard: 'Configurar como tarjeta predeterminada',
    makeDefaultInfoHover:
      'Esta tarjeta se convertir\u00e1 autom\u00e1ticamente en tu tarjeta predeterminada, porque es la primera de tu cartera.',
    processing: 'Procesando...',
    saveCardCheck: 'Guardar la tarjeta para futuras transacciones',
    saveCardSuccess: 'Tarjeta a\u00f1adida a tu cartera de Ford.com',
    technicalErrorPayment:
      '\u00a1Lo sentimos! Se ha producido un problema t\u00e9cnico y no hemos podido procesar el pago. Int\u00e9ntalo de nuevo.',
    timeoutSorry: 'Lo sentimos.',
    timeoutError:
      'Ahora mismo, estamos experimentando ciertas dificultades t\u00e9cnicas.',
    timeoutTryAgain: 'Int\u00e9ntalo de nuevo',
    savePaymentMethodCheck:
      'Guardar el m\u00e9todo de pago para futuras transacciones',
    makeDefaultPaymentMethod:
      'Establecer como m\u00e9todo de pago predeterminado',
    savePaymentMethodButton: 'Guardar m\u00e9todo de pago',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Este se convertir\u00e1 autom\u00e1ticamente en su m\u00e9todo de pago predeterminado, porque es el primer m\u00e9todo de pago en su billetera.',
    savePaymentMethodSuccess: 'El m\u00e9todo de pago se ha agregado a Wallet.'
  },
  checkout: {
    payButton: 'Pagar {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      '\u00a1Lo sentimos! Se ha producido un problema t\u00e9cnico y no hemos podido procesar el pago. Vuelve a cargar la p\u00e1gina e int\u00e9ntalo de nuevo. ',
    technicalErrorPaymentDetachFail:
      '\u00a1Lo sentimos! Se ha producido un problema t\u00e9cnico y no hemos podido procesar el pago. La tarjeta se ha guardado en tu cartera. Int\u00e9ntalo de nuevo.',
    tosTextBefore:
      'Al hacer clic en el bot\u00f3n \u00abPagar\u00bb que se muestra a continuaci\u00f3n, aceptas los ',
    tosTextBeforeForFordPayGuestId:
      'Al hacer clic en el bot\u00f3n {{paymentButtonText}} que se muestra a continuaci\u00f3n, aceptas los ',
    tosLinkText:
      'T\u00e9rminos y Condiciones de Pago y la Pol\u00edtica de Privacidad',
    tosTextAfter: '.',
    useNewCard: 'Usar una nueva tarjeta',
    useNewCardDropdownText: 'A\u00f1adir una nueva tarjeta',
    useNewCardTextBefore: 'Pagar con la tarjeta guardada o ',
    useNewCardLinkText: 'usar nuevo m\u00e9todo de pago',
    useNewCardTextAfter: '.',
    cardSelect: 'Tarjeta de cr\u00e9dito seleccionada',
    checkoutFaster: 'Agiliza tus pagos con FordPay.',
    checkout: 'Pagar',
    nameOnCard: 'Nombre que figura en la tarjeta',
    fullName: 'Nombre completo',
    cardNumber: 'N\u00famero que aparece en la tarjeta',
    expirationDate: 'Fecha de caducidad',
    securityCode: 'C\u00f3digo de seguridad',
    invalidCCNMessage: 'El n\u00famero de la tarjeta no es v\u00e1lido.',
    invalidCVVMessage: 'El c\u00f3digo de seguridad no es v\u00e1lido.',
    invalidExpDateMessage: 'La fecha de caducidad no es v\u00e1lida.',
    emptyCCNMessage: 'El n\u00famero de la tarjeta est\u00e1 vac\u00edo.',
    emptyCVVMessage: 'El c\u00f3digo de seguridad est\u00e1 vac\u00edo.',
    emptyExpDateMessage: 'La fecha de caducidad est\u00e1 vac\u00eda.',
    unsupportedCardMessage:
      'No se admite el n\u00famero de la tarjeta introducido. Introduce un n\u00famero de VISA, MasterCard o AMEX.',
    paymentNotProcessed: 'No hemos podido procesar el pago.',
    redirectStatusFailure:
      'No hemos podido procesar el pago. Int\u00e9ntalo de nuevo.\u00a0',
    continue: 'Continuar {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Al hacer clic en el bot\u00f3n \u00abContinuar\u00bb que se muestra a continuaci\u00f3n, aceptas los ',
    authCheckoutStatement:
      'Pague con la tarjeta guardada, use un nuevo m\u00e9todo de pago o use Pay By Bank.',
    useNewCardTextBeforePayByBank: 'Pague con la tarjeta guardada, ',
    usePayByBank: ', o use ',
    payByBankLinkText: 'Pay By Bank',
    payByBankHeader: 'Usar Pay By Bank',
    use: 'Usar ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Usar nuevo m\u00e9todo de pago',
    useNewPaymentDropdownText: 'usar nuevo m\u00e9todo de pago',
    useNewPaymentTextBefore: 'Pague con el m\u00e9todo de pago guardado o ',
    useNewPaymentLinkText: 'usar nuevo m\u00e9todo de pago',
    useNewPaymentTextBeforePayByBank:
      'Pague con el m\u00e9todo de pago guardado, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Actualmente no tienes ning\u00fan m\u00e9todo de pago en tu monedero. Empieza a\u00f1adiendo el primer m\u00e9todo de pago a tu monedero.',
    addPaymentMethod: 'A\u00f1adir m\u00e9todo de pago',
    makeDefault: 'Convertir este m\u00e9todo de pago en predeterminado',
    changeDefaultPMSuccess:
      'Su m\u00e9todo de pago predeterminado ha cambiado.',
    makeDefaultSuccessPM:
      'M\u00e9todo de pago a\u00f1adido como predeterminado.',
    makeDefaultErrorPM:
      'Error al establecer el m\u00e9todo de pago como predeterminado.',
    removePMSuccess: 'M\u00e9todo de pago eliminado de la Cartera de Ford.com',
    removePMError: 'M\u00e9todo de pago eliminado de la Cartera de Ford.com',
    removePM: 'Eliminar m\u00e9todo de pago',
    removePMQuestion: '\u00bfEliminar m\u00e9todo de pago?',
    removePMText:
      '\u00bfEst\u00e1s seguro de que quieres eliminar el m\u00e9todo de pago {{cardNumber}} de tu Cartera? Todas las suscripciones activas asociadas con este m\u00e9todo de pago fallar\u00e1n si se elimina el m\u00e9todo de pago.',
    defaultCard: 'Tarjeta predeterminada',
    defaultPM: 'M\u00e9todo de pago predeterminado',
    defaultCardQuestion:
      '\u00bfQuieres configurar esta tarjeta como tarjeta predeterminada?',
    defaultPMQuestion: '\u00bfM\u00e9todo de pago predeterminado?',
    defaultCardText:
      '\u00bfConfirmas que ahora deseas que tu tarjeta predeterminada sea la {{cardNumber}}?',
    defaultPMText:
      '\u00bfEst\u00e1s seguro de que quieres cambiar tu m\u00e9todo de pago predeterminado a {{cardNumber}}?',
    navigationLabel:
      'Navegar por las opciones de administraci\u00f3n de cuenta',
    removeDefaultPMSuccess:
      'M\u00e9todo de pago predeterminado eliminado de la Cartera de Ford.com',
    removeDefaultCardError:
      'Se ha producido un error al eliminar la tarjeta predeterminada.',
    removeDefaultPMError:
      'M\u00e9todo de pago predeterminado eliminado de la Cartera de Ford.com',
    removeDefaultCard: 'Eliminar tu tarjeta predeterminada',
    removeDefaultPM: '\u00bfEliminar tu m\u00e9todo de pago predeterminado?',
    removeDefaultPMText:
      'El m\u00e9todo de pago {{cardNumber}} es su m\u00e9todo de pago predeterminado. Por favor, elija un nuevo m\u00e9todo de pago predeterminado a continuaci\u00f3n. Todas las suscripciones activas asociadas con este m\u00e9todo de pago fallar\u00e1n si se elimina el m\u00e9todo de pago.',
    removeDefaultPMButton:
      'Eliminar m\u00e9todo de pago y actualizar predeterminado',
    removeDefaultSelectPM:
      'Seleccionar nuevo m\u00e9todo de pago predeterminado',
    title: 'Cartera',
    expiration: 'Caducidad\u00a0{{date}}',
    expires: 'Vence el {{date}}',
    stripeDefaultError:
      'Se ha producido un problema t\u00e9cnico. Int\u00e9ntalo de nuevo.\u00a0',
    paymentMethod: 'M\u00e9todo de pago',
    tosTextBefore:
      'Al hacer clic en el bot\u00f3n \u00abGuardar tarjeta\u00bb que se muestra a continuaci\u00f3n, aceptas los ',
    tosTextBeforePM:
      'Al hacer clic en el bot\u00f3n "Guardar m\u00e9todo de pago" a continuaci\u00f3n, aceptas los',
    tosLinkText:
      'T\u00e9rminos y Condiciones de Pago y la Pol\u00edtica de Privacidad',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Todo',
    amount: 'Cuant\u00eda',
    date: 'Fecha',
    description: 'Descripci\u00f3n',
    displayingAll: 'Se muestran todas las transacciones.',
    displayingDays:
      'Se muestran las transacciones de los \u00faltimos {{numberOfDays}} d\u00edas.',
    displayingYear: 'Se muestran las transacciones del a\u00f1o {{year}}.',
    filter: '\u00daltimos {{numberOfDays}} d\u00edas',
    loadAll: 'Cargar todo',
    loadMore: 'Cargar {{x}} m\u00e1s',
    noTransactions: 'No hay transacciones para mostrar.',
    paymentMethod: 'M\u00e9todo de pago',
    refund: 'Reembolso',
    searchBar: 'Buscar por descripci\u00f3n',
    timeFilter: 'Selecciona un plazo para ver el historial de transacciones',
    title: 'Transacciones',
    pending: 'Pendiente'
  },
  mit: {
    confirmPurchase: 'Confirmar compra',
    paymentSuccessful: 'Pago completado con \u00e9xito',
    saveCardAlert:
      'Para utilizar esta tarjeta en futuros pagos, es necesario guardarla en la cartera de Ford.com.',
    title: 'Selecci\u00f3n del m\u00e9todo de pago',
    tosTextBefore:
      'Al hacer clic en el bot\u00f3n {{paymentButtonText}} que se muestra a continuaci\u00f3n, aceptas los ',
    tosLinkText:
      'T\u00e9rminos y Condiciones de Pago y la Pol\u00edtica de Privacidad',
    tosTextAfter: '.',
    useNewCard: 'Usar una nueva tarjeta',
    useNewCardDropDownItem: 'Usar una nueva tarjeta',
    useNewCardTextBefore: 'Seleccionar una tarjeta guardada o ',
    useNewCardLinkText: 'usar una nueva tarjeta',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Usar nuevo m\u00e9todo de pago',
    useNewPaymentDropdownText: 'usar nuevo m\u00e9todo de pago',
    useNewPaymentTextBefore: 'Pague con el m\u00e9todo de pago guardado o ',
    useNewPaymentLinkText: 'usar nuevo m\u00e9todo de pago',
    successful: 'Exitoso',
    savePaymentMethodAlert:
      'Para utilizar este m\u00e9todo de pago para pagos futuros, debe guardarlo en su billetera.'
  }
};
