import { enUS, TRANSLATION_MISSING } from './en-US';

export const elGR: typeof enUS = {
  common: {
    default:
      '\u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7',
    endingIn: '\u03bb\u03ae\u03b3\u03b5\u03b9 \u03c3\u03b5',
    close: '\u039a\u03bb\u03b5\u03af\u03c3\u03b9\u03bc\u03bf',
    makeDefaultCard:
      '\u039f\u03c1\u03af\u03c3\u03c4\u03b5 \u03c9\u03c2 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1',
    makeDefaultInfoHover:
      '\u0391\u03c5\u03c4\u03ae \u03b8\u03b1 \u03b3\u03af\u03bd\u03b5\u03b9 \u03b1\u03c5\u03c4\u03cc\u03bc\u03b1\u03c4\u03b1 \u03b7 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1 \u03c3\u03b1\u03c2, \u03b5\u03c0\u03b5\u03b9\u03b4\u03ae \u03b5\u03af\u03bd\u03b1\u03b9 \u03b7 \u03c0\u03c1\u03ce\u03c4\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1 \u03c3\u03c4\u03bf \u03c0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9 \u03c3\u03b1\u03c2.',
    processing:
      '\u03a5\u03c0\u03cc \u03b5\u03c0\u03b5\u03be\u03b5\u03c1\u03b3\u03b1\u03c3\u03af\u03b1\u2026',
    saveCardCheck:
      '\u0391\u03c0\u03bf\u03b8\u03b7\u03ba\u03b5\u03cd\u03c3\u03c4\u03b5 \u03c4\u03b7\u03bd \u03ba\u03ac\u03c1\u03c4\u03b1 \u03b3\u03b9\u03b1 \u03bc\u03b5\u03bb\u03bb\u03bf\u03bd\u03c4\u03b9\u03ba\u03ad\u03c2 \u03c3\u03c5\u03bd\u03b1\u03bb\u03bb\u03b1\u03b3\u03ad\u03c2',
    saveCardSuccess:
      '\u0397 \u03ba\u03ac\u03c1\u03c4\u03b1 \u03c0\u03c1\u03bf\u03c3\u03c4\u03ad\u03b8\u03b7\u03ba\u03b5 \u03c3\u03c4\u03bf \u03c0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9 \u03c4\u03bf\u03c5 Ford.com',
    technicalErrorPayment:
      '\u039b\u03c5\u03c0\u03bf\u03cd\u03bc\u03b1\u03c3\u03c4\u03b5! \u03a0\u03b1\u03c1\u03bf\u03c5\u03c3\u03b9\u03ac\u03c3\u03c4\u03b7\u03ba\u03b5 \u03c4\u03b5\u03c7\u03bd\u03b9\u03ba\u03cc \u03c0\u03c1\u03cc\u03b2\u03bb\u03b7\u03bc\u03b1 \u03ba\u03b1\u03b9 \u03b7 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae \u03c3\u03b1\u03c2 \u03b4\u03b5\u03bd \u03b4\u03b9\u03b5\u03ba\u03c0\u03b5\u03c1\u03b1\u03b9\u03ce\u03b8\u03b7\u03ba\u03b5. \u0394\u03bf\u03ba\u03b9\u03bc\u03ac\u03c3\u03c4\u03b5 \u03be\u03b1\u03bd\u03ac.',
    timeoutSorry:
      '\u039b\u03c5\u03c0\u03bf\u03cd\u03bc\u03b1\u03c3\u03c4\u03b5\u2026',
    timeoutError:
      '\u0391\u03c5\u03c4\u03ae\u03bd \u03c4\u03b7 \u03c3\u03c4\u03b9\u03b3\u03bc\u03ae \u03b1\u03bd\u03c4\u03b9\u03bc\u03b5\u03c4\u03c9\u03c0\u03af\u03b6\u03bf\u03c5\u03bc\u03b5 \u03c4\u03b5\u03c7\u03bd\u03b9\u03ba\u03ad\u03c2 \u03b4\u03c5\u03c3\u03ba\u03bf\u03bb\u03af\u03b5\u03c2.',
    timeoutTryAgain:
      '\u0394\u03bf\u03ba\u03b9\u03bc\u03ac\u03c3\u03c4\u03b5 \u03be\u03b1\u03bd\u03ac',
    savePaymentMethodCheck:
      '\u0391\u03c0\u03bf\u03b8\u03ae\u03ba\u03b5\u03c5\u03c3\u03b7 \u03c4\u03c1\u03cc\u03c0\u03bf\u03c5 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03b3\u03b9\u03b1 \u03bc\u03b5\u03bb\u03bb\u03bf\u03bd\u03c4\u03b9\u03ba\u03ad\u03c2 \u03c3\u03c5\u03bd\u03b1\u03bb\u03bb\u03b1\u03b3\u03ad\u03c2',
    makeDefaultPaymentMethod:
      '\u039f\u03c1\u03af\u03c3\u03c4\u03b5 \u03c9\u03c2 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    savePaymentMethodButton:
      '\u0391\u03c0\u03bf\u03b8\u03ae\u03ba\u03b5\u03c5\u03c3\u03b7 \u03a4\u03c1\u03cc\u03c0\u03bf\u03c5 \u03a0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      '\u0391\u03c5\u03c4\u03cc\u03c2 \u03b8\u03b1 \u03b3\u03af\u03bd\u03b5\u03b9 \u03b1\u03c5\u03c4\u03cc\u03bc\u03b1\u03c4\u03b1 \u03bf \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03bf\u03c2 \u03c4\u03c1\u03cc\u03c0\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c3\u03b1\u03c2, \u03b5\u03c0\u03b5\u03b9\u03b4\u03ae \u03b5\u03af\u03bd\u03b1\u03b9 \u03bf \u03c0\u03c1\u03ce\u03c4\u03bf\u03c2 \u03c4\u03c1\u03cc\u03c0\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c3\u03c4\u03bf \u03c0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9 \u03c3\u03b1\u03c2.',
    savePaymentMethodSuccess:
      '\u039f \u03c4\u03c1\u03cc\u03c0\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03ad\u03c7\u03b5\u03b9 \u03c0\u03c1\u03bf\u03c3\u03c4\u03b5\u03b8\u03b5\u03af \u03c3\u03c4\u03bf \u03a0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9.'
  },
  checkout: {
    payButton:
      '\u03a0\u03bb\u03b7\u03c1\u03ce\u03c3\u03c4\u03b5 {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      '\u039b\u03c5\u03c0\u03bf\u03cd\u03bc\u03b1\u03c3\u03c4\u03b5! \u03a0\u03b1\u03c1\u03bf\u03c5\u03c3\u03b9\u03ac\u03c3\u03c4\u03b7\u03ba\u03b5 \u03c4\u03b5\u03c7\u03bd\u03b9\u03ba\u03cc \u03c0\u03c1\u03cc\u03b2\u03bb\u03b7\u03bc\u03b1 \u03ba\u03b1\u03b9 \u03b7 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae \u03c3\u03b1\u03c2 \u03b4\u03b5\u03bd \u03b4\u03b9\u03b5\u03ba\u03c0\u03b5\u03c1\u03b1\u03b9\u03ce\u03b8\u03b7\u03ba\u03b5. \u0391\u03bd\u03b1\u03bd\u03b5\u03ce\u03c3\u03c4\u03b5 \u03c4\u03b7 \u03c3\u03b5\u03bb\u03af\u03b4\u03b1 \u03ba\u03b1\u03b9 \u03b4\u03bf\u03ba\u03b9\u03bc\u03ac\u03c3\u03c4\u03b5 \u03be\u03b1\u03bd\u03ac. ',
    technicalErrorPaymentDetachFail:
      '\u039b\u03c5\u03c0\u03bf\u03cd\u03bc\u03b1\u03c3\u03c4\u03b5! \u03a0\u03b1\u03c1\u03bf\u03c5\u03c3\u03b9\u03ac\u03c3\u03c4\u03b7\u03ba\u03b5 \u03c4\u03b5\u03c7\u03bd\u03b9\u03ba\u03cc \u03c0\u03c1\u03cc\u03b2\u03bb\u03b7\u03bc\u03b1 \u03ba\u03b1\u03b9 \u03b7 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae \u03c3\u03b1\u03c2 \u03b4\u03b5\u03bd \u03b4\u03b9\u03b5\u03ba\u03c0\u03b5\u03c1\u03b1\u03b9\u03ce\u03b8\u03b7\u03ba\u03b5. \u0397 \u03ba\u03ac\u03c1\u03c4\u03b1 \u03b1\u03c0\u03bf\u03b8\u03b7\u03ba\u03b5\u03cd\u03c4\u03b7\u03ba\u03b5 \u03c3\u03c4\u03bf \u03c0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9 \u03c3\u03b1\u03c2. \u0394\u03bf\u03ba\u03b9\u03bc\u03ac\u03c3\u03c4\u03b5 \u03be\u03b1\u03bd\u03ac.',
    tosTextBefore:
      '\u039a\u03ac\u03bd\u03bf\u03bd\u03c4\u03b1\u03c2 \u03ba\u03bb\u03b9\u03ba \u03c3\u03c4\u03bf \u03ba\u03bf\u03c5\u03bc\u03c0\u03af \u00ab\u03a0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u00bb \u03c0\u03b1\u03c1\u03b1\u03ba\u03ac\u03c4\u03c9, \u03c3\u03c5\u03bc\u03c6\u03c9\u03bd\u03b5\u03af\u03c4\u03b5 \u03bc\u03b5 \u03c4\u03bf\u03c5\u03c2 ',
    tosLinkText:
      '\u03cc\u03c1\u03bf\u03c5\u03c2 \u03ba\u03b1\u03b9 \u03c4\u03b9\u03c2 \u03c0\u03c1\u03bf\u03cb\u03c0\u03bf\u03b8\u03ad\u03c3\u03b5\u03b9\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03ba\u03b1\u03b9 \u03c4\u03b7\u03bd \u03c0\u03bf\u03bb\u03b9\u03c4\u03b9\u03ba\u03ae \u03b1\u03c0\u03bf\u03c1\u03c1\u03ae\u03c4\u03bf\u03c5',
    tosTextAfter: '.',
    useNewCard:
      '\u03a7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03b1 \u03ba\u03ac\u03c1\u03c4\u03b1',
    useNewCardDropdownText:
      '\u03a0\u03c1\u03bf\u03c3\u03b8\u03ad\u03c3\u03c4\u03b5 \u03bd\u03ad\u03b1 \u03ba\u03ac\u03c1\u03c4\u03b1',
    useNewCardTextBefore:
      '\u03a0\u03bb\u03b7\u03c1\u03ce\u03c3\u03c4\u03b5 \u03bc\u03b5 \u03b1\u03c0\u03bf\u03b8\u03b7\u03ba\u03b5\u03c5\u03bc\u03ad\u03bd\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1 \u03ae ',
    tosTextBeforeForFordPayGuestId:
      '\u039a\u03ac\u03bd\u03bf\u03bd\u03c4\u03b1\u03c2 \u03ba\u03bb\u03b9\u03ba \u03c3\u03c4\u03bf \u03ba\u03bf\u03c5\u03bc\u03c0\u03af {{paymentButtonText}} \u03c0\u03b1\u03c1\u03b1\u03ba\u03ac\u03c4\u03c9, \u03c3\u03c5\u03bc\u03c6\u03c9\u03bd\u03b5\u03af\u03c4\u03b5 \u03bc\u03b5 \u03c4\u03bf\u03c5\u03c2 ',
    useNewCardLinkText:
      '\u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    useNewCardTextAfter: '.',
    cardSelect:
      '\u0395\u03c0\u03b9\u03bb\u03bf\u03b3\u03ae \u03c0\u03b9\u03c3\u03c4\u03c9\u03c4\u03b9\u03ba\u03ae\u03c2 \u03ba\u03ac\u03c1\u03c4\u03b1\u03c2',
    checkoutFaster:
      '\u039f\u03bb\u03bf\u03ba\u03bb\u03b7\u03c1\u03ce\u03c3\u03c4\u03b5 \u03c4\u03b7\u03bd \u03b1\u03b3\u03bf\u03c1\u03ac \u03c0\u03b9\u03bf \u03b3\u03c1\u03ae\u03b3\u03bf\u03c1\u03b1 \u03bc\u03b5 \u03c4\u03bf FordPay.',
    checkout: '\u03a0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae',
    nameOnCard:
      '\u038c\u03bd\u03bf\u03bc\u03b1 \u03c3\u03c4\u03b7\u03bd \u03ba\u03ac\u03c1\u03c4\u03b1',
    fullName:
      '\u039f\u03bd\u03bf\u03bc\u03b1\u03c4\u03b5\u03c0\u03ce\u03bd\u03c5\u03bc\u03bf',
    cardNumber:
      '\u0391\u03c1\u03b9\u03b8\u03bc\u03cc\u03c2 \u03ba\u03ac\u03c1\u03c4\u03b1\u03c2',
    expirationDate:
      '\u0397\u03bc\u03b5\u03c1\u03bf\u03bc. \u03bb\u03ae\u03be\u03b7\u03c2',
    securityCode:
      '\u039a\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 \u03b1\u03c3\u03c6\u03b1\u03bb\u03b5\u03af\u03b1\u03c2',
    invalidCCNMessage:
      '\u039f \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc\u03c2 \u03c4\u03b7\u03c2 \u03ba\u03ac\u03c1\u03c4\u03b1\u03c2 \u03c3\u03b1\u03c2 \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2.',
    invalidCVVMessage:
      '\u039f \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 \u03b1\u03c3\u03c6\u03b1\u03bb\u03b5\u03af\u03b1\u03c2 \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2.',
    invalidExpDateMessage:
      '\u0397 \u03b7\u03bc\u03b5\u03c1\u03bf\u03bc\u03b7\u03bd\u03af\u03b1 \u03bb\u03ae\u03be\u03b7\u03c2 \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7.',
    emptyCCNMessage:
      '\u039f \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc\u03c2 \u03c4\u03b7\u03c2 \u03ba\u03ac\u03c1\u03c4\u03b1\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 \u03ba\u03b5\u03bd\u03cc\u03c2.',
    emptyCVVMessage:
      '\u039f \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 \u03b1\u03c3\u03c6\u03b1\u03bb\u03b5\u03af\u03b1\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 \u03ba\u03b5\u03bd\u03cc\u03c2.',
    emptyExpDateMessage:
      '\u0397 \u03b7\u03bc\u03b5\u03c1\u03bf\u03bc\u03b7\u03bd\u03af\u03b1 \u03bb\u03ae\u03be\u03b7\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 \u03ba\u03b5\u03bd\u03ae.',
    unsupportedCardMessage:
      '\u039f \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc\u03c2 \u03c4\u03b7\u03c2 \u03ba\u03ac\u03c1\u03c4\u03b1\u03c2 \u03c3\u03b1\u03c2 \u03b4\u03b5\u03bd \u03c5\u03c0\u03bf\u03c3\u03c4\u03b7\u03c1\u03af\u03b6\u03b5\u03c4\u03b1\u03b9. \u03a0\u03bb\u03b7\u03ba\u03c4\u03c1\u03bf\u03bb\u03bf\u03b3\u03ae\u03c3\u03c4\u03b5 VISA, MasterCard \u03ae AMEX.',
    paymentNotProcessed:
      '\u0394\u03b5\u03bd \u03ae\u03c4\u03b1\u03bd \u03b4\u03c5\u03bd\u03b1\u03c4\u03ae \u03b7 \u03b5\u03c0\u03b5\u03be\u03b5\u03c1\u03b3\u03b1\u03c3\u03af\u03b1 \u03c4\u03b7\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c3\u03b1\u03c2',
    redirectStatusFailure:
      '\u0394\u03b5\u03bd \u03ae\u03c4\u03b1\u03bd \u03b4\u03c5\u03bd\u03b1\u03c4\u03ae \u03b7 \u03b5\u03c0\u03b5\u03be\u03b5\u03c1\u03b3\u03b1\u03c3\u03af\u03b1 \u03c4\u03b7\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c3\u03b1\u03c2. \u0394\u03bf\u03ba\u03b9\u03bc\u03ac\u03c3\u03c4\u03b5 \u03be\u03b1\u03bd\u03ac.\u00a0',
    continue:
      '\u039d\u03b1 \u03c3\u03c5\u03bd\u03b5\u03c7\u03af\u03c3\u03b5\u03b9 {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      '\u039a\u03ac\u03bd\u03bf\u03bd\u03c4\u03b1\u03c2 \u03ba\u03bb\u03b9\u03ba \u03c3\u03c4\u03bf \u03ba\u03bf\u03c5\u03bc\u03c0\u03af \u00ab\u039d\u03b1 \u03c3\u03c5\u03bd\u03b5\u03c7\u03af\u03c3\u03b5\u03b9\u00bb \u03c0\u03b1\u03c1\u03b1\u03ba\u03ac\u03c4\u03c9, \u03c3\u03c5\u03bc\u03c6\u03c9\u03bd\u03b5\u03af\u03c4\u03b5 \u03bc\u03b5 \u03c4\u03bf\u03c5\u03c2 ',
    authCheckoutStatement:
      'Check out \u03bc\u03b5 \u03b1\u03c0\u03bf\u03b8\u03b7\u03ba\u03b5\u03c5\u03bc\u03ad\u03bd\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1, \u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03ae \u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 Pay By Bank.',
    useNewCardTextBeforePayByBank:
      'Check out \u03bc\u03b5 \u03b1\u03c0\u03bf\u03b8\u03b7\u03ba\u03b5\u03c5\u03bc\u03ad\u03bd\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1, ',
    usePayByBank:
      ', \u03ae \u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 ',
    payByBankLinkText: 'Pay By Bank',
    payByBankHeader:
      '\u03a7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03c4\u03bf Pay By Bank.',
    use: '\u03c7\u03c1\u03ae\u03c3\u03b7',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment:
      '\u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    useNewPaymentDropdownText:
      '\u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    useNewPaymentTextBefore:
      '\u0395\u03bb\u03ad\u03b3\u03be\u03c4\u03b5 \u03bc\u03b5 \u03c4\u03bf\u03bd \u03b1\u03c0\u03bf\u03b8\u03b7\u03ba\u03b5\u03c5\u03bc\u03ad\u03bd\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03ae ',
    useNewPaymentLinkText:
      '\u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    useNewPaymentTextBeforePayByBank:
      '\u0395\u03bb\u03ad\u03b3\u03be\u03c4\u03b5 \u03bc\u03b5 \u03c4\u03bf\u03bd \u03b1\u03c0\u03bf\u03b8\u03b7\u03ba\u03b5\u03c5\u03bc\u03ad\u03bd\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      '\u03a0\u03c1\u03bf\u03c2 \u03c4\u03bf \u03c0\u03b1\u03c1\u03cc\u03bd \u03b4\u03b5\u03bd \u03ad\u03c7\u03b5\u03c4\u03b5 \u03ba\u03b1\u03bc\u03af\u03b1 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c3\u03c4\u03bf \u03c0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9 \u03c3\u03b1\u03c2. \u039e\u03b5\u03ba\u03b9\u03bd\u03ae\u03c3\u03c4\u03b5 \u03c0\u03c1\u03bf\u03c3\u03b8\u03ad\u03c4\u03bf\u03bd\u03c4\u03b1\u03c2 \u03c4\u03b7\u03bd \u03c0\u03c1\u03ce\u03c4\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c3\u03c4\u03bf \u03c0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9 \u03c3\u03b1\u03c2.',
    addPaymentMethod:
      '\u03a0\u03c1\u03bf\u03c3\u03b8\u03ad\u03c3\u03c4\u03b5 \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    makeDefault:
      '\u039f\u03c1\u03af\u03c3\u03c4\u03b5 \u03c9\u03c2 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03bf\u03b3\u03ae',
    changeDefaultPMSuccess:
      '\u0397 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c3\u03b1\u03c2 \u03ad\u03c7\u03b5\u03b9 \u03b1\u03bb\u03bb\u03ac\u03be\u03b5\u03b9. | | en-CA | Your default payment method has changed.',
    makeDefaultSuccessPM:
      '\u0397 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c0\u03c1\u03bf\u03c3\u03c4\u03ad\u03b8\u03b7\u03ba\u03b5 \u03c9\u03c2 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7.',
    makeDefaultErrorPM:
      '\u03a3\u03c6\u03ac\u03bb\u03bc\u03b1 \u03ba\u03b1\u03c4\u03ac \u03c4\u03b7\u03bd \u03c1\u03cd\u03b8\u03bc\u03b9\u03c3\u03b7 \u03c4\u03b7\u03c2 \u03bc\u03b5\u03b8\u03cc\u03b4\u03bf\u03c5 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c9\u03c2 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7',
    removePMSuccess:
      '\u0397 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03b1\u03c6\u03b1\u03b9\u03c1\u03ad\u03b8\u03b7\u03ba\u03b5 \u03b1\u03c0\u03cc \u03c4\u03bf \u03c0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9 Ford.com',
    removePMError:
      '\u03a3\u03c6\u03ac\u03bb\u03bc\u03b1 \u03ba\u03b1\u03c4\u03ac \u03c4\u03b7\u03bd \u03b1\u03c6\u03b1\u03af\u03c1\u03b5\u03c3\u03b7 \u03c4\u03b7\u03c2 \u03bc\u03b5\u03b8\u03cc\u03b4\u03bf\u03c5 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2.',
    removePM:
      '\u0391\u03c6\u03b1\u03af\u03c1\u03b5\u03c3\u03b7 \u03bc\u03b5\u03b8\u03cc\u03b4\u03bf\u03c5 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    removePMQuestion:
      '\u0391\u03c6\u03b1\u03af\u03c1\u03b5\u03c3\u03b7 \u03bc\u03b5\u03b8\u03cc\u03b4\u03bf\u03c5 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2;',
    removePMText:
      '\u0395\u03af\u03c3\u03c4\u03b5 \u03c3\u03af\u03b3\u03bf\u03c5\u03c1\u03bf\u03b9 \u03cc\u03c4\u03b9 \u03b8\u03ad\u03bb\u03b5\u03c4\u03b5 \u03bd\u03b1 \u03b1\u03c6\u03b1\u03b9\u03c1\u03ad\u03c3\u03b5\u03c4\u03b5 \u03c4\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 {{cardNumber}} \u03b1\u03c0\u03cc \u03c4\u03bf \u03c0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9 \u03c3\u03b1\u03c2. \u038c\u03bb\u03b5\u03c2 \u03bf\u03b9 \u03b5\u03bd\u03b5\u03c1\u03b3\u03ad\u03c2 \u03c3\u03c5\u03bd\u03b4\u03c1\u03bf\u03bc\u03ad\u03c2 \u03c0\u03bf\u03c5 \u03c3\u03c7\u03b5\u03c4\u03af\u03b6\u03bf\u03bd\u03c4\u03b1\u03b9 \u03bc\u03b5 \u03b1\u03c5\u03c4\u03cc\u03bd \u03c4\u03bf\u03bd \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03b8\u03b1 \u03b1\u03c0\u03bf\u03c4\u03cd\u03c7\u03bf\u03c5\u03bd \u03b5\u03ac\u03bd \u03ba\u03b1\u03c4\u03b1\u03c1\u03b3\u03b7\u03b8\u03b5\u03af \u03bf \u03c4\u03c1\u03cc\u03c0\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2.',
    defaultCard:
      '\u03a0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1',
    defaultPM:
      '\u03a0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    defaultCardQuestion:
      '\u03a0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1;',
    defaultPMQuestion:
      '\u03a0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2;',
    defaultCardText:
      '\u0395\u03af\u03c3\u03c4\u03b5 \u03b2\u03ad\u03b2\u03b1\u03b9\u03bf\u03b9 \u03cc\u03c4\u03b9 \u03b8\u03ad\u03bb\u03b5\u03c4\u03b5 \u03bd\u03b1 \u03b1\u03bb\u03bb\u03ac\u03be\u03b5\u03c4\u03b5 \u03c4\u03b7\u03bd \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1 \u03c3\u03b1\u03c2 \u03c3\u03b5 {{cardNumber}};',
    defaultPMText:
      '\u0395\u03af\u03c3\u03c4\u03b5 \u03c3\u03af\u03b3\u03bf\u03c5\u03c1\u03bf\u03b9 \u03cc\u03c4\u03b9 \u03b8\u03ad\u03bb\u03b5\u03c4\u03b5 \u03bd\u03b1 \u03b1\u03bb\u03bb\u03ac\u03be\u03b5\u03c4\u03b5 \u03c4\u03b7\u03bd \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c3\u03b1\u03c2 \u03c3\u03b5 {{cardNumber}};\u00a0',
    navigationLabel:
      '\u03a0\u03bb\u03bf\u03ae\u03b3\u03b7\u03c3\u03b7 \u03b4\u03b9\u03b1\u03c7\u03b5\u03af\u03c1\u03b9\u03c3\u03b7\u03c2 \u03bb\u03bf\u03b3\u03b1\u03c1\u03b9\u03b1\u03c3\u03bc\u03bf\u03cd',
    removeDefaultPMSuccess:
      '\u0397 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03b1\u03c6\u03b1\u03b9\u03c1\u03ad\u03b8\u03b7\u03ba\u03b5 \u03b1\u03c0\u03cc \u03c4\u03bf \u03c0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9 Ford.com',
    removeDefaultCardError:
      '\u03a3\u03c6\u03ac\u03bb\u03bc\u03b1 \u03ba\u03b1\u03c4\u03ac \u03c4\u03b7\u03bd \u03b1\u03c6\u03b1\u03af\u03c1\u03b5\u03c3\u03b7 \u03c4\u03b7\u03c2 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7\u03c2 \u03ba\u03ac\u03c1\u03c4\u03b1\u03c2.',
    removeDefaultPMError:
      '\u03a3\u03c6\u03ac\u03bb\u03bc\u03b1 \u03ba\u03b1\u03c4\u03ac \u03c4\u03b7\u03bd \u03b1\u03c6\u03b1\u03af\u03c1\u03b5\u03c3\u03b7 \u03c4\u03b7\u03c2 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7\u03c2 \u03bc\u03b5\u03b8\u03cc\u03b4\u03bf\u03c5 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2.',
    removeDefaultCard:
      '\u0391\u03c6\u03b1\u03b9\u03c1\u03ad\u03c3\u03c4\u03b5 \u03c4\u03b7\u03bd \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1 \u03c3\u03b1\u03c2',
    removeDefaultPM:
      '\u0391\u03c6\u03b1\u03b9\u03c1\u03ad\u03c3\u03c4\u03b5 \u03c4\u03b7\u03bd \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c3\u03b1\u03c2;',
    removeDefaultPMText:
      '\u0397 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 {{cardNumber}} \u03b5\u03af\u03bd\u03b1\u03b9 \u03b7 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c3\u03b1\u03c2. \u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03ce \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03bc\u03b9\u03b1 \u03bd\u03ad\u03b1 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03c0\u03b1\u03c1\u03b1\u03ba\u03ac\u03c4\u03c9. \u038c\u03bb\u03b5\u03c2 \u03bf\u03b9 \u03b5\u03bd\u03b5\u03c1\u03b3\u03ad\u03c2 \u03c3\u03c5\u03bd\u03b4\u03c1\u03bf\u03bc\u03ad\u03c2 \u03c0\u03bf\u03c5 \u03c3\u03c7\u03b5\u03c4\u03af\u03b6\u03bf\u03bd\u03c4\u03b1\u03b9 \u03bc\u03b5 \u03b1\u03c5\u03c4\u03cc\u03bd \u03c4\u03bf\u03bd \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03b8\u03b1 \u03b1\u03c0\u03bf\u03c4\u03cd\u03c7\u03bf\u03c5\u03bd \u03b5\u03ac\u03bd \u03ba\u03b1\u03c4\u03b1\u03c1\u03b3\u03b7\u03b8\u03b5\u03af \u03bf \u03c4\u03c1\u03cc\u03c0\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2.',
    removeDefaultPMButton:
      '\u0391\u03c6\u03b1\u03b9\u03c1\u03ad\u03c3\u03c4\u03b5 \u03c4\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03ba\u03b1\u03b9 \u03b5\u03bd\u03b7\u03bc\u03b5\u03c1\u03ce\u03c3\u03c4\u03b5 \u03c4\u03b7\u03bd \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7.',
    removeDefaultSelectPM:
      '\u0395\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03bd\u03ad\u03b1 \u03c0\u03c1\u03bf\u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03bc\u03ad\u03b8\u03bf\u03b4\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2.',
    title: '\u03a0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9',
    expiration: '\u039b\u03ae\u03be\u03b7\u00a0{{date}}',
    expires: '\u039b\u03ae\u03b3\u03b5\u03b9 \u03c3\u03c4\u03b9\u03c2 {{date}}',
    stripeDefaultError:
      '\u03a0\u03b1\u03c1\u03bf\u03c5\u03c3\u03b9\u03ac\u03c3\u03c4\u03b7\u03ba\u03b5 \u03c4\u03b5\u03c7\u03bd\u03b9\u03ba\u03cc \u03c0\u03c1\u03cc\u03b2\u03bb\u03b7\u03bc\u03b1. \u0394\u03bf\u03ba\u03b9\u03bc\u03ac\u03c3\u03c4\u03b5 \u03be\u03b1\u03bd\u03ac.\u00a0',
    paymentMethod:
      '\u03a4\u03c1\u03cc\u03c0\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    tosTextBefore:
      '\u039a\u03ac\u03bd\u03bf\u03bd\u03c4\u03b1\u03c2 \u03ba\u03bb\u03b9\u03ba \u03c3\u03c4\u03bf \u03ba\u03bf\u03c5\u03bc\u03c0\u03af \u00ab\u0391\u03c0\u03bf\u03b8\u03ae\u03ba\u03b5\u03c5\u03c3\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1\u03c2\u00bb \u03c0\u03b1\u03c1\u03b1\u03ba\u03ac\u03c4\u03c9, \u03c3\u03c5\u03bc\u03c6\u03c9\u03bd\u03b5\u03af\u03c4\u03b5 \u03bc\u03b5 \u03c4\u03bf\u03c5\u03c2 ',
    tosTextBeforePM:
      '\u039a\u03ac\u03bd\u03bf\u03bd\u03c4\u03b1\u03c2 \u03ba\u03bb\u03b9\u03ba \u03c3\u03c4\u03bf \u03ba\u03bf\u03c5\u03bc\u03c0\u03af "\u0391\u03c0\u03bf\u03b8\u03ae\u03ba\u03b5\u03c5\u03c3\u03b7 \u03bc\u03b5\u03b8\u03cc\u03b4\u03bf\u03c5 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2" \u03c0\u03b1\u03c1\u03b1\u03ba\u03ac\u03c4\u03c9, \u03c3\u03c5\u03bc\u03c6\u03c9\u03bd\u03b5\u03af\u03c4\u03b5 \u03bc\u03b5 \u03c4\u03bf\u03c5\u03c2 \u03cc\u03c1\u03bf\u03c5\u03c2 \u03ba\u03b1\u03b9 \u03c0\u03c1\u03bf\u03cb\u03c0\u03bf\u03b8\u03ad\u03c3\u03b5\u03b9\u03c2.',
    tosLinkText:
      '\u03cc\u03c1\u03bf\u03c5\u03c2 \u03ba\u03b1\u03b9 \u03c4\u03b9\u03c2 \u03c0\u03c1\u03bf\u03cb\u03c0\u03bf\u03b8\u03ad\u03c3\u03b5\u03b9\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03ba\u03b1\u03b9 \u03c4\u03b7\u03bd \u03c0\u03bf\u03bb\u03b9\u03c4\u03b9\u03ba\u03ae \u03b1\u03c0\u03bf\u03c1\u03c1\u03ae\u03c4\u03bf\u03c5',
    tosTextAfter: '.'
  },
  transaction: {
    all: '\u038c\u03bb\u03b5\u03c2',
    amount: '\u03a0\u03bf\u03c3\u03cc',
    date: '\u0397\u03bc\u03b5\u03c1\u03bf\u03bc\u03b7\u03bd\u03af\u03b1',
    description: '\u03a0\u03b5\u03c1\u03b9\u03b3\u03c1\u03b1\u03c6\u03ae',
    displayingAll:
      '\u03a0\u03c1\u03bf\u03b2\u03bf\u03bb\u03ae \u03cc\u03bb\u03c9\u03bd \u03c4\u03c9\u03bd \u03c3\u03c5\u03bd\u03b1\u03bb\u03bb\u03b1\u03b3\u03ce\u03bd.',
    displayingDays:
      '\u03a0\u03c1\u03bf\u03b2\u03bf\u03bb\u03ae \u03c3\u03c5\u03bd\u03b1\u03bb\u03bb\u03b1\u03b3\u03ce\u03bd \u03c4\u03c9\u03bd \u03c4\u03b5\u03bb\u03b5\u03c5\u03c4\u03b1\u03af\u03c9\u03bd {{numberOfDays}} \u03b7\u03bc\u03b5\u03c1\u03ce\u03bd.',
    displayingYear:
      '\u03a0\u03c1\u03bf\u03b2\u03bf\u03bb\u03ae \u03c3\u03c5\u03bd\u03b1\u03bb\u03bb\u03b1\u03b3\u03ce\u03bd \u03c4\u03bf\u03c5 {{year}}.',
    filter:
      '\u03a4\u03b5\u03bb\u03b5\u03c5\u03c4\u03b1\u03af\u03b5\u03c2 {{numberOfDays}} \u03b7\u03bc\u03ad\u03c1\u03b5\u03c2',
    loadAll:
      '\u03a0\u03c1\u03bf\u03b2\u03bf\u03bb\u03ae \u03cc\u03bb\u03c9\u03bd',
    loadMore:
      '\u03a0\u03c1\u03bf\u03b2\u03bf\u03bb\u03ae {{x}} \u03b1\u03ba\u03cc\u03bc\u03b7',
    noTransactions:
      '\u0394\u03b5\u03bd \u03c5\u03c0\u03ac\u03c1\u03c7\u03bf\u03c5\u03bd \u03c3\u03c5\u03bd\u03b1\u03bb\u03bb\u03b1\u03b3\u03ad\u03c2 \u03b3\u03b9\u03b1 \u03c0\u03c1\u03bf\u03b2\u03bf\u03bb\u03ae.',
    paymentMethod:
      '\u03a4\u03c1\u03cc\u03c0\u03bf\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    refund:
      '\u0395\u03c0\u03b9\u03c3\u03c4\u03c1\u03bf\u03c6\u03ae \u03c7\u03c1\u03b7\u03bc\u03ac\u03c4\u03c9\u03bd',
    searchBar:
      '\u0391\u03bd\u03b1\u03b6\u03ae\u03c4\u03b7\u03c3\u03b7 \u03b2\u03ac\u03c3\u03b5\u03b9 \u03c0\u03b5\u03c1\u03b9\u03b3\u03c1\u03b1\u03c6\u03ae\u03c2',
    timeFilter:
      '\u0395\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03c7\u03c1\u03bf\u03bd\u03b9\u03ba\u03cc \u03c0\u03bb\u03b1\u03af\u03c3\u03b9\u03bf \u03b3\u03b9\u03b1 \u03c4\u03bf \u03b9\u03c3\u03c4\u03bf\u03c1\u03b9\u03ba\u03cc \u03c3\u03c5\u03bd\u03b1\u03bb\u03bb\u03b1\u03b3\u03ce\u03bd',
    title: '\u03a3\u03c5\u03bd\u03b1\u03bb\u03bb\u03b1\u03b3\u03ad\u03c2',
    pending: '\u03b5\u03ba\u03ba\u03c1\u03b5\u03bc\u03ae\u03c2'
  },
  mit: {
    confirmPurchase:
      '\u0395\u03a0\u0399\u0392\u0395\u0392\u0391\u0399\u03a9\u03a3\u0397 \u0391\u0393\u039f\u03a1\u0391\u03a3',
    paymentSuccessful:
      '\u0395\u03c0\u03b9\u03c4\u03c5\u03c7\u03ae\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae',
    saveCardAlert:
      '\u0393\u03b9\u03b1 \u03bd\u03b1 \u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03b5\u03c4\u03b5 \u03b1\u03c5\u03c4\u03ae\u03bd \u03c4\u03b7\u03bd \u03ba\u03ac\u03c1\u03c4\u03b1 \u03b3\u03b9\u03b1 \u03bc\u03b5\u03bb\u03bb\u03bf\u03bd\u03c4\u03b9\u03ba\u03ad\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ad\u03c2, \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03c4\u03b7\u03bd \u03b1\u03c0\u03bf\u03b8\u03b7\u03ba\u03b5\u03cd\u03c3\u03b5\u03c4\u03b5 \u03c3\u03c4\u03bf \u03c0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9 \u03c3\u03b1\u03c2 Ford.com.',
    title:
      '\u0395\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    tosTextBefore:
      '\u039a\u03ac\u03bd\u03bf\u03bd\u03c4\u03b1\u03c2 \u03ba\u03bb\u03b9\u03ba \u03c3\u03c4\u03bf \u03ba\u03bf\u03c5\u03bc\u03c0\u03af {{paymentButtonText}} \u03c0\u03b1\u03c1\u03b1\u03ba\u03ac\u03c4\u03c9, \u03c3\u03c5\u03bc\u03c6\u03c9\u03bd\u03b5\u03af\u03c4\u03b5 \u03bc\u03b5 \u03c4\u03bf\u03c5\u03c2 ',
    tosLinkText:
      '\u03cc\u03c1\u03bf\u03c5\u03c2 \u03ba\u03b1\u03b9 \u03c4\u03b9\u03c2 \u03c0\u03c1\u03bf\u03cb\u03c0\u03bf\u03b8\u03ad\u03c3\u03b5\u03b9\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03ba\u03b1\u03b9 \u03c4\u03b7\u03bd \u03c0\u03bf\u03bb\u03b9\u03c4\u03b9\u03ba\u03ae \u03b1\u03c0\u03bf\u03c1\u03c1\u03ae\u03c4\u03bf\u03c5',
    tosTextAfter: '.',
    useNewCard:
      '\u03a7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03b1 \u03ba\u03ac\u03c1\u03c4\u03b1',
    useNewCardDropDownItem:
      '\u03a7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03b1 \u03ba\u03ac\u03c1\u03c4\u03b1',
    useNewCardTextBefore:
      '\u0395\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03b1\u03c0\u03bf\u03b8\u03b7\u03ba\u03b5\u03c5\u03bc\u03ad\u03bd\u03b7 \u03ba\u03ac\u03c1\u03c4\u03b1 \u03ae ',
    useNewCardLinkText:
      '\u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03b1 \u03ba\u03ac\u03c1\u03c4\u03b1',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment:
      '\u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    useNewPaymentDropdownText:
      '\u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    useNewPaymentTextBefore:
      '\u0395\u03bb\u03ad\u03b3\u03be\u03c4\u03b5 \u03bc\u03b5 \u03c4\u03bf\u03bd \u03b1\u03c0\u03bf\u03b8\u03b7\u03ba\u03b5\u03c5\u03bc\u03ad\u03bd\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03ae ',
    useNewPaymentLinkText:
      '\u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03c4\u03b5 \u03bd\u03ad\u03bf \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2',
    successful: '\u0395\u03c0\u03b9\u03c4\u03c5\u03c7\u03ae\u03c2',
    savePaymentMethodAlert:
      '\u0393\u03b9\u03b1 \u03bd\u03b1 \u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03b5\u03c4\u03b5 \u03b1\u03c5\u03c4\u03cc\u03bd \u03c4\u03bf\u03bd \u03c4\u03c1\u03cc\u03c0\u03bf \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ae\u03c2 \u03b3\u03b9\u03b1 \u03bc\u03b5\u03bb\u03bb\u03bf\u03bd\u03c4\u03b9\u03ba\u03ad\u03c2 \u03c0\u03bb\u03b7\u03c1\u03c9\u03bc\u03ad\u03c2, \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b1\u03c0\u03bf\u03b8\u03b7\u03ba\u03b5\u03c5\u03c4\u03b5\u03af \u03c3\u03c4\u03bf \u03c0\u03bf\u03c1\u03c4\u03bf\u03c6\u03cc\u03bb\u03b9 \u03c3\u03b1\u03c2.'
  }
};
