import React from 'react';
import { InputGridArea } from '@fpc/common/components/InputComponents';
import { getWalletIcon } from '@fpc/common';

interface WalletPaymentInputProps {
  walletName: string;
}

const WalletPaymentInput: React.FC<WalletPaymentInputProps> = ({
  walletName
}) => {
  return (
    <InputGridArea
      style={{ gridArea: '1 / 1 / auto / -1', alignItems: 'center' }}
    >
      {getWalletIcon(walletName)}
    </InputGridArea>
  );
};

export default WalletPaymentInput;
