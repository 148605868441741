import { enUS, TRANSLATION_MISSING } from './en-US';

export const plPL: typeof enUS = {
  common: {
    default: 'domy\u015blna',
    endingIn: 'koniec za',
    close: 'Zamknij',
    makeDefaultCard: 'Ustaw jako domy\u015bln\u0105 kart\u0119',
    makeDefaultInfoHover:
      'B\u0119dzie to automatycznie karta domy\u015blna, poniewa\u017c jest to pierwsza karta w portfelu.',
    processing: 'Przetwarzanie...',
    saveCardCheck: 'Zapisz kart\u0119 do przysz\u0142ych transakcji',
    saveCardSuccess: 'Dodano kart\u0119 do portfela Ford.com.',
    technicalErrorPayment:
      'Przepraszamy! Wyst\u0105pi\u0142 problem techniczny, p\u0142atno\u015b\u0107 nie zosta\u0142a przetworzona. Spr\u00f3buj ponownie.',
    timeoutSorry: 'Przykro nam...',
    timeoutError: 'W tej chwili mamy problemy techniczne.',
    timeoutTryAgain: 'Spr\u00f3buj ponownie',
    savePaymentMethodCheck:
      'Zapisz metod\u0119 p\u0142atno\u015bci dla przysz\u0142ych transakcji',
    makeDefaultPaymentMethod:
      'Ustaw domy\u015bln\u0105 metod\u0119 p\u0142atno\u015bci',
    savePaymentMethodButton: 'Zapisz metod\u0119 p\u0142atno\u015bci',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'To automatycznie stanie si\u0119 Twoj\u0105 domy\u015bln\u0105 metod\u0105 p\u0142atno\u015bci, poniewa\u017c jest to pierwsza metoda p\u0142atno\u015bci w Twoim portfelu.',
    savePaymentMethodSuccess:
      'Metoda p\u0142atno\u015bci zosta\u0142a dodana do Portfela.'
  },
  checkout: {
    payButton: 'Zap\u0142a\u0107 {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Przepraszamy! Wyst\u0105pi\u0142 problem techniczny, Twoja p\u0142atno\u015b\u0107 nie zosta\u0142a przetworzona. Od\u015bwie\u017c stron\u0119 i spr\u00f3buj ponownie.\u00a0',
    technicalErrorPaymentDetachFail:
      'Przepraszamy! Wyst\u0105pi\u0142 problem techniczny, p\u0142atno\u015b\u0107 nie zosta\u0142a przetworzona. Twoja karta zosta\u0142a zapisana w portfelu. Spr\u00f3buj ponownie.',
    tosTextBefore:
      'Klikaj\u0105c przycisk \u201eZap\u0142a\u0107\u201d poni\u017cej, akceptujesz ',
    tosTextBeforeForFordPayGuestId:
      'Klikaj\u0105c przycisk {{paymentButtonText}} poni\u017cej, akceptujesz ',
    tosLinkText:
      'Regulamin Funkcji P\u0142atniczych i Polityk\u0119 Prywatno\u015bci',
    tosTextAfter: '.',
    useNewCard: 'U\u017cyj nowej karty',
    useNewCardDropdownText: 'Dodaj now\u0105 kart\u0119',
    useNewCardTextBefore: 'Zap\u0142a\u0107 zapisan\u0105 kart\u0105 lub ',
    useNewCardLinkText: 'u\u017cyj nowej metody p\u0142atno\u015bci',
    useNewCardTextAfter: '.',
    cardSelect: 'Wyb\u00f3r karty kredytowej',
    checkoutFaster: 'Szybsza p\u0142atno\u015b\u0107 z FordPay.',
    checkout: 'Kasa',
    nameOnCard: 'Nazwisko na karcie',
    fullName: 'Imi\u0119 i nazwisko',
    cardNumber: 'Numer karty',
    expirationDate: 'Data wa\u017cno\u015bci',
    securityCode: 'Kod bezpiecze\u0144stwa',
    invalidCCNMessage: 'Numer karty jest niepoprawny.',
    invalidCVVMessage: 'Kod bezpiecze\u0144stwa jest niepoprawny.',
    invalidExpDateMessage: 'Data wa\u017cno\u015bci jest niepoprawna.',
    emptyCCNMessage: 'Brak numeru karty.',
    emptyCVVMessage: 'Brak kodu zabezpieczaj\u0105cego.',
    emptyExpDateMessage: 'Brak daty wa\u017cno\u015bci.',
    unsupportedCardMessage:
      'Ten numer karty nie jest obs\u0142ugiwany. Wprowad\u017a dane karty VISA, MasterCard lub AMEX.',
    paymentNotProcessed:
      'Twoja p\u0142atno\u015b\u0107 nie mog\u0142a zosta\u0107 zrealizowana',
    redirectStatusFailure:
      'Twoja p\u0142atno\u015b\u0107 nie mog\u0142a zosta\u0107 zrealizowana. Spr\u00f3buj ponownie.\u00a0',
    continue: 'Kontynuowa\u0107 {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Klikaj\u0105c przycisk \u201eKontynuowa\u0107 \u201d poni\u017cej, akceptujesz ',
    authCheckoutStatement:
      'Do kasy u\u017cyj zapisanej karty, u\u017cyj nowej metody p\u0142atno\u015bci lub skorzystaj z opcji Pay By Bank.',
    useNewCardTextBeforePayByBank: 'Do kasy u\u017cyj zapisanej karty, ',
    usePayByBank: ', z opcji ',
    payByBankLinkText: 'Pay By Bank',
    payByBankHeader: 'Opcji Pay By Bank.',
    use: 'U\u017cywa\u0107 ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'U\u017cyj nowej metody p\u0142atno\u015bci',
    useNewPaymentDropdownText: 'u\u017cyj nowej metody p\u0142atno\u015bci',
    useNewPaymentTextBefore:
      'Sprawd\u017a, korzystaj\u0105c z zapisanej metody p\u0142atno\u015bci lub ',
    useNewPaymentLinkText: 'u\u017cyj nowej metody p\u0142atno\u015bci',
    useNewPaymentTextBeforePayByBank:
      'Sprawd\u017a, korzystaj\u0105c z zapisanej metody p\u0142atno\u015bci, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Obecnie nie masz \u017cadnych metod p\u0142atno\u015bci w swoim portfelu. Zacznij od dodania pierwszej metody p\u0142atno\u015bci do swojego portfela.',
    addPaymentMethod: 'Dodaj metod\u0119 p\u0142atno\u015bci',
    makeDefault: 'Ustaw jako domy\u015bln\u0105',
    changeDefaultPMSuccess:
      'Twoja domy\u015blna metoda p\u0142atno\u015bci zosta\u0142a zmieniona.',
    makeDefaultSuccessPM:
      'Metoda p\u0142atno\u015bci dodana jako domy\u015blna.',
    makeDefaultErrorPM:
      'B\u0142\u0105d ustawiania metody p\u0142atno\u015bci jako domy\u015blnej.',
    removePMSuccess:
      'Metoda p\u0142atno\u015bci usuni\u0119ta z portfela Ford.com',
    removePMError: 'B\u0142\u0105d usuwania metody p\u0142atno\u015bci.',
    removePM: 'Usu\u0144 metod\u0119 p\u0142atno\u015bci',
    removePMQuestion: 'Usun\u0105\u0107 metod\u0119 p\u0142atno\u015bci?',
    removePMText:
      'Czy na pewno chcesz usun\u0105\u0107 metod\u0119 p\u0142atno\u015bci {{cardNumber}} z portfela?Wszystkie aktywne subskrypcje powi\u0105zane z t\u0105 metod\u0105 p\u0142atno\u015bci nie powiod\u0105 si\u0119, je\u015bli metoda p\u0142atno\u015bci zostanie usuni\u0119ta.',
    defaultCard: 'Ustawi\u0107 jako domy\u015bln\u0105 kart\u0119',
    defaultPM: 'Domy\u015blna metoda p\u0142atno\u015bci',
    defaultCardQuestion: 'Ustawi\u0107 jako domy\u015bln\u0105 kart\u0119?',
    defaultPMQuestion: 'Domy\u015blna metoda p\u0142atno\u015bci',
    defaultCardText:
      'Czy na pewno chcesz zmieni\u0107 swoj\u0105 domy\u015bln\u0105 kart\u0119 na kart\u0119 o numerze {{cardNumber}}?',
    defaultPMText:
      'Czy na pewno chcesz zmieni\u0107 swoj\u0105 domy\u015bln\u0105 metod\u0119 p\u0142atno\u015bci na {{cardNumber}}?',
    navigationLabel: 'Nawigacja konta',
    removeDefaultPMSuccess:
      'Domy\u015blna metoda p\u0142atno\u015bci usuni\u0119ta z portfela Ford.com',
    removeDefaultCardError:
      'B\u0142\u0105d podczas usuwania domy\u015blnej karty.',
    removeDefaultPMError:
      'B\u0142\u0105d usuwania domy\u015blnej metody p\u0142atno\u015bci.\u00a0',
    removeDefaultCard: 'Usu\u0144 domy\u015bln\u0105 kart\u0119',
    removeDefaultPM:
      'Usun\u0105\u0107 swoj\u0105 domy\u015bln\u0105 metod\u0119 p\u0142atno\u015bci?',
    removeDefaultPMText:
      'Metoda p\u0142atno\u015bci {{cardNumber}} jest Twoj\u0105 domy\u015bln\u0105 metod\u0105 p\u0142atno\u015bci. Wybierz poni\u017cej now\u0105 domy\u015bln\u0105 metod\u0119 p\u0142atno\u015bci. Wszystkie aktywne subskrypcje powi\u0105zane z t\u0105 metod\u0105 p\u0142atno\u015bci nie powiod\u0105 si\u0119, je\u015bli metoda p\u0142atno\u015bci zostanie usuni\u0119ta.',
    removeDefaultPMButton:
      'Usu\u0144 metod\u0119 p\u0142atno\u015bci i zaktualizuj domy\u015bln\u0105',
    removeDefaultSelectPM:
      'Wybierz now\u0105 domy\u015bln\u0105 metod\u0119 p\u0142atno\u015bci',
    title: 'Portfel',
    expiration: 'Termin wa\u017cno\u015bci\u00a0{{date}}',
    expires: 'Wygasa dnia {{date}}',
    stripeDefaultError:
      'Wyst\u0105pi\u0142 problem techniczny. Spr\u00f3buj ponownie.\u00a0',
    paymentMethod: 'Metoda p\u0142atno\u015bci',
    tosTextBefore:
      'Klikaj\u0105c przycisk \u201eZapisz kart\u0119\u201d poni\u017cej, akceptujesz ',
    tosTextBeforePM:
      'Klikaj\u0105c przycisk \u201eZapisz metod\u0119 p\u0142atno\u015bci\u201d poni\u017cej, akceptujesz',
    tosLinkText:
      'Regulamin Funkcji P\u0142atniczych i Polityk\u0119 Prywatno\u015bci',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Wszystko',
    amount: 'Kwota',
    date: 'Data',
    description: 'Opis',
    displayingAll: 'Wy\u015bwietlanie wszystkich transakcji.',
    displayingDays:
      'Wy\u015bwietlanie transakcji z ostatnich {{numberOfDays}} dn.',
    displayingYear: 'Wy\u015bwietlanie transakcji z roku {{year}}.',
    filter: 'Ostatnie {{numberOfDays}} dni',
    loadAll: 'Za\u0142aduj wszystkie',
    loadMore: 'Za\u0142aduj {{x}} kolejnych',
    noTransactions: 'Brak transakcji do wy\u015bwietlenia.',
    paymentMethod: 'Metoda p\u0142atno\u015bci',
    refund: 'Zwrot',
    searchBar: 'Wyszukaj wed\u0142ug opisu',
    timeFilter: 'Wybierz ramy czasowe dla historii transakcji',
    title: 'Transakcje',
    pending: 'A\u017c do'
  },
  mit: {
    confirmPurchase: 'Potwierd\u017a zakup',
    paymentSuccessful: 'P\u0142atno\u015b\u0107 zako\u0144czona pomy\u015blnie',
    saveCardAlert:
      'Aby korzysta\u0107 z tej karty podczas przysz\u0142ych p\u0142atno\u015bci, nale\u017cy zapisa\u0107 j\u0105 w portfelu Ford.com.',
    title: 'Wyb\u00f3r metody p\u0142atno\u015bci',
    tosTextBefore:
      'Klikaj\u0105c przycisk {{paymentButtonText}} poni\u017cej, akceptujesz ',
    tosLinkText:
      'Regulamin Funkcji P\u0142atniczych i Polityk\u0119 Prywatno\u015bci',
    tosTextAfter: '.',
    useNewCard: 'U\u017cyj nowej karty',
    useNewCardDropDownItem: 'U\u017cyj nowej karty',
    useNewCardTextBefore: 'Wybierz zapisan\u0105 kart\u0119 lub ',
    useNewCardLinkText: 'u\u017cyj nowej karty',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'U\u017cyj nowej metody p\u0142atno\u015bci',
    useNewPaymentDropdownText: 'u\u017cyj nowej metody p\u0142atno\u015bci',
    useNewPaymentTextBefore:
      'Sprawd\u017a, korzystaj\u0105c z zapisanej metody p\u0142atno\u015bci lub ',
    useNewPaymentLinkText: 'u\u017cyj nowej metody p\u0142atno\u015bci',
    successful: 'Udany',
    savePaymentMethodAlert:
      'Aby m\u00f3c u\u017cywa\u0107 tej metody p\u0142atno\u015bci do przysz\u0142ych p\u0142atno\u015bci, nale\u017cy j\u0105 zapisa\u0107 w portfelu.'
  }
};
