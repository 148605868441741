export type HttpHeaders = {
  [key: string]: string;
};

export enum HttpMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE'
}

export enum HttpHeader {
  CONTENT_TYPE = 'Content-Type',
  TRACEPARENT = 'Traceparent',
  AUTHORIZATION = 'Authorization',
  AUTH_TOKEN = 'auth-token',
  CAT_TOKEN_TYPE = 'x-token-type',
  PSP = 'x-psp',
  MERCHANT_ACCOUNT_ID = 'merchantAccountId'
}

export type IHttpError = {
  code: number;
  message: string;
  unrecoverable: boolean;
  cause: Error;
  traceId: string;
  pspErrorCode?: string;
  declineCode?: string;
};

export class HttpError extends Error {
  constructor(
    public code: number,
    public message: string,
    public unrecoverable: boolean,
    public cause: Error,
    public traceId = '',
    public pspErrorCode?: string,
    public declineCode?: string
  ) {
    super(message);
    this.name = 'HttpError';
    Error.captureStackTrace(this, this.constructor);
  }

  static from(error: IHttpError): HttpError {
    return new HttpError(
      error.code,
      error.message,
      error.unrecoverable,
      error.cause,
      error.traceId,
      error.pspErrorCode,
      error.declineCode
    );
  }

  // eslint-disable-next-line
  static fromError(error: any): HttpError {
    if (error instanceof HttpError) {
      return error;
    }
    return new HttpError(
      -1,
      error.message ?? error.toString(),
      false,
      error,
      ''
    );
  }
}
