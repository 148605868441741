import { useEffect, useState } from 'react';
import {
  Stripe,
  StripeElements,
  StripeElementsOptionsClientSecret,
  StripeElementsOptionsMode,
  StripeExpressCheckoutElementConfirmEvent
} from '@stripe/stripe-js';
import { paymentRequestButtonStyle } from '@fpc/common/Styles';
import { RecurringPaymentRequest } from '@fpc/common/transactionInterfaces';

export interface ExpressCheckoutOptions {
  amount: number;
  currency: string;
  applePay: boolean;
  googlePay: boolean;
  klarna: boolean;
  isSetupMode?: boolean;
  paymentMethodTypes?: string[];
}

interface ExpressCheckoutButtonProps {
  stripe: Stripe;
  onPaymentConfirmCallback: (
    event: StripeExpressCheckoutElementConfirmEvent,
    elements: StripeElements
  ) => void;
  options: ExpressCheckoutOptions;
  finishedLoadingHandler?: (value: boolean) => void;
  recurringPaymentRequest?: RecurringPaymentRequest;
  isGuestMit: boolean;
}

export function ExpressCheckoutButton(props: ExpressCheckoutButtonProps) {
  let options: StripeElementsOptionsClientSecret | StripeElementsOptionsMode =
    props.options.isSetupMode
      ? { mode: 'setup', currency: props.options.currency }
      : {
          mode: 'payment',
          amount: props.options.amount,
          currency: props.options.currency
        };
  if (props.isGuestMit && !!props.options.isSetupMode) {
    options = {
      ...options,
      payment_method_options: {
        us_bank_account: {
          verification_method: 'instant_or_skip'
        }
      },
      paymentMethodTypes: props.options.paymentMethodTypes
    } as unknown as StripeElementsOptionsMode;
  }
  const [elements] = useState<StripeElements>(props.stripe.elements(options));
  const [hasPaymentRequestButtonStyle, setHasPaymentRequestButtonStyle] =
    useState(false);
  const showExpressCheckout =
    props.options.googlePay || props.options.applePay || props.options.klarna;

  useEffect(() => {
    if (showExpressCheckout) {
      mountExpressCheckout();
    }
  }, []);

  function finishLoading() {
    if (props.finishedLoadingHandler) {
      props.finishedLoadingHandler(true);
    }
  }
  function mountExpressCheckout() {
    let loadKlarna = props.options.klarna && !props.options.isSetupMode;
    // @ts-ignore
    const expressCheckout = elements.create('expressCheckout', {
      buttonType: {
        googlePay: 'plain',
        applePay: 'plain',
        klarna: 'pay'
      },
      buttonTheme: {
        googlePay: 'black',
        applePay: 'black',
        klarna: 'dark'
      },
      paymentMethods: {
        applePay: props.options.applePay ? 'auto' : 'never',
        klarna: loadKlarna ? 'auto' : 'never'
      },
      layout: {
        maxRows: 1,
        overflow: 'auto'
      }
    });

    expressCheckout.mount('#express-checkout-button');

    // @ts-ignore
    expressCheckout.on('ready', ({ availablePaymentMethods }) => {
      if (
        availablePaymentMethods &&
        ((availablePaymentMethods.applePay && props.options.applePay) ||
          (availablePaymentMethods.googlePay && props.options.googlePay) ||
          (availablePaymentMethods.klarna && props.options.klarna))
      ) {
        setHasPaymentRequestButtonStyle(true);
      }
      finishLoading();
    });

    expressCheckout.on('click', (event) => {
      if (props.recurringPaymentRequest) {
        event.resolve({
          applePay: { recurringPaymentRequest: props.recurringPaymentRequest }
        });
      } else {
        event.resolve();
      }
    });

    expressCheckout.on('confirm', async (event) => {
      props.onPaymentConfirmCallback(event, elements);
    });
  }
  if (showExpressCheckout) {
    return (
      <>
        <div
          id={'express-checkout-button'}
          data-testid={'express-checkout-button'}
          style={hasPaymentRequestButtonStyle ? paymentRequestButtonStyle : {}}
        >
          {/*Inject Express Checkout Button here*/}
        </div>
        {hasPaymentRequestButtonStyle && <hr style={{ margin: '1em 0' }} />}
      </>
    );
  }
  return <></>;
}
