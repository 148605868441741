import { errorTextStyle } from '@fpc/common/Styles';
import { CSSProperties, useEffect } from 'react';
import { CustomerAuthTokenType } from '@fpc/reactutils/accountContextProvider';
import { dispatchCheckoutFormOnloadEvent } from '@fpc/utils/dispatchEvent';
import { CHECKOUT_ELEMENT } from '@fpc/reactutils/checkoutContextProvider';

interface CheckoutLoadErrorProps {
  language: string | null;
  bearerToken: string | null;
  paymentInfoToken: string | null;
  customerAuthToken?: string | null;
  customerAuthTokenType: CustomerAuthTokenType | null;
  redirectUrl: string | null;
  isPreAuth: boolean | null;
  bffBaseUrl?: string | null;
  isValidAuthParams: boolean;
  redirectStatus?: string | null;
}

export function CheckoutLoadError(props: CheckoutLoadErrorProps) {
  const style: CSSProperties = {
    textAlign: 'left',
    ...errorTextStyle
  };

  useEffect(() => {
    dispatchCheckoutFormOnloadEvent(CHECKOUT_ELEMENT, {
      loaded: false,
      errorMessage: 'loaded with error'
    });
  }, []);

  return (
    <div style={style} data-testid="checkout-load-error">
      <strong>Error loading Checkout: missing properties.</strong>
      <br />
      Required properties:
      <br />
      <br />
      <li>
        One of:
        <ul>
          <li>BFF URL (if no AD Token and CAT Token Provided)</li>
          <li>CAT Token (if no BFF URL Provided)</li>
        </ul>
      </li>
      <li>Payment Info Token</li>
      <li>Redirect URL</li>
      <li>Language (locale string)</li>
      <br />
      Optional properties:
      <li>FMA Customer Authentication Token</li>
      <li>
        Is Pre-Auth flag (false if not present, true if present with any value)
      </li>
      <li>
        Is Ach-No-Pay Flag present (then No-Credit-Cards flag should also
        present) for ACH enabled Account
      </li>
      <br />
      Received Values:
      <br />
      <ul style={{ textAlign: 'left' }}>
        <li>Azure Active Directory Token: {props.bearerToken}</li>
        <li>Payment Info Token: {props.paymentInfoToken}</li>
        <li>Redirect URL: {props.redirectUrl}</li>
        <li>Customer Access Token: {props.customerAuthToken}</li>
        <li>Customer Access Token Type: {props.customerAuthTokenType}</li>
        <li>IsPreAuth: {props.isPreAuth?.toString()}</li>
        <li>Redirect Status: {props.redirectStatus}</li>
        <li>BFF Base URL: {props.bffBaseUrl}</li>
        <li>Language: {props.language}</li>
        {(props.bearerToken || props.customerAuthToken) && props.bffBaseUrl && (
          <li>
            BFF Base Url and Azure/CAT Tokens were both supplied. Please supply
            one or the other.
          </li>
        )}
      </ul>
    </div>
  );
}
