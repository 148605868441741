import { Style } from '../Styles';
import i18next from '@fpc/common/i18n';
import { resolveTCLink } from '../Links';
import { translationKeys } from '@fpc/common';
import { IDEAL_PAY_METHOD, PAY_BY_BANK_METHOD } from '../../checkout';
import { OptionalTermsContext } from '@fpc/reactutils/optionalTermsContextProvider';
import { useContext } from 'react';
import MarkdownUtils from '@fpc/reactutils/MarkdownParser';

export interface TermsAndConditionsProps {
  translationGroup:
    | typeof translationKeys.mit
    | typeof translationKeys.wallet
    | typeof translationKeys.checkout;
  paymentButtonText?: string | null;
  paymentMethod?: string;
  isFordPayGuestIdCheckout?: boolean;
}

export function TermsAndConditions(props: TermsAndConditionsProps) {
  const shouldUseContinueVerbage =
    props.paymentMethod === PAY_BY_BANK_METHOD ||
    props.paymentMethod === IDEAL_PAY_METHOD;
  const getTosTextBefore = () => {
    let text;
    if (props.isFordPayGuestIdCheckout) {
      text = i18next.t<string>(
        translationKeys.checkout.tosTextBeforeForFordPayGuestId
      );
    } else if (shouldUseContinueVerbage) {
      text = i18next.t<string>(translationKeys.checkout.tosTextBeforeContinue);
    } else {
      text = i18next.t<string>(props.translationGroup.tosTextBefore);
    }
    if (props.translationGroup === translationKeys.wallet) {
      text = i18next.t<string>(props.translationGroup.tosTextBeforePM);
    }
    text += ' ';
    return props.paymentButtonText
      ? i18next.t<string>(text, {
          paymentButtonText: props.paymentButtonText,
          interpolation: {
            escapeValue: false
          }
        })
      : text;
  };
  const tosLinkText = i18next.t<string>(props.translationGroup.tosLinkText);
  const tosTextAfter = i18next.t<string>(props.translationGroup.tosTextAfter);

  const useOptionalTerms: string | null = useContext(OptionalTermsContext);

  const trimmedTerms = useOptionalTerms?.trim();
  const optionalTerms = trimmedTerms?.endsWith('.')
    ? trimmedTerms.substring(0, trimmedTerms.length - 1)
    : useOptionalTerms;

  return (
    <p
      data-testid={'terms-conditions'}
      style={{
        fontSize: '0.875em',
        lineHeight: '1.43em',
        margin: '0',
        paddingLeft: '1.5em',
        paddingRight: '1.5em',
        paddingTop: '.5em',
        color: Style.color.gray3
      }}
      aria-label={getTosTextBefore() + tosLinkText + tosTextAfter}
    >
      {getTosTextBefore()}
      {tosLinkText !== ' ' && (
        <a
          href={resolveTCLink(i18next.language)}
          target="_blank"
          rel="noreferrer"
          style={{ color: Style.color.primary }}
        >
          {tosLinkText}
        </a>
      )}
      <MarkdownUtils optionalTerms={optionalTerms} />
      {tosTextAfter}
    </p>
  );
}
