export enum PaymentMethodType {
  CARD = 'card',
  US_BANK_ACCOUNT = 'us_bank_account',
  PAY_BY_BANK = 'pay_by_bank',
  PROMPTPAY = 'promptpay',
  ACSS_DEBIT = 'acss_debit',
  BACS_DEBIT = 'bacs_debit',
  SEPA_DEBIT = 'sepa_debit',
  IDEAL = 'ideal',
  GOOGLE_PAY = 'google_pay',
  APPLE_PAY = 'apple_pay',
  PAYPAL = 'paypal_account',
  KLARNA = 'klarna',
  GCASH = 'gcash',
  PAYMAYA = 'paymaya'
}

export type PaymentMethod = {
  type: PaymentMethodType;

  /**
   * The displayable portion of the account number for a payment.
   * Examples include: the last 4 digits of a bank account, or card number.
   */
  accountNumberDisplayable: string;
};

export type BankPaymentMethod = {
  bankName: string;
} & PaymentMethod;

export type AcssDebitAccount = BankPaymentMethod;

export type IdealAccount = BankPaymentMethod;

export type BacsDebitAccount = {
  sortCode: string;
} & PaymentMethod;

export type SepaDebitAccount = {
  bankCode: string;
} & PaymentMethod;

export type CardPaymentMethod = {
  cardType: CardType;
  expireMonth?: number;
  expireYear?: number;
} & PaymentMethod;

export type PayPalPaymentMethod = PaymentMethod;

export type Refund = {
  id: string;
  amount: number;
  timestamp: string;
};

export enum CardType {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
  DISCOVER = 'DISCOVER',
  UNIONPAY = 'UNIONPAY',
  MAESTRO = 'MAESTRO'
}
