import { statusReject } from '../statusReject';
import {
  parseTransactionDetails,
  parseTransactionDetailsForMultiMerchant
} from './HandleTransactionDetails';
import {
  StripeTransactionDetails,
  TransactionDetails
} from '@fpc/common/transactionInterfaces';
import { MultiMerchantStripeTransactionDetails } from '@fpc/checkout/features/multi-merchant/types/PaymentInfoToken';
import { traceId } from '@fpc/reactutils/TraceIdContext';
import { observedFetch } from '@fpc/common/monitoring/utils';

export function verifyDigitalSignature(
  payload: string,
  bffBaseUrl?: string | null,
  isPreAuth?: boolean
): Promise<
  | TransactionDetails
  | StripeTransactionDetails
  | MultiMerchantStripeTransactionDetails
> {
  let targetServer;
  let paymentInfoJson = JSON.parse(payload);
  let paymentInfoPayload = JSON.parse(payload).paymentInfo;
  let headers: Headers = new Headers();
  headers.set('Accept', 'application/json, text/plain, */*');
  headers.set('Traceparent', traceId);

  if (bffBaseUrl) {
    targetServer = `${bffBaseUrl}${process.env.PAYMENT_APP_APIGEE_BASE_PATH}/api/v2/digital-signature-verify`;
    paymentInfoPayload = payload;
    headers.set('Content-Type', 'application/json');
  } else {
    targetServer = `${process.env.PAYMENT_DIGITAL_SIGNATURE_SERVER}/api/digital-signature-verify`;
    headers.set('Content-Type', 'text/plain');
    headers.set('Authorization', `${paymentInfoJson.signature}`);
  }
  return observedFetch(`${targetServer}`, {
    method: 'POST',
    headers: headers,
    body: paymentInfoPayload
  })
    .then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          if (data.version) {
            return parseTransactionDetailsForMultiMerchant(data, isPreAuth);
          }
          return parseTransactionDetails(data, isPreAuth);
        });
      } else {
        return Promise.reject(statusReject(res));
      }
    })
    .catch((err) => {
      console.warn('Error verifying Digital Signature: ', err);
      return Promise.reject(err);
    });
}
