import { CustomerTokenType } from '../types/auth';
import { AppDomain } from '../types/app-domain';

type DomainUrls = {
  bffBasePath: string;
  host: string;
};
type DomainUrlRegistry = {
  [domain in AppDomain]: DomainUrls;
};

const APP_DOMAIN_TO_URLS: DomainUrlRegistry = {
  [AppDomain.PAYMENT_APP]: {
    bffBasePath: process.env.PAYMENT_APP_APIGEE_BASE_PATH!, // ex: /bffpayment
    host: process.env.PAYMENT_APP_SERVER_V2! // ex: https://api-qat.fordcredit.com
  },
  [AppDomain.TRANSACTION_APP]: {
    bffBasePath: process.env.TRANSACTION_APP_APIGEE_BASE_PATH!,
    host: process.env.TRANSACTION_APP_SERVER_V2!
  },
  [AppDomain.CUSTOMER_APP]: {
    bffBasePath: process.env.CUSTOMER_APP_APIGEE_BASE_PATH!,
    host: process.env.CUSTOMER_APP_SERVER_V2!
  }
};

function getApiPathPrefix(tokenType: CustomerTokenType): string {
  const TOKEN_TYPE_TO_API_PREFIX = {
    [CustomerTokenType.CAT]: 'api/v2',

    // TODO -- Add an alias to this path in the backend, and update UI to call it
    // [CustomerTokenType.CAT]: 'cts/api/v1',
    [CustomerTokenType.FLEET]: 'fts/api/v1'
  };
  const prefix = TOKEN_TYPE_TO_API_PREFIX[tokenType];

  if (!prefix) {
    throw new Error(`Invalid token type provided: ${tokenType}`);
  }
  return prefix;
}

// TODO -- this logic needs to be consolidated with the new standard URL pattern
//    -- '<fts|cts>/api/<api-version-number>
function getApiPathPrefixForCustomerApp(tokenType: CustomerTokenType) {
  return tokenType === CustomerTokenType.CAT ? 'v2' : 'fts/api/v1';
}

export function getBaseUrl(appDomain: AppDomain, bffBaseUrl?: string | null) {
  const domainUrls = APP_DOMAIN_TO_URLS[appDomain];

  if (!domainUrls) {
    throw new Error(`No URLs are configured for the domain: ${appDomain}`);
  }

  return bffBaseUrl
    ? `${bffBaseUrl}${domainUrls.bffBasePath}`
    : domainUrls.host;
}

export function getApiBaseUrl(
  appDomain: AppDomain,
  tokenType: CustomerTokenType,
  bffBaseUrl?: string | null
) {
  const baseUrl = getBaseUrl(appDomain, bffBaseUrl);
  const pathPrefix =
    appDomain === AppDomain.CUSTOMER_APP
      ? getApiPathPrefixForCustomerApp(tokenType)
      : getApiPathPrefix(tokenType);

  return `${baseUrl}/${pathPrefix}`;
}
