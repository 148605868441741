import { enUS, TRANSLATION_MISSING } from './en-US';

export const svSE: typeof enUS = {
  common: {
    default: 'standard',
    endingIn: 'slutar om',
    close: 'St\u00e4ng',
    makeDefaultCard: 'Anv\u00e4nd som standardkort',
    makeDefaultInfoHover:
      'Det h\u00e4r blir automatiskt ditt standardkort eftersom det \u00e4r det f\u00f6rsta kortet i din Wallet.',
    processing: 'Bearbetar ...',
    saveCardCheck: 'Spara kort f\u00f6r framtida transaktioner',
    saveCardSuccess: 'Kortet har lagts till i Ford.com Wallet.',
    technicalErrorPayment:
      'Vii beklagar! Det uppstod ett tekniskt problem och din betalning genomf\u00f6rdes inte. F\u00f6rs\u00f6k igen.',
    timeoutSorry: 'Vi beklagar ...',
    timeoutError: 'Vi har tekniska problem just nu.',
    timeoutTryAgain: 'F\u00f6rs\u00f6k igen',
    savePaymentMethodCheck:
      'Spara betalningsmetoden f\u00f6r framtida transaktioner',
    makeDefaultPaymentMethod: 'Anv\u00e4nd standardbetalningsmetod',
    savePaymentMethodButton: 'Spara betalningsmetod',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Detta blir automatiskt din standardbetalningsmetod, eftersom det \u00e4r den f\u00f6rsta betalningsmetoden i din pl\u00e5nbok.',
    savePaymentMethodSuccess: 'Betalningsmetod har lagts till i Wallet.'
  },
  checkout: {
    payButton: 'Betala {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Vii beklagar! Det uppstod ett tekniskt problem och din betalning genomf\u00f6rdes inte. Uppdatera sidan och f\u00f6rs\u00f6k igen. ',
    technicalErrorPaymentDetachFail:
      'Vii beklagar! Det uppstod ett tekniskt problem och din betalning genomf\u00f6rdes inte. Ditt kort har nu sparats i din Wallet. F\u00f6rs\u00f6k igen.',
    tosTextBefore:
      'Genom att klicka p\u00e5 Betala-knappen nedan godk\u00e4nner du ',
    tosTextBeforeForFordPayGuestId:
      'Genom att klicka p\u00e5 "Spara kort"-knappen nedan godk\u00e4nner du ',
    tosLinkText: 'betalningsvillkoren och sekretesspolicy',
    tosTextAfter: '.',
    useNewCard: 'Anv\u00e4nd nytt kort',
    useNewCardDropdownText: 'L\u00e4gg till nytt kort',
    useNewCardTextBefore: 'G\u00e5 till kassan med sparat kort eller ',
    useNewCardLinkText: 'anv\u00e4nd ny betalningsmetod',
    useNewCardTextAfter: '.',
    cardSelect: 'Kreditkort valt',
    checkoutFaster: 'G\u00e5 genom kassan snabbare med FordPay.',
    checkout: 'Kassa',
    nameOnCard: 'Namn p\u00e5 kortet',
    fullName: 'Fullst\u00e4ndigt namn',
    cardNumber: 'Kortnummer',
    expirationDate: 'G\u00e4ller t.o.m. Datum',
    securityCode: 'S\u00e4kerhetskod',
    invalidCCNMessage: 'Ditt kortnummer \u00e4r ogiltigt.',
    invalidCVVMessage: 'Din s\u00e4kerhetskod \u00e4r ogiltig.',
    invalidExpDateMessage: 'Ditt utg\u00e5ngsdatum \u00e4r ogiltigt.',
    emptyCCNMessage: 'Ditt kortnummer \u00e4r tomt.',
    emptyCVVMessage: 'Din s\u00e4kerhetskod \u00e4r tom',
    emptyExpDateMessage: 'Ditt utg\u00e5ngsdatum \u00e4r tomt.',
    unsupportedCardMessage:
      'Ditt kortnummer st\u00f6ds ej. Ange VISA. MasterCard eller AMEX.',
    paymentNotProcessed: 'Din betalning kunde inte behandlas',
    redirectStatusFailure:
      'Din betalning kunde inte behandlas. F\u00f6rs\u00f6k igen.\u00a0',
    continue: 'Forts\u00e4tta {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      "Genom att klicka p\u00e5 knappen 'Forts\u00e4tt' nedan godk\u00e4nner du",
    authCheckoutStatement:
      'Checka ut med sparat kort, anv\u00e4nd ny betalningsmetod eller anv\u00e4nd Betala med bank.',
    useNewCardTextBeforePayByBank: 'Checka ut med sparat kort, ',
    usePayByBank: ', eller anv\u00e4nd ',
    payByBankLinkText: 'Betala med bank',
    payByBankHeader: 'Anv\u00e4nd Pay By Bank.',
    use: 'Anv\u00e4nd ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Anv\u00e4nd ny betalningsmetod',
    useNewPaymentDropdownText: 'anv\u00e4nd ny betalningsmetod',
    useNewPaymentTextBefore: 'Checka ut med sparad betalningsmetod eller ',
    useNewPaymentLinkText: 'anv\u00e4nd ny betalningsmetod',
    useNewPaymentTextBeforePayByBank: 'Checka ut med sparad betalningsmetod, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Du har f\u00f6r n\u00e4rvarande inga betalningsmetoder i din pl\u00e5nbok. Kom ig\u00e5ng genom att l\u00e4gga till den f\u00f6rsta betalningsmetoden i din pl\u00e5nbok.',
    addPaymentMethod: 'L\u00e4gg till betalningsperiod',
    makeDefault: 'G\u00f6r till standard',
    changeDefaultPMSuccess: 'Din standard betalningsmetod har \u00e4ndrats.',
    makeDefaultSuccessPM: 'Betalningsmetod tillagd som standard.',
    makeDefaultErrorPM:
      'Fel vid inst\u00e4llning av betalningsmetod som standard.',
    removePMSuccess:
      'Betalningsmetod borttagen fr\u00e5n Ford.com-pl\u00e5nbok',
    removePMError: 'Fel vid borttagning av betalningsmetod.',
    removePM: 'Ta bort betalningsmetod',
    removePMQuestion: 'Ta bort betalningsmetod?',
    removePMText:
      '\u00c4r du s\u00e4ker p\u00e5 att du vill ta bort betalningsmetod {{cardNumber}} fr\u00e5n din pl\u00e5nbok?\u00a0Alla aktiva prenumerationer som \u00e4r kopplade till denna betalningsmetod kommer att misslyckas om betalningsmetoden tas bort.',
    defaultCard: 'Standardkort',
    defaultPM: 'Standard betalningsmetod',
    defaultCardQuestion: 'Standardkort?',
    defaultPMQuestion: 'Standard betalningsmetod?',
    defaultCardText:
      '\u00c4r du s\u00e4ker p\u00e5 att du vill byta ditt standardkort till {{cardNumber}}?',
    defaultPMText:
      '\u00c4r du s\u00e4ker p\u00e5 att du vill \u00e4ndra din standard betalningsmetod till {{cardNumber}}?',
    navigationLabel: 'Kontohanteringsnavigering',
    removeDefaultPMSuccess:
      'Standard betalningsmetod borttagen fr\u00e5n Ford.com-pl\u00e5nbok',
    removeDefaultCardError:
      'Det uppstod ett fel n\u00e4r standardkortet skulle tas bort.',
    removeDefaultPMError: 'Fel vid borttagning av standard betalningsmetod.',
    removeDefaultCard: 'Ta bort ditt standardkort',
    removeDefaultPM: 'Ta bort din standard betalningsmetod?',
    removeDefaultPMText:
      'Betalningsmetod {{cardNumber}} \u00e4r din standard betalningsmetod. V\u00e4lj en ny standard betalningsmetod nedan. Alla aktiva prenumerationer som \u00e4r kopplade till denna betalningsmetod kommer att misslyckas om betalningsmetoden tas bort.',
    removeDefaultPMButton: 'Ta bort betalningsmetod och uppdatera standard',
    removeDefaultSelectPM: 'V\u00e4lj ny standard betalningsmetod\u00a0',
    title: 'Pl\u00e5nbok',
    expiration: 'G\u00e4ller t.o.m.\u00a0{{date}}',
    expires: 'F\u00f6rfaller {{date}}',
    stripeDefaultError:
      'Det uppstod ett tekniskt problem. F\u00f6rs\u00f6k igen.\u00a0',
    paymentMethod: 'Betalningsmetod',
    tosTextBefore:
      'Genom att klicka p\u00e5 "Spara kort"-knappen nedan godk\u00e4nner du ',
    tosTextBeforePM:
      "Genom att klicka p\u00e5 knappen 'Spara betalningsmetod' nedan godk\u00e4nner du",
    tosLinkText: 'betalningsvillkoren och sekretesspolicy',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Alla',
    amount: 'Summa',
    date: 'Datum',
    description: 'Beskrivning',
    displayingAll: 'Visar alla transaktioner.',
    displayingDays:
      'Visar transaktioner fr\u00e5n de senaste {{numberOfDays}} dagarna.',
    displayingYear: 'Visar transaktioner fr\u00e5n {{year}}.',
    filter: 'Senaste {{numberOfDays}} dagarna',
    loadAll: 'Ladda upp alla',
    loadMore: 'Ladda upp {{x}} till',
    noTransactions: 'Inga transaktioner att visa.',
    paymentMethod: 'Betalningsmetod',
    refund: '\u00c5terbetalning',
    searchBar: 'S\u00f6k p\u00e5 beskrivning',
    timeFilter: 'V\u00e4lj tidsram f\u00f6r transaktionshistorik',
    title: 'Transaktioner',
    pending: 'I v\u00e4ntan p\u00e5'
  },
  mit: {
    confirmPurchase: 'Bekr\u00e4fta k\u00f6p',
    paymentSuccessful: 'Betalningen lyckades',
    saveCardAlert:
      'F\u00f6r att anv\u00e4nda detta kort f\u00f6r framtida betalningar m\u00e5ste det sparas i din Ford.com-pl\u00e5nbok.',
    title: 'V\u00e4lj betalningsmetod',
    tosTextBefore:
      'Genom att klicka p\u00e5 "Spara kort"-knappen nedan godk\u00e4nner du ',
    tosLinkText: 'betalningsvillkoren och sekretesspolicy',
    tosTextAfter: '.',
    useNewCard: 'Anv\u00e4nd nytt kort',
    useNewCardDropDownItem: 'Anv\u00e4nd nytt kort',
    useNewCardTextBefore: 'V\u00e4lj sparat kort eller ',
    useNewCardLinkText: 'anv\u00e4nd nytt kort',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Anv\u00e4nd ny betalningsmetod',
    useNewPaymentDropdownText: 'anv\u00e4nd ny betalningsmetod',
    useNewPaymentTextBefore: 'Checka ut med sparad betalningsmetod eller ',
    useNewPaymentLinkText: 'anv\u00e4nd ny betalningsmetod',
    successful: 'Framg\u00e5ngsrik',
    savePaymentMethodAlert:
      'F\u00f6r att anv\u00e4nda denna betalningsmetod f\u00f6r framtida betalningar m\u00e5ste den sparas i din pl\u00e5nbok.'
  }
};
