import {
  CommonCheckoutProps,
  logMissingParamError,
  validateCheckout
} from '@fpc/checkout/CheckoutCommon';
import { TwocTwopTransactionDetails } from '@fpc/common/transactionInterfaces';
import { ReactElement, useEffect, useMemo, useReducer, useState } from 'react';
import {
  ErrorCondition,
  errorReducer,
  initialErrorState
} from '@fpc/common/ErrorHandler';
import {
  CheckoutTokens,
  TwocTwopCheckout,
  TwocTwopCheckoutContext
} from '@fpc/reactutils/checkoutContextProvider';
import { TwocTwop } from '@fpc/checkout/twoctwop/TwocTwop';
import { Psp } from '@fpc/common/Psp';
import { isNotProd } from '../../flags';
import { CheckoutLoadError } from '@fpc/checkout/CheckoutLoadError';
import { TechnicalError } from '@fpc/common';
import { CheckoutLoadWrapper } from '@fpc/checkout/CheckoutLoadWrapper';
export interface TwocTwopCheckoutProps extends CommonCheckoutProps {
  transactionDetails: TwocTwopTransactionDetails;
}
function TwocTwopCheckoutContextProvider({
  transaction
}: {
  transaction: TwocTwopCheckout;
}) {
  const [paymentProcessing, setPaymentProcessing] = useState('');

  const updatePaymentProcessing = (currentProcessingPayment: string) => {
    setPaymentProcessing(currentProcessingPayment);
  };

  const contextValue = useMemo(
    () => ({
      paymentProcessing: paymentProcessing,
      updatePaymentProcessing: updatePaymentProcessing,
      transaction: transaction.transaction,
      tokens: transaction.tokens,
      redirectUrl: transaction.redirectUrl,
      errorDispatch: () => {}
    }),
    [
      paymentProcessing,
      transaction.transaction,
      transaction.tokens,
      transaction.redirectUrl
    ]
  );

  return (
    <TwocTwopCheckoutContext.Provider value={contextValue}>
      <TwocTwop />
    </TwocTwopCheckoutContext.Provider>
  );
}

export function TwocTwopCheckoutWrapper(props: TwocTwopCheckoutProps) {
  const transactionDetails = props.transactionDetails;
  const redirectUrl = props.redirectUrl;
  const [error, errorDispatch] = useReducer(errorReducer, initialErrorState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isValidCheckout } = validateCheckout(props);

  const checkoutTokens: CheckoutTokens = {
    paymentInfoToken: props.paymentInfoToken,
    paymentObjectType: props.paymentObjectType,
    customerAuthTokenType: props.customerAuthTokenType,
    bearerToken: props.bearerToken,
    customerAuthToken: props.customerAuthToken,
    digitalSignature: props.digitalSignature
  };

  const transaction: TwocTwopCheckout = useMemo(
    () => ({
      transaction: transactionDetails,
      tokens: checkoutTokens,
      redirectUrl: redirectUrl,
      errorDispatch: errorDispatch,
      paymentProcessing: '',
      updatePaymentProcessing: (paymentClickedOn: string) => {}
    }),
    [props, transactionDetails, redirectUrl]
  );

  useEffect(() => {
    try {
      errorDispatch(ErrorCondition.Reset);
    } finally {
      setIsLoading(false);
    }
  }, [props]);

  function resetFromError() {
    errorDispatch(ErrorCondition.Reset);
    setIsLoading(true);
  }

  function checkoutErrorHandler() {
    logMissingParamError(
      props.paymentInfoToken,
      props.redirectUrl,
      props.bearerToken!,
      Psp.TwocTwop
    );
    if (isNotProd()) {
      return (
        <CheckoutLoadError
          redirectUrl={props.redirectUrl}
          bearerToken={null}
          paymentInfoToken={props.paymentInfoToken}
          customerAuthToken={null}
          customerAuthTokenType={props.customerAuthTokenType}
          isPreAuth={false}
          bffBaseUrl={props.bffBaseUrl}
          isValidAuthParams={true}
          language={props.language}
        />
      );
    } else {
      return (
        <TechnicalError resetFromError={resetFromError} unrecoverable={true} />
      );
    }
  }

  function getCheckout(): ReactElement {
    if (isValidCheckout) {
      return <TwocTwopCheckoutContextProvider transaction={transaction} />;
    } else {
      return checkoutErrorHandler();
    }
  }
  return (
    <CheckoutLoadWrapper
      error={error}
      resetFromError={resetFromError}
      loading={isLoading}
      checkoutComponent={getCheckout()}
    />
  );
}
