import { enUS, TRANSLATION_MISSING } from './en-US';

export const daDK: typeof enUS = {
  common: {
    default: 'standard',
    endingIn: 'der slutter p\u00e5',
    close: 'Luk',
    makeDefaultCard: 'Angiv som standardkort',
    makeDefaultInfoHover:
      'Dette bliver automatisk dit standardkort, fordi det er det f\u00f8rste kort i din wallet.',
    processing: 'Behandler ...',
    saveCardCheck: 'Gem kort til fremtidige betalinger',
    saveCardSuccess: 'Kort blev tilf\u00f8jet til Ford.com Wallet.',
    technicalErrorPayment:
      'Beklager! Der opstod et teknisk problem, og din betaling blev ikke behandlet. Pr\u00f8v igen.',
    timeoutSorry: 'Vi beklager ...',
    timeoutError: 'Vi oplever tekniske problemer i \u00f8jeblikket.',
    timeoutTryAgain: 'Pr\u00f8v igen',
    savePaymentMethodCheck: 'Gem betalingsmetode til fremtidige betalinger',
    makeDefaultPaymentMethod: 'Foretag standardbetalingsmetode',
    savePaymentMethodButton: 'Gem betalingsmetode',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Dette bliver automatisk din standardbetalingsmetode, fordi det er den f\u00f8rste betalingsmetode i din pung.',
    savePaymentMethodSuccess:
      'Betalingsmetode er blevet tilf\u00f8jet til Wallet.'
  },
  checkout: {
    payButton: 'Betal {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Beklager! Der opstod et teknisk problem, og din betaling blev ikke behandlet. Genindl\u00e6s siden, og pr\u00f8v igen. ',
    technicalErrorPaymentDetachFail:
      'Beklager! Der opstod et teknisk problem, og din betaling blev ikke behandlet. Dit kort blev gemt i din wallet. Pr\u00f8v igen.',
    tosTextBefore:
      'Ved at klikke p\u00e5 "Betal" nedenfor, accepterer du vores ',
    tosTextBeforeForFordPayGuestId:
      'Ved at klikke p\u00e5 knappen {{paymentButtonText}} nedenfor accepterer du ',
    tosLinkText: 'betalingsvilk\u00e5r og -betingelser og privatlivspolitik',
    tosTextAfter: '.',
    useNewCard: 'Anvend nyt kort',
    useNewCardDropdownText: 'Tilf\u00f8j nyt kort',
    useNewCardTextBefore: 'G\u00e5 til kassen med gemt kort, eller ',
    useNewCardLinkText: 'brug ny betalingsmetode',
    useNewCardTextAfter: '.',
    cardSelect: 'V\u00e6lg kreditkort',
    checkoutFaster: 'Betal hurtigere med FordPay.',
    checkout: 'G\u00e5 til kassen',
    nameOnCard: 'Navn p\u00e5 kortet',
    fullName: 'Fulde navn',
    cardNumber: 'Kortnummer',
    expirationDate: 'Udl\u00f8ber Dato',
    securityCode: 'Sikkerhedskode',
    invalidCCNMessage: 'Dit kortnummer er ikke gyldigt.',
    invalidCVVMessage: 'Din sikkerhedskode er ikke gyldig.',
    invalidExpDateMessage: 'Din udl\u00f8bsdato er ikke gyldig.',
    emptyCCNMessage: 'Dit kortnummer er tomt.',
    emptyCVVMessage: 'Din sikkerhedskode er tom.',
    emptyExpDateMessage: 'Din udl\u00f8bsdato er tom.',
    unsupportedCardMessage:
      'Dit kortnummer er ikke underst\u00f8ttet. Indtast venligst VISA, MasterCard eller AMEX.',
    paymentNotProcessed: 'Din betaling kunne ikke behandles',
    redirectStatusFailure: 'Din betaling kunne ikke behandles. Pr\u00f8v igen.',
    continue: 'Blive ved {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Ved at klikke p\u00e5 "Blive ved" nedenfor, accepterer du vores ',
    authCheckoutStatement:
      'Tjek ud med gemt kort, brug ny betalingsmetode, eller brug Betal med bank.',
    useNewCardTextBeforePayByBank: 'Tjek ud med gemt kort, ',
    usePayByBank: ', eller brug ',
    payByBankLinkText: 'Betal med bank',
    payByBankHeader: 'Brug Betal med bank',
    use: 'Brug ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Brug ny betalingsmetode',
    useNewPaymentDropdownText: 'brug ny betalingsmetode',
    useNewPaymentTextBefore: 'Tjek ud med gemt betalingsmetode eller ',
    useNewPaymentLinkText: 'brug ny betalingsmetode',
    useNewPaymentTextBeforePayByBank: 'Tjek ud med gemt betalingsmetode, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Du har i \u00f8jeblikket ingen betalingsmetoder i din tegnebog. Kom i gang ved at tilf\u00f8je den f\u00f8rste betalingsmetode til din tegnebog.',
    addPaymentMethod: 'Tilf\u00f8j betalingsmetode',
    makeDefault: 'Angiv som standard',
    changeDefaultPMSuccess:
      'Din standard betalingsmetode er blevet \u00e6ndret.',
    makeDefaultSuccessPM: 'Betalingsmetode tilf\u00f8jet som standard.',
    makeDefaultErrorPM: 'Fejl ved indstilling af betalingsmetode som standard',
    removePMSuccess: 'Betalingsmetode fjernet fra Ford.com Wallet',
    removePMError: 'Fejl ved fjernelse af betalingsmetode.',
    removePM: 'Fjern betalingsmetode',
    removePMQuestion: 'Fjern betalingsmetode?\u00a0',
    removePMText:
      'Er du sikker p\u00e5, at du vil fjerne betalingsmetoden {{cardNumber}} fra din Wallet? Alle aktive abonnementer forbundet med denne betalingsmetode vil mislykkes, hvis betalingsmetoden fjernes.',
    defaultCard: 'Standardkort',
    defaultPM: 'Standard betalingsmetode',
    defaultCardQuestion: 'Standardkort?',
    defaultPMQuestion: 'Standard betalingsmetode?',
    defaultCardText:
      'Er du sikker p\u00e5, at du vil \u00e6ndre dit standardkort til {{cardNumber}}?',
    defaultPMText:
      'Er du sikker p\u00e5, at du vil \u00e6ndre din standard betalingsmetode til {{cardNumber}}?',
    navigationLabel: 'Kontoadministrationsnavigation',
    removeDefaultPMSuccess:
      'Standard betalingsmetode fjernet fra Ford.com Wallet',
    removeDefaultCardError: 'Der opstod en fejl ved fjernelse af standardkort.',
    removeDefaultPMError: 'Fejl ved fjernelse af standard betalingsmetode.',
    removeDefaultCard: 'Fjern dit standardkort',
    removeDefaultPM: 'Fjern din standard betalingsmetode?\u00a0',
    removeDefaultPMText:
      'Betalingsmetode {{cardNumber}} er din standard betalingsmetode. V\u00e6lg venligst en ny standard betalingsmetode nedenfor. Alle aktive abonnementer forbundet med denne betalingsmetode vil mislykkes, hvis betalingsmetoden fjernes.',
    removeDefaultPMButton: 'Fjern betalingsmetode og opdater standard',
    removeDefaultSelectPM: 'V\u00e6lg ny standard betalingsmetode',
    title: 'Wallet',
    expiration: 'Udl\u00f8ber\u00a0{{date}}',
    expires: 'Udl\u00f8ber den {{date}}',
    stripeDefaultError:
      'Der opstod et teknisk problem. Pr\u00f8v igen.\u00a0\u00a0',
    paymentMethod: 'Betalingsmetode',
    tosTextBefore: 'Ved at klikke p\u00e5 "Gem kort" nedenfor accepterer du ',
    tosTextBeforePM:
      "Ved at klikke p\u00e5 knappen 'Gem betalingsmetode' nedenfor accepterer du",
    tosLinkText: 'betalingsvilk\u00e5r og -betingelser og privatlivspolitik',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Alle',
    amount: 'Bel\u00f8b',
    date: 'Dato',
    description: 'Beskrivelse',
    displayingAll: 'Viser alle transaktioner.',
    displayingDays: 'Viser transaktioner fra de sidste {{numberOfDays}} dage.',
    displayingYear: 'Viser transaktioner fra {{year}}.',
    filter: 'Seneste {{numberOfDays}} dage',
    loadAll: 'Indl\u00e6s alle',
    loadMore: 'Indl\u00e6s {{x}} mere',
    noTransactions: 'Der er ingen transaktioner at vise.',
    paymentMethod: 'Betalingsmetode',
    refund: 'Refusion',
    searchBar: 'S\u00f8g efter beskrivelse',
    timeFilter: 'V\u00e6lg periode for transaktionshistorik',
    title: 'Transaktioner',
    pending: 'Verserende'
  },
  mit: {
    confirmPurchase: 'Bekr\u00e6ft k\u00f8b',
    paymentSuccessful: 'Betalingen er gennemf\u00f8rt',
    saveCardAlert:
      'Hvis du vil bruge dette kort til fremtidige betalinger, skal det gemmes i din Ford.com Wallet.',
    title: 'Valg af betalingsmetode',
    tosTextBefore:
      'Ved at klikke p\u00e5 knappen {{paymentButtonText}} nedenfor accepterer du ',
    tosLinkText: 'betalingsvilk\u00e5r og -betingelser og privatlivspolitik',
    tosTextAfter: '.',
    useNewCard: 'Anvend nyt kort',
    useNewCardDropDownItem: 'Anvend nyt kort',
    useNewCardTextBefore: 'V\u00e6lg gemt kort eller ',
    useNewCardLinkText: 'anvend nyt kort',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Brug ny betalingsmetode ',
    useNewPaymentDropdownText: 'brug ny betalingsmetode',
    useNewPaymentTextBefore: 'Tjek ud med gemt betalingsmetode eller ',
    useNewPaymentLinkText: 'brug ny betalingsmetode',
    successful: 'Vellykket',
    savePaymentMethodAlert:
      'For at bruge denne betalingsmetode til fremtidige betalinger, skal den gemmes i din tegnebog.'
  }
};
