import { PaymentMethodType } from '../../types/payment';

export interface AlternatePaymentMethodConfig {
  type: PaymentMethodType;
  backgroundColor: string;
  width?: string;
  height?: string;
}

export const PAYMENT_METHODS: Record<string, AlternatePaymentMethodConfig> = {
  [PaymentMethodType.GCASH]: {
    type: PaymentMethodType.GCASH,
    backgroundColor: '#CCE7FF'
  },
  [PaymentMethodType.PAYMAYA]: {
    type: PaymentMethodType.PAYMAYA,
    backgroundColor: '#C5E0B3'
  }
};
