import {
  PaymentMethodAchAcssDebit,
  PaymentMethodCard,
  PaymentMethodInfo,
  PaymentMethodResponse,
  PaymentMethodUsBank
} from '../paymentapp';
import { SEPA_PAY_METHOD } from 'checkout';
import { getApiBaseUrl } from '../../helpers/http';
import { AppDomain } from '../../types/app-domain';
import { useAuthContextStore } from '../../stores/auth-context.store';
import httpClient, { getHeadersByAuthTokens } from '@fpc/common/HttpClient';
import { HttpHeader, HttpHeaders } from '../../types/http';
import { Psp } from '@fpc/common/Psp';

export async function getPaymentMethods(
  merchantAccountId?: string | null,
  psp: Psp = Psp.Stripe
): Promise<{
  paymentMethods: PaymentMethodInfo[];
  pspCustomerId: string;
  fordCustomerId: string;
}> {
  const { customerAuthTokenType, bffBaseUrl } = useAuthContextStore.getState();

  const baseUrl = getApiBaseUrl(
    AppDomain.CUSTOMER_APP,
    customerAuthTokenType,
    bffBaseUrl
  );

  let requestUrl = `${baseUrl}/customers/payment-methods`;

  if (merchantAccountId) {
    requestUrl = `${requestUrl}?merchantAccountId=${merchantAccountId}`;
  }

  const headers: HttpHeaders = getHeadersByAuthTokens();
  headers[HttpHeader.PSP] = psp;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res = await httpClient.get<any>(requestUrl, headers);

  const paymentMethods: PaymentMethodInfo[] = res.paymentMethods
    .filter((pm: PaymentMethodResponse) => pm.type !== SEPA_PAY_METHOD)
    .map((paymentMethod: PaymentMethodResponse) => {
      return {
        isDefault: paymentMethod.isDefault,
        type: paymentMethod.type,
        paymentMethodId: paymentMethod.paymentMethodId,
        paymentMethod: getPaymentMethod(paymentMethod),
        merchantAccountId: paymentMethod.merchantAccountId
      };
    });

  return {
    paymentMethods,
    pspCustomerId: res.pspCustomerId,
    fordCustomerId: res.fordCustomerId
  };
}

/* To get right payment method */
export function getPaymentMethod(
  paymentMethod: PaymentMethodResponse
):
  | PaymentMethodCard
  | PaymentMethodAchAcssDebit
  | PaymentMethodUsBank
  | undefined {
  if (paymentMethod.type === 'us_bank_account') {
    return paymentMethod.usBankAccount;
  } else if (paymentMethod.type === 'acss_debit') {
    return paymentMethod.acssDebit;
  } else {
    return paymentMethod.card;
  }
}
