import { FormEvent, useContext, useEffect, useState } from 'react';
import {
  Button,
  buttonContinue,
  buttonNext,
  buttonPayNow,
  ChevronIcon,
  FAILED_STATUS,
  LoadingSpinner,
  paymentButtonProcessing,
  REQUIRES_ACTION_STATUS,
  StripeElementErrorDisplay,
  SUCCESS_STATUS,
  translationKeys
} from '@fpc/common';
import {
  cardElementStyle,
  loadingSpinnerBig,
  STRIPE_APPEARANCE
} from '@fpc/common/Styles';
import {
  CHECKOUT_ELEMENT,
  StripeCheckoutContext
} from '@fpc/reactutils/checkoutContextProvider';
import i18next from '@fpc/common/i18n';
import { TermsAndConditions } from '@fpc/common/components/TermsAndConditions';
import {
  Stripe,
  StripeElements,
  StripeElementsOptionsMode,
  StripeError
} from '@stripe/stripe-js';
import {
  ACCOUNT_ID_PARAM,
  ACSS_DEBIT_METHOD,
  BACS_DIRECT_DEBIT,
  CARD_PAYMENT_METHOD,
  IDEAL_PAY_METHOD,
  KLARNA,
  PAY_BY_BANK_METHOD,
  PAYMENT_TYPE_PARAM,
  PROMPT_PAY_METHOD,
  SEPA_PAY_METHOD
} from '../index';
import { GuestPaymentRequestButton } from './GuestPaymentRequestButton';

import { isLocal } from '../../flags';
import useStripePaymentElements from '@fpc/api/stripe/UseStripePaymentElements';
import { initializeStripe } from '@fpc/api/stripe/Stripe';
import { PayByAcssDebit } from './PayByAcssDebit';
import { handleHttpError } from '@fpc/utils/errorHandling';
import {
  PaymentSuccessResponse,
  processPayment,
  StripeSpecificInfo
} from '@fpc/api/paymentapp/ProcessPayment';
import { buildManualRedirectUrl } from '@fpc/utils/buildManualRedirectUrl';
import {
  callHandleNextAction,
  callHandleNextActionForSetupIntent
} from '@fpc/api/stripe/HandleNextAction';
import { getFirstUrlQueryDelimiter } from '@fpc/utils/urlQueryDelimiter';
import { PayByAcssDebitLink } from './PayByAcssDebitLink';
import {
  dispatchCheckoutFormOnloadEvent,
  dispatchLoadingEventForAchGuestPay,
  dispatchPaymentStatusEvent,
  DispatchPaymentStatusEventRequest,
  dispatchSetupIntentEventForAchGuestPay
} from '@fpc/utils/dispatchEvent';
import {
  disableTermsAndCondition,
  filterPaymentMethodTypes,
  hasCardElement,
  isAchEnabled,
  isAcssDebitPresent,
  isGuestpay,
  isVariableDebitEnabled,
  shouldShowAcssDebitFirst
} from '@fpc/utils/paymentMethods';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';
import {
  setupIntent,
  SetupIntentResponse
} from '@fpc/api/paymentapp/SetupIntent';
import { observedStripe } from '@fpc/common/monitoring/utils';
import { UCLButton } from '@fpc/common/components/UCLButton';
import { IHttpError } from '../../types/http';
import { MIT_ELEMENT_NAME } from '@fpc/reactutils/mitContextProvider';

interface PaymentProps {
  stripe: Stripe;
  isPreAuth: boolean;
  paymentIntentClientSecret: string;
  bearerToken?: string;
}

export interface PaymentMethodParams {
  id: string;
  type: string;
}

export interface ConfirmationMethodParams {
  id: string;
  type: string;
}

export const GuestPayment = (props: PaymentProps) => {
  const [isPayByAcssDebit, setPayByAcssDebit] = useState<boolean>(false);
  const [
    connectPaymentIntentClientSecret,
    setConnectPaymentIntentClientSecret
  ] = useState<string | null>(null);
  const [stripeConnect, setStripeConnect] = useState<Stripe | null>(null);
  const {
    transaction,
    redirectUrl,
    errorDispatch,
    redirectStatus,
    paymentMethodDisplayOrder,
    blockedCardBrands,
    tokens,
    noCreditCards,
    bffBaseUrl,
    achNoPay,
    fordPayGuestId,
    paymentButtonText,
    isFromMitComponent
  } = useContext(StripeCheckoutContext);
  const [message, setMessage] = useState('');
  let isGuestPay = isGuestpay(transaction, achNoPay);
  let shouldDisableTermsAndConditions = disableTermsAndCondition(
    transaction,
    isGuestPay
  );
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [nextButton, setNextButton] = useState(true);
  const [paymentType, setPaymentType] = useState('');
  const options = getPaymentOptions() as unknown as StripeElementsOptionsMode;
  const paymentMethodTypes = options.paymentMethodTypes
    ? options.paymentMethodTypes.join(',')
    : '';
  const [elements] = useState<StripeElements>(props.stripe.elements(options));
  const {
    mountPaymentElements,
    isPaymentMounted,
    isFormComplete,
    isFormRendered
  } = useStripePaymentElements(
    props.stripe,
    options,
    elements,
    errorDispatch,
    paymentMethodDisplayOrder ? paymentMethodDisplayOrder : paymentMethodTypes
  );
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodParams>({
    id: '',
    type: ''
  });

  const [confirmationMethod, setConfirmationMethod] =
    useState<ConfirmationMethodParams>({
      id: '',
      type: ''
    });

  const isAcssDebit = transaction.paymentMethodTypesExcluded
    ? isAcssDebitPresent(transaction.paymentMethodTypesExcluded)
    : false;
  const isAcssDisplayedFirst =
    isAcssDebit && shouldShowAcssDebitFirst(transaction.paymentMethodTypes);
  const canMountCardElementForAcssDebit = hasCardElement(
    transaction.paymentMethodTypes
  );
  const [shouldShowCardElement, setShouldShowCardElement] = useState(true);

  const getActiveTransaction = () => transaction as StripeTransactionDetails;

  function getPaymentOptions() {
    const baseOptions = {
      currency: transaction.currency,
      appearance: STRIPE_APPEARANCE,
      payment_method_options: {
        us_bank_account: { verification_method: 'instant_or_skip' }
      },
      payment_method_types: filterPaymentMethodsTypes().map((p) =>
        p.toLowerCase()
      )
    };

    if (isGuestPay || !!fordPayGuestId) {
      return {
        ...baseOptions,
        mode: 'setup',
        capture_method: 'automatic',
        setup_future_usage: 'off_session'
      };
    } else {
      return {
        ...baseOptions,
        mode: 'payment',
        amount: transaction.amount,
        disallowedCardBrands: blockedCardBrands?.map((card) =>
          card.toLowerCase()
        )
      };
    }
  }

  useEffect(() => {
    if (!isPayByAcssDebit) {
      if (shouldShowCardElement) {
        mountPaymentElements('#payment-element', (event) => {
          setPaymentType(event.value.type);
          if (isAcssDebit && !canMountCardElementForAcssDebit) {
            setShouldShowCardElement(false);
            setPayByAcssDebit(true);
          }
        });
      }
    } else {
      setStripeConnect(null);
      Promise.all([
        initializeStripe(getActiveTransaction().merchantAccountId),
        processPayment(
          tokens,
          null,
          ACSS_DEBIT_METHOD,
          false,
          bffBaseUrl,
          tokens.digitalSignature,
          buildRedirectUrl(redirectUrl, paymentMethod.type)
        )
      ]).then((results) => {
        setStripeConnect(results[0]);
        const stripeSpecificInfo = results[1]
          ?.pspSpecificInfo as StripeSpecificInfo;
        setConnectPaymentIntentClientSecret(
          stripeSpecificInfo.paymentIntentClientSecret
        );
      });
    }
  }, [isPayByAcssDebit]);

  useEffect(() => {
    mountPaymentElements('#payment-element', (event) => {
      setPaymentType(event.value.type);
      if (isAcssDebit && !canMountCardElementForAcssDebit) {
        setShouldShowCardElement(false);
        setPayByAcssDebit(true);
      }
    });
  }, []);

  useEffect(() => {
    if (redirectStatus === 'failed') {
      setMessage(
        i18next.t<string>(translationKeys.checkout.redirectStatusFailure)
      );
    }
  }, []);

  useEffect(() => {
    // When Stripe gives a payment method ID, we proceed to call process payment API
    if (paymentMethod.id || confirmationMethod.id) {
      processPayment(
        tokens,
        paymentMethod.id,
        paymentMethod.type || confirmationMethod.type,
        props.isPreAuth,
        bffBaseUrl,
        tokens.digitalSignature,
        buildRedirectUrl(
          redirectUrl,
          paymentMethod.type || confirmationMethod.type
        ),
        confirmationMethod.id
      )
        .then(async (paymentSuccessResponse: PaymentSuccessResponse) => {
          const stripeSpecificInfo =
            paymentSuccessResponse?.pspSpecificInfo as StripeSpecificInfo;

          if (paymentSuccessResponse.paymentStatus === REQUIRES_ACTION_STATUS) {
            await callHandleNextAction(
              {
                paymentIntentClientSecret:
                  stripeSpecificInfo.paymentIntentClientSecret,
                paymentIntentId: paymentSuccessResponse.paymentId,
                status: paymentSuccessResponse.paymentStatus,
                merchantAccountId: getActiveTransaction().merchantAccountId
              },
              (lastPaymentError, isActionRequiredForPromptPay) => {
                if (paymentMethod.type === PROMPT_PAY_METHOD) {
                  handleRedirection(
                    isActionRequiredForPromptPay,
                    lastPaymentError,
                    stripeSpecificInfo
                  );
                } else {
                  window.location.href = buildManualRedirectUrl(
                    redirectUrl,
                    getActiveTransaction().merchantAccountId,
                    stripeSpecificInfo?.paymentIntentClientSecret,
                    paymentType,
                    true
                  );
                }
              },
              (error) => {
                handleError({
                  declineCode: error.decline_code,
                  unrecoverable: false
                } as IHttpError);
              }
            );
          } else {
            window.location.href = buildManualRedirectUrl(
              redirectUrl,
              getActiveTransaction().merchantAccountId,
              stripeSpecificInfo?.paymentIntentClientSecret,
              paymentType,
              true,
              SUCCESS_STATUS
            );
          }
        })
        .catch(async (error) => {
          handleError(error);
          return error;
        });
    }
  }, [paymentMethod.id || confirmationMethod.id]);

  useEffect(() => {
    if (isPaymentMounted) {
      if (isFormRendered) {
        dispatchCheckoutFormOnloadEvent(CHECKOUT_ELEMENT, {
          loaded: true,
          errorMessage: ''
        });
      } else if (isFormRendered === false) {
        dispatchCheckoutFormOnloadEvent(CHECKOUT_ELEMENT, {
          loaded: false,
          errorMessage: 'loaded with error'
        });
      }
    }
  }, [isPaymentMounted, isFormRendered]);

  const createPaymentMethod = () => {
    return observedStripe(
      () => props.stripe.createPaymentMethod({ elements: elements }),
      'Create Guest Payment Method'
    );
  };

  const createConfirmationToken = () => {
    return props.stripe.createConfirmationToken({ elements: elements });
  };

  const handleCheckoutForAchGuestPay = async () => {
    setNextButton(false);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setMessage(
        submitError.message ??
          i18next.t(translationKeys.common.technicalErrorPayment)
      );
      setNextButton(true);
      return;
    }
    dispatchLoadingEventForAchGuestPay(CHECKOUT_ELEMENT, true);
    try {
      const { confirmationToken, error } = await createConfirmationToken();
      if (confirmationToken) {
        const setupIntentPromise = setupIntent(
          confirmationToken.id,
          tokens.paymentInfoToken,
          tokens.digitalSignature
        )
          .then((result) => {
            dispatchSetupIntentEventForAchGuestPay(CHECKOUT_ELEMENT, result.id);
          })
          .catch((error) => {
            dispatchSetupIntentEventForAchGuestPay(
              CHECKOUT_ELEMENT,
              undefined,
              error
            );
          });
        await setupIntentPromise;
      } else {
        setNextButton(true);
        setMessage(
          error?.message ??
            i18next.t(translationKeys.common.technicalErrorPayment)
        );
      }
    } catch (error: any) {
      setMessage(
        error?.message ??
          i18next.t(translationKeys.common.technicalErrorPayment)
      );
    } finally {
      setNextButton(true);
      dispatchLoadingEventForAchGuestPay(CHECKOUT_ELEMENT, false);
    }
  };

  const handleCheckoutForGuestMit = async () => {
    setPaymentProcessing(true);
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setMessage(
        submitError.message ??
          i18next.t(translationKeys.common.technicalErrorPayment)
      );
      setPaymentProcessing(false);
      return;
    }
    const { confirmationToken, error } = await createConfirmationToken();
    if (confirmationToken) {
      await handleSetupIntent(confirmationToken.id);
    } else {
      handleError({
        declineCode: error.decline_code,
        unrecoverable: false
      } as IHttpError);
    }
  };

  function handleRedirection(
    isActionRequiredForPromptPay: undefined | boolean,
    lastPaymentError: boolean,
    stripeSpecificInfo: StripeSpecificInfo
  ) {
    if (isActionRequiredForPromptPay) {
      setPaymentProcessing(false);
      return;
    }
    let redirectStatus = lastPaymentError ? FAILED_STATUS : SUCCESS_STATUS;
    window.location.href = buildManualRedirectUrl(
      redirectUrl,
      getActiveTransaction().merchantAccountId,
      stripeSpecificInfo?.paymentIntentClientSecret,
      paymentType,
      true,
      redirectStatus
    );
  }

  function filterPaymentMethodsTypes() {
    let paymentMethodTypes =
      transaction.paymentMethodTypes.length > 0
        ? transaction.paymentMethodTypes
        : [CARD_PAYMENT_METHOD];
    if (noCreditCards && isAchEnabled(transaction)) {
      paymentMethodTypes = filterPaymentMethodTypes(
        transaction.paymentMethodTypes,
        CARD_PAYMENT_METHOD,
        transaction.paymentMethodTypesExcluded
      );
    }
    if (props.isPreAuth) {
      paymentMethodTypes = [CARD_PAYMENT_METHOD];
    } else if (isVariableDebitEnabled(transaction) && fordPayGuestId === null) {
      paymentMethodTypes = paymentMethodTypes.filter(
        (it) =>
          it.toLowerCase() !== BACS_DIRECT_DEBIT.toLowerCase() &&
          it.toLowerCase() !== SEPA_PAY_METHOD.toLowerCase()
      );
    }
    if (!!fordPayGuestId) {
      paymentMethodTypes = paymentMethodTypes.filter(
        (it) =>
          it.toLowerCase() !== PAY_BY_BANK_METHOD.toLowerCase() &&
          it.toLowerCase() !== IDEAL_PAY_METHOD.toLowerCase()
      );
    }
    if (transaction.paymentMethodTypesExcluded?.length) {
      paymentMethodTypes = paymentMethodTypes.filter(
        (paymentMethod) =>
          !transaction.paymentMethodTypesExcluded?.includes(paymentMethod)
      );
    }
    return paymentMethodTypes;
  }

  const handleCheckout = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMessage('');

    if (isGuestPay) {
      handleCheckoutForAchGuestPay();
    } else if (!!fordPayGuestId) {
      await handleCheckoutForGuestMit();
    } else {
      setPaymentProcessing(true);
      const { paymentMethod, error } = await createPaymentMethod();

      if (paymentMethod) {
        setPaymentMethod({
          id: paymentMethod.id,
          type: paymentMethod.type
        });
      } else {
        handleError({
          declineCode: error.decline_code,
          unrecoverable: false
        } as IHttpError);
      }
    }
  };

  async function handleSetupIntent(confirmationTokenId: string) {
    setMessage('');
    let setupResponse: SetupIntentResponse | null | undefined;
    let setupError: StripeError | null | undefined;
    setupIntent(
      confirmationTokenId,
      tokens.paymentInfoToken,
      tokens.digitalSignature,
      options.payment_method_types,
      fordPayGuestId!!
    )
      .then(async (response) => {
        if (response.status === REQUIRES_ACTION_STATUS) {
          const setupPromise = await callHandleNextActionForSetupIntent(
            transaction.merchantAccountId!!,
            response.setupIntentClientSecret!!
          );
          setupResponse = !!setupPromise.setupIntentResponse
            ? response
            : setupResponse;
          setupError = setupPromise.setupError;
        } else {
          setupResponse = response;
        }
        if (!!setupResponse) {
          const request: DispatchPaymentStatusEventRequest = {
            eventTarget: isFromMitComponent
              ? MIT_ELEMENT_NAME
              : CHECKOUT_ELEMENT,
            status: 'SUCCESS',
            paymentIntentId: null,
            setupIntentId: setupResponse.id,
            paymentMethodId: setupResponse.paymentMethodId!!,
            paymentMethodDetails: setupResponse.paymentMethodDetails!!
          };
          dispatchPaymentStatusEvent(request);
        } else {
          setMessage(
            setupError?.message ??
              i18next.t(translationKeys.common.technicalErrorPayment)
          );
          setPaymentProcessing(false);
        }
      })
      .catch((error) => {
        handleError(error);
        const request: DispatchPaymentStatusEventRequest = {
          eventTarget: isFromMitComponent ? MIT_ELEMENT_NAME : CHECKOUT_ELEMENT,
          status: 'INCOMPLETE',
          paymentIntentId: null,
          setupIntentId: null,
          paymentMethodId: null,
          paymentMethodDetails: null,
          message: error.description,
          declineCode: error.declineCode,
          code: error.errorCode
        };
        dispatchPaymentStatusEvent(request);
      });
  }

  function buildRedirectUrl(
    redirectUrl: string,
    paymentMethodType: string | null
  ) {
    if (
      paymentMethodType === PAY_BY_BANK_METHOD ||
      paymentMethodType === IDEAL_PAY_METHOD ||
      paymentMethodType === KLARNA
    ) {
      const queryDelimiter = getFirstUrlQueryDelimiter(redirectUrl);
      const url = new URL(redirectUrl, encodeURI(window.location.origin));
      return (
        url.toString() +
        `${queryDelimiter}${ACCOUNT_ID_PARAM}=${
          getActiveTransaction().merchantAccountId
        }` +
        `&${PAYMENT_TYPE_PARAM}=${paymentMethodType}`
      );
    }
    return null;
  }

  function handleError(error: IHttpError) {
    let errorMessage;

    errorMessage = handleHttpError(
      error,
      translationKeys.checkout.technicalErrorPaymentRefresh,
      errorDispatch
    );

    if (isLocal) {
      console.warn(error);
    }
    setMessage(errorMessage);
    setPaymentProcessing(false);
  }

  function determineButton() {
    let buttonContents;
    if (isPaymentProcessing) {
      buttonContents = paymentButtonProcessing();
    } else if (isGuestPay) {
      buttonContents = buttonNext();
    } else {
      buttonContents =
        paymentType === PAY_BY_BANK_METHOD
          ? buttonContinue(transaction.amount, transaction.currency)
          : buttonPayNow(
              transaction.amount,
              transaction.currency,
              paymentButtonText,
              fordPayGuestId
            );
    }
    return buttonContents;
  }

  function handlePaymentRequestReady(isReady: boolean) {
    setPaymentProcessing(!isReady);
  }

  function togglePayByAcssDebit(shouldOpenAcssDebit: boolean): void {
    setPayByAcssDebit(shouldOpenAcssDebit);
  }

  if (
    isPayByAcssDebit &&
    connectPaymentIntentClientSecret != null &&
    stripeConnect
  ) {
    return (
      <PayByAcssDebit
        clientSecret={connectPaymentIntentClientSecret}
        stripe={stripeConnect}
        isPreAuth={props.isPreAuth}
        isAuthenticatedCheckout={false}
        togglePayByAcssDebit={togglePayByAcssDebit}
        isMit={false}
        mitCloseButtonEnable={() => {}}
        isOnlyPayByAcssDebit={!shouldShowCardElement}
        isAcssDebitFirst={isAcssDisplayedFirst}
      />
    );
  }
  let paymentRequestButton;
  if (elements != null) {
    paymentRequestButton = (
      <GuestPaymentRequestButton
        elements={elements}
        stripe={props.stripe}
        isReadyHandler={handlePaymentRequestReady}
        setPaymentMethod={setPaymentMethod}
        setConfirmationMethod={setConfirmationMethod}
        handleSetupIntent={handleSetupIntent}
        paymentMethodTypes={options.payment_method_types}
      />
    );
  } else {
    paymentRequestButton = <></>;
  }

  return (
    <>
      {isPaymentMounted ? '' : <LoadingSpinner style={loadingSpinnerBig} />}
      <form
        hidden={!isPaymentMounted}
        onSubmit={handleCheckout}
        id="payment-form"
        data-testid="payment-container"
      >
        {paymentRequestButton}
        {isAcssDebit && isAcssDisplayedFirst && (
          <div
            style={{ textAlign: 'left', padding: '15px' }}
            data-testid={'acss-element-in-first'}
          >
            <PayByAcssDebitLink
              togglePayByAcssDebit={togglePayByAcssDebit}
            ></PayByAcssDebitLink>
          </div>
        )}
        <div
          id="payment-element"
          data-testid={'payment-element'}
          style={
            isGuestPay
              ? { paddingBlockStart: '1em' }
              : { ...cardElementStyle, paddingBlockStart: '1em' }
          }
          hidden={!shouldShowCardElement}
        >
          {/*Payment Element gets injected here.*/}
        </div>
        {isAcssDebit && !isAcssDisplayedFirst && (
          <div
            style={{
              textAlign: 'left',
              paddingLeft: '19px',
              marginTop: '4px',
              paddingTop: '3px',
              paddingBottom: '40px',
              lineHeight: '5px'
            }}
          >
            <div data-testid={'acss-element-in-last'}>
              <PayByAcssDebitLink
                togglePayByAcssDebit={togglePayByAcssDebit}
              ></PayByAcssDebitLink>
            </div>
          </div>
        )}
        {!shouldDisableTermsAndConditions && (
          <TermsAndConditions
            translationGroup={translationKeys.checkout}
            paymentButtonText={paymentButtonText}
            paymentMethod={paymentType}
            isFordPayGuestIdCheckout={!!(fordPayGuestId && paymentButtonText)}
          />
        )}
        <StripeElementErrorDisplay message={message} isGuestPay={isGuestPay} />
        {isGuestPay ? (
          <UCLButton
            disabled={!isFormComplete || isPaymentProcessing || !nextButton}
            id="submit"
            data-testid="ucl-button-next"
          >
            {buttonNext()}
            <ChevronIcon.Right />
          </UCLButton>
        ) : (
          <Button
            style={{
              justifyContent: 'center'
            }}
            disabled={!isFormComplete || isPaymentProcessing || !nextButton}
            id="submit"
          >
            {determineButton()}
          </Button>
        )}
      </form>
    </>
  );
};
