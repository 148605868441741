import {
  CheckoutTokens,
  MISSING_PARAM_ERROR_MESSAGE,
  PAYMENT_INFO_TOKEN,
  REDIRECT_URL
} from '@fpc/reactutils/checkoutContextProvider';
import { attributeHasValue } from '@fpc/utils/attributeHasValue';
import { AZURE_AD_TOKEN } from '@fpc/common';
import { Language, supportedLanguages } from '@fpc/common/Language';
import { TransactionDetails } from '@fpc/common/transactionInterfaces';
import { Psp } from '@fpc/common/Psp';

export interface CommonCheckoutProps extends CheckoutTokens {
  redirectUrl: string;
  merchantCurrency?: string;
  bffBaseUrl?: string | null;
  transactionDetails: TransactionDetails;
  noCreditCards: boolean;
  language: Language | null;
  fordPayGuestId: string | null;
  paymentButtonText: string | null;
  isFromMitComponent?: boolean;
}

export function validateCheckout(props: CommonCheckoutProps) {
  const hasValidLanguage: boolean =
    attributeHasValue(props.language) &&
    supportedLanguages.includes(props.language);

  const hasRequiredAttributes: boolean =
    hasValidLanguage &&
    attributeHasValue(props.paymentInfoToken) &&
    (!!props.fordPayGuestId || attributeHasValue(props.redirectUrl));

  const isValidCheckout: boolean =
    hasRequiredAttributes &&
    (attributeHasValue(props.bearerToken) ||
      attributeHasValue(props.bffBaseUrl) ||
      props.paymentObjectType === 'DIGITALSIGN' ||
      attributeHasValue(props.customerAuthToken));

  const isAuthCheckout =
    isValidCheckout &&
    (attributeHasValue(props.customerAuthToken) ||
      attributeHasValue(props.bffBaseUrl));

  return {
    isValidCheckout: isValidCheckout,
    isAuthCheckout: isAuthCheckout
  };
}

export function logMissingParamError(
  paymentInfoToken: string,
  redirectUrl: string,
  bearerToken: string,
  psp?: string
) {
  const missingParams = [];
  if (!paymentInfoToken) {
    missingParams.push(PAYMENT_INFO_TOKEN);
  }
  if (!redirectUrl) {
    missingParams.push(REDIRECT_URL);
  }

  if (!bearerToken && psp != Psp.Puma) {
    missingParams.push(AZURE_AD_TOKEN);
  }

  if (missingParams.length > 0) {
    console.error(MISSING_PARAM_ERROR_MESSAGE, missingParams);
  }
}
