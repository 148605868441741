import styled from 'styled-components/macro';
import { Style } from '../Styles';
import { FordStyles } from '../FordStyles';

export const UnstyledButton = styled.button`
  && {
    all: unset;
    cursor: pointer;
  }
  &&:disabled {
    cursor: not-allowed;
    font-family: ${Style.fontFamilyCondLight};
    backgroundcolor: ${FordStyles.color.disabled};
    outline: none;
    outline-offset: 0.8em;
    margin: 0;
  }
`;

/** Adds an outline to the UnstyledButton component on focus. */
export const UnstyledButtonWithFocus = styled(UnstyledButton)<{
  focusColor?: string;
}>`
  && {
    height: 1.5em;
    width: 1.5em;
  }

  &&:focus {
    outline: 1px solid ${(props) => props.focusColor ?? Style.color.secondary};
    outline-offset: 1px;
  }
`;
export const DEFAULT_BUTTON_STYLES = {
  borderRadius: '0.75rem',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'center'
};
