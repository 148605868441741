import i18n from 'i18next';
import { supportedLanguages } from '@fpc/common/Language';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enUS } from './i18n/en-US';
import { csCZ } from './i18n/cs-CZ';
import { daDK } from './i18n/da-DK';
import { deAT } from './i18n/de-AT';
import { deCH } from './i18n/de-CH';
import { deDE } from './i18n/de-DE';
import { elGR } from './i18n/el-GR';
import { enAU } from './i18n/en-AU';
import { enCA } from './i18n/en-CA';
import { enGB } from './i18n/en-GB';
import { enIE } from './i18n/en-IE';
import { enNZ } from './i18n/en-NZ';
import { esES } from './i18n/es-ES';
import { esUS } from './i18n/es-US';
import { fiFI } from './i18n/fi-FI';
import { frBE } from './i18n/fr-BE';
import { frCA } from './i18n/fr-CA';
import { frCH } from './i18n/fr-CH';
import { frFR } from './i18n/fr-FR';
import { frLU } from './i18n/fr-LU';
import { huHU } from './i18n/hu-HU';
import { itCH } from './i18n/it-CH';
import { itIT } from './i18n/it-IT';
import { nlBE } from './i18n/nl-BE';
import { nlNL } from './i18n/nl-NL';
import { noNO } from './i18n/no-NO';
import { plPL } from './i18n/pl-PL';
import { ptPT } from './i18n/pt-PT';
import { roRO } from './i18n/ro-RO';
import { svSE } from './i18n/sv-SE';
import { thTH } from './i18n/th-TH';
import { enTH } from './i18n/en-TH';
import { enZA } from './i18n/en-ZA';
import { viVN } from './i18n/vi-VN';
import { enPH } from './i18n/en-PH';

i18n.use(LanguageDetector).init({
  supportedLngs: supportedLanguages,
  fallbackLng: ['en-US'],
  resources: {
    'en-US': { translation: enUS },
    'cs-CZ': { translation: csCZ },
    'da-DK': { translation: daDK },
    'de-AT': { translation: deAT },
    'de-CH': { translation: deCH },
    'de-DE': { translation: deDE },
    'el-GR': { translation: elGR },
    'en-AU': { translation: enAU },
    'en-CA': { translation: enCA },
    'en-GB': { translation: enGB },
    'en-IE': { translation: enIE },
    'en-NZ': { translation: enNZ },
    'es-ES': { translation: esES },
    'es-US': { translation: esUS },
    'fi-FI': { translation: fiFI },
    'fr-BE': { translation: frBE },
    'fr-CA': { translation: frCA },
    'fr-CH': { translation: frCH },
    'fr-FR': { translation: frFR },
    'fr-LU': { translation: frLU },
    'hu-HU': { translation: huHU },
    'it-CH': { translation: itCH },
    'it-IT': { translation: itIT },
    'nl-BE': { translation: nlBE },
    'nl-NL': { translation: nlNL },
    'no-NO': { translation: noNO },
    'pl-PL': { translation: plPL },
    'pt-PT': { translation: ptPT },
    'ro-RO': { translation: roRO },
    'sv-SE': { translation: svSE },
    'th-TH': { translation: thTH },
    'en-TH': { translation: enTH },
    'en-ZA': { translation: enZA },
    'vi-VN': { translation: viVN },
    'en-PH': { translation: enPH }
  },
  detection: {
    order: ['querystring', 'navigator']
  }
});

export default i18n;
