import React, { useState } from 'react';
import {
  CheckoutInput,
  InputGridArea,
  InputLabel
} from '@fpc/common/components/InputComponents';
import { TextInput } from '@fpc/checkout/payu/Style';
import i18n from 'i18next';
import { translationKeys } from '@fpc/common';

const isValidMonth = (month: string): boolean => {
  const monthNum = parseInt(month);
  return monthNum >= 1 && monthNum <= 12;
};

const isValidYear = (year: string): boolean => {
  const yearNum = parseInt(year);
  const currentYear = new Date().getFullYear();
  return yearNum >= currentYear;
};

const isValidExpiryDate = (month: string, year: string): boolean => {
  if (!month || !year || year.length !== 4) {
    return false;
  }

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  if (parseInt(year) === currentYear && parseInt(month) < currentMonth) {
    return false;
  }

  return isValidMonth(month) && isValidYear(year);
};

const getValidationError = (month: string, year: string): string => {
  if (!month || !year) {
    return 'Please enter a valid expiration date';
  }

  if (!isValidMonth(month)) {
    return 'Invalid month. Must be between 01 and 12';
  }

  if (year.length !== 4) {
    return 'Your card’s expiration year is incomplete';
  }

  if (!isValidYear(year)) {
    return 'Your card’s expiration year is in the past.';
  }

  if (!isValidExpiryDate(month, year)) {
    return 'Card has expired';
  }

  return '';
};

const focusCvvInput = (): void => {
  const cvvInput = document.getElementById('cvv');
  cvvInput?.focus();
};

interface CardExpiryDateInputProps {
  expiryMonth: string;
  expiryYear: string;
  onMonthChange: (month: string) => void;
  onYearChange: (year: string) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  setIsValid: (isValid: boolean) => void;
}

const formatExpiryDate = (month: string, year: string): string => {
  return month && year ? `${month}/${year}` : '';
};

const parseExpiryInput = (value: string): { month: string; year: string } => {
  const digitsOnly = value.replace(/\D/g, '').slice(0, 6);
  const month = digitsOnly.substring(0, 2);
  const year = digitsOnly.length > 2 ? digitsOnly.substring(2) : '';

  return { month, year };
};

const isBackspaceAtSeparator = (
  cursorPosition: number | null,
  value: string
): boolean => {
  return cursorPosition === 3 && value.charAt(2) === '/';
};

const isBackspaceCaseMet = (
  prevValue: string,
  currentValue: string
): boolean => {
  return (
    prevValue.length > currentValue.length &&
    prevValue.includes('/') &&
    !currentValue.includes('/') &&
    currentValue.length === 2
  );
};

const shouldAddSeparator = (
  value: string,
  key: string,
  shiftKey: boolean
): boolean => {
  return (
    key === 'Tab' && !shiftKey && value.length === 2 && isValidMonth(value)
  );
};

const CardExpiryDateInput = React.forwardRef<
  HTMLInputElement,
  CardExpiryDateInputProps
>((props, ref) => {
  const {
    expiryMonth,
    expiryYear,
    onMonthChange,
    onYearChange,
    onBlur,
    setIsValid
  } = props;

  const [displayValue, setDisplayValue] = useState(
    formatExpiryDate(expiryMonth, expiryYear)
  );
  const [error, setError] = useState<string>('');

  const validateInput = (month: string, year: string): void => {
    const errorMessage = getValidationError(month, year);
    setError(errorMessage);
    setIsValid(!errorMessage);
  };

  const updateMonthOnly = (monthValue: string): void => {
    setDisplayValue(monthValue);
    onMonthChange(monthValue);
    onYearChange('');
    setIsValid(false);
    setError(
      monthValue.length === 0 ? 'Please enter a valid expiration date' : ''
    );
  };

  const updateFullDate = (month: string, year: string): void => {
    const formattedValue = `${month}/${year}`;
    setDisplayValue(formattedValue);

    if (isValidMonth(month)) {
      onMonthChange(month);
      onYearChange(year);

      if (year.length === 4 && isValidExpiryDate(month, year)) {
        focusCvvInput();
      }
    }

    validateInput(month, year);
  };

  const setSelectionPosition = (
    input: HTMLInputElement,
    position: number
  ): void => {
    setTimeout(() => {
      input.setSelectionRange(position, position);
    }, 0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value;

    if (isBackspaceCaseMet(displayValue, inputValue)) {
      updateMonthOnly(inputValue);
      return;
    }

    const { month, year } = parseExpiryInput(inputValue);

    if (!year) {
      updateMonthOnly(month);
      return;
    }

    updateFullDate(month, year);
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const [month, year] = displayValue.split('/');
    validateInput(month || '', year || '');
    onBlur(event);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    const input = event.currentTarget;
    const value = input.value.replace(/\D/g, '');

    if (shouldAddSeparator(value, event.key, event.shiftKey)) {
      event.preventDefault();
      setDisplayValue(`${value}/`);
      onMonthChange(value);
      setSelectionPosition(input, 3);
      return;
    }

    if (
      event.key === 'Backspace' &&
      isBackspaceAtSeparator(input.selectionStart, input.value)
    ) {
      event.preventDefault();
      updateMonthOnly(input.value.substring(0, 2));
      setSelectionPosition(input, 2);
    }
  };

  return (
    <InputGridArea
      style={{
        gridArea: '1 / 1 / auto / 2'
      }}
    >
      <InputLabel htmlFor="expiry-date">
        {i18n.t<string>(translationKeys.checkout.expirationDate)}
      </InputLabel>
      <CheckoutInput
        style={{
          borderBottomLeftRadius: '.25rem',
          borderTopLeftRadius: '.25rem',
          borderBottomRightRadius: '.25rem',
          borderTopRightRadius: '.25rem',
          padding: '0.9em 0.5em',
          height: '1.5rem'
        }}
      >
        <TextInput
          id="expiry-date"
          data-testid="expiry-date-input"
          placeholder="MM/YYYY"
          maxLength={7}
          value={displayValue}
          onChange={handleChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          ref={ref}
          style={{
            paddingLeft: '0rem'
          }}
        />
      </CheckoutInput>
      {error && (
        <span
          className="helper-text"
          id="expiry-date-help"
          data-testid="expiry-date-error"
          style={{ color: 'red', fontSize: '12px' }}
        >
          {error}
        </span>
      )}
    </InputGridArea>
  );
});

export default CardExpiryDateInput;
