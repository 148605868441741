import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { traceId } from '@fpc/reactutils/TraceIdContext';
import {
  enterCustomAction,
  leaveCustomAction,
  log,
  reportCustomError
} from '@fpc/common/monitoring/shared';

declare module 'axios' {
  export interface AxiosRequestConfig {
    rumData?: {
      actionId?: number;
    };
  }
}

export function requestMonitoringInterceptor(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  const actionId = enterCustomAction(config.url || '');
  log(
    `Request initiated. Method: ${config.method}, URL: ${config.url}, Action ID: ${actionId}.`
  );

  config.rumData = { actionId };
  return config;
}

export function requestMonitoringErrorInterceptor(
  error: AxiosError
): Promise<AxiosError> {
  const actionId = error.config?.rumData?.actionId || 0;
  log(
    `Request error. Method: ${error.config?.method}, URL: ${error.config?.url}, Action ID: ${actionId}.`
  );

  if (Boolean(actionId)) {
    reportCustomError(
      actionId,
      `HTTP 500: ${error.config?.method} ${error.config?.url}`,
      error.message
    );

    const actionProperties: Record<string, string> = {
      fpp_request_url: error.config?.url ?? '',
      fpp_request_method: error.config?.method ?? '',
      fpp_domain: window.location.hostname,
      fpp_status_code: '500',
      fpp_trace: traceId
    };

    leaveCustomAction(actionId, actionProperties);
  }
  return Promise.reject(error);
}

export function responseMonitoringInterceptor(
  response: AxiosResponse
): AxiosResponse {
  const actionId = response.config.rumData?.actionId || 0;
  log(
    `Response received. Method: ${response.config.method}, URL: ${response.config.url}, Status: ${response.status}, Action ID: ${actionId}`
  );

  if (Boolean(actionId)) {
    const actionProperties: Record<string, string> = {
      fpp_request_url: response.config.url || '',
      fpp_request_method: response.config.method || '',
      fpp_domain: window.location.hostname,
      fpp_status_code: response.status.toString(),
      fpp_trace: traceId
    };

    leaveCustomAction(actionId, actionProperties);
  }
  return response;
}

export function responseMonitoringErrorInterceptor(
  error: AxiosError
): Promise<AxiosError> {
  const actionId = error.config?.rumData?.actionId || 0;
  log(
    `Response error. Method: ${error.config?.method}, URL: ${error.config?.url}, Status: ${error.response?.status}, Action ID: ${actionId}.`
  );

  if (Boolean(actionId)) {
    reportCustomError(
      actionId,
      `HTTP ${error.response?.status}: ${error.config?.method} ${error.config?.url}`,
      'Request could not be processed successfully'
    );

    const actionProperties: Record<string, string> = {
      fpp_request_url: error.config?.url ?? '',
      fpp_request_method: error.config?.method ?? '',
      fpp_domain: window.location.hostname,
      fpp_status_code: error.response?.status.toString() ?? '',
      fpp_trace: traceId
    };

    leaveCustomAction(actionId, actionProperties);
  }
  return Promise.reject(error);
}
