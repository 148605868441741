export const AZURE_AD_TOKEN = 'azure-ad-token';
export const BEARER_TOKEN = 'bearer-token';
export const PAYMENT_INFO_TOKEN = 'payment-info-token';
export const PAYMENT_OBJECT_TYPE = 'payment-object-type';
export const CUSTOMER_AUTH_TOKEN = 'customer-auth-token';
export const CUSTOMER_AUTH_TOKEN_TYPE = 'customer-auth-token-type';
export const MERCHANT_CURRENCY = 'merchant-currency';
export const MERCHANT_COUNTRY = 'merchant-country';
export const LANGUAGE = 'language';
export const PAYMENT_BUTTON_TEXT = 'payment-button-text';
export const DEFAULT_PAYMENT_BUTTON_TEXT = 'Confirm purchase';
export const IS_PRE_AUTH = 'is-pre-auth';
export const BFF_BASE_URL = 'bff-base-url';
export const NO_CREDIT_CARDS = 'no-credit-cards';
export const REQUIRES_ACTION_STATUS = 'requires_action';
export const REDIRECT_STATUS = 'redirect-status';
export const SUCCESS_STATUS = 'succeeded';
export const FAILED_STATUS = 'failed';
export const PAYMENT_METHOD_DISPLAY_ORDER = 'payment-method-display-order';
export const STRIPE_GENERIC_DECLINE = 'generic_decline';

export const ACH_NO_PAY = 'ach-no-pay';
export const APIGEE_AUTH_ERROR = 'Failed to fetch';
export const OPTIONAL_TERMS = 'optional-terms';
export const FORDPAY_GUEST_ID = 'fordpay-guest-id';
