import { useAuthContextStore } from '../../stores/auth-context.store';
import { getApiBaseUrl } from '../../helpers/http';
import { AppDomain } from '../../types/app-domain';
import httpClient, { getHeadersByAuthTokens } from '@fpc/common/HttpClient';

export function detachPaymentMethod(
  paymentMethodId: string,
  merchantAccountId?: string | undefined
): Promise<void> {
  merchantAccountId = merchantAccountId || undefined;

  const { bffBaseUrl, customerAuthTokenType } = useAuthContextStore.getState();

  const baseUrl = getApiBaseUrl(
    AppDomain.CUSTOMER_APP,
    customerAuthTokenType,
    bffBaseUrl
  );
  const url = `${baseUrl}/customers/payment-methods/detach`;

  return httpClient.post(
    url,
    { paymentMethodId, merchantAccountId },
    getHeadersByAuthTokens()
  );
}
