import { CheckoutTokens } from '@fpc/reactutils/checkoutContextProvider';
import { MitTokens } from '@fpc/reactutils/mitContextProvider';
import { PAYMENT_INITIATOR } from '@fpc/common';
import httpClient, { getHeadersByAuthTokens } from '@fpc/common/HttpClient';
import { useAuthContextStore } from '../../stores/auth-context.store';
import { getApiBaseUrl } from '../../helpers/http';
import { AppDomain } from '../../types/app-domain';

export interface AuthPaymentRequest {
  checkoutTokens: CheckoutTokens | MitTokens;
  stripeCustomerId?: string | null;
  paymentMethodId: string | null;
  paymentMethodType: string;
  isSavePaymentMethod: boolean;
  setPaymentMethodAsDefault: boolean;
  fordCustomerId?: string | null;
  paymentInitiator: PAYMENT_INITIATOR;
  isPreAuth?: boolean;
  bffBaseUrl?: string;
  isConnectFlow?: boolean;
  confirmationId?: string | null;
  redirectUrl?: string | null;
}

export interface PaymentResponse {
  paymentIntentClientSecret: string;
  paymentIntentId: string;
  status: string;
  merchantAccountId: string;
  paymentMethodDetails?: {
    type: string;
    card?: {
      brand: string;
      expiryMonth: string;
      expiryYear: string;
      lastFourDigits: string;
      walletType?: string;
    };
    usBankAccount?: {
      bankName: string;
      lastFourDigits: string;
    };
  };
  stripeConnectAccountId?: string;
}

export interface MultiMerchantPaymentResponse extends PaymentResponse {
  status: string;
  data: {
    merchantId: string;
    confirmationId: string;
    paymentIntentId: string;
    status: string;
  }[];
}

export async function makeAuthenticatedPayment(
  request: AuthPaymentRequest
): Promise<PaymentResponse> {
  const { customerAuthTokenType } = useAuthContextStore.getState();

  const baseUrl = getApiBaseUrl(
    AppDomain.PAYMENT_APP,
    customerAuthTokenType,
    request.bffBaseUrl
  );

  const endpoint =
    request.paymentInitiator === PAYMENT_INITIATOR.CUSTOMER
      ? 'authenticated-payment'
      : 'authenticated-payment-merchant';

  const paymentInfoObj = request.bffBaseUrl
    ? { paymentInfoJson: request.checkoutTokens.paymentInfoToken }
    : { paymentInfoToken: request.checkoutTokens.paymentInfoToken };

  const headers = getHeadersByAuthTokens();

  return httpClient.post(
    `${baseUrl}/${endpoint}`,
    {
      ...paymentInfoObj,
      stripeCustomerId: request.stripeCustomerId
        ? request.stripeCustomerId
        : null,
      paymentMethodId: request.paymentMethodId,
      fordCustomerId: request.fordCustomerId ?? null,
      paymentMethodType: request.paymentMethodType.toUpperCase(),
      setPaymentMethodAsDefault: request.setPaymentMethodAsDefault,
      isPaymentMethodDetach: !request.isSavePaymentMethod,
      isPreAuth: request.isPreAuth,
      isConnectFlow: request.isConnectFlow,
      confirmationId: request.confirmationId,
      redirectUrl: request.redirectUrl
    },
    headers
  );
}
