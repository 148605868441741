import { enUS, TRANSLATION_MISSING } from './en-US';

export const deCH: typeof enUS = {
  common: {
    default: 'standard',
    endingIn: 'endend mit',
    close: 'Schliessen',
    makeDefaultCard: 'Standardkarte erstellen',
    makeDefaultInfoHover:
      'Diese Karte wird automatisch zu Ihrer Standardkarte, da sie die erste Karte in Ihrer Geldb\u00f6rse ist.',
    processing: 'In Bearbeitung ...',
    saveCardCheck: 'Karte f\u00fcr zuk\u00fcnftige Transaktionen speichern',
    saveCardSuccess:
      'Die Zahlungsmethode wurde zu Ihrem Konto hinzugef\u00fcgt.',
    technicalErrorPayment:
      'Entschuldigung! Es gab ein technisches Problem und Ihre Zahlung konnte nicht ausgef\u00fchrt werden. Bitte versuchen Sie es erneut.',
    timeoutSorry: 'Entschuldigung ...',
    timeoutError: 'Wir haben im Moment technische Schwierigkeiten.',
    timeoutTryAgain: 'Erneut versuchen',
    savePaymentMethodCheck:
      'Zahlungsmethode f\u00fcr zuk\u00fcnftige Transaktionen speichern',
    makeDefaultPaymentMethod: 'Als Standardzahlungsmethode festlegen',
    savePaymentMethodButton: 'Zahlungsmethode speichern',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Dies wird automatisch zu Ihrer Standardzahlungsmethode, da es sich um die erste Zahlungsmethode in Ihrem Wallet handelt.',
    savePaymentMethodSuccess:
      'Die Zahlungsmethode wurde zu Wallet hinzugef\u00fcgt.'
  },
  checkout: {
    payButton: 'bezahlen  {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Entschuldigung! Es ist ein technisches Problem aufgetreten, und Ihre Zahlung konnte nicht ausgef\u00fchrt werden. Bitte aktualisieren Sie die Seite und versuchen Sie es erneut. ',
    technicalErrorPaymentDetachFail:
      'Entschuldigung! Es gab ein technisches Problem und Ihre Zahlung konnte nicht ausgef\u00fchrt werden. Ihre Karte wurde in Ihrem Wallet gespeichert. Bitte versuchen Sie es erneut.',
    tosTextBefore:
      'Indem Sie unten auf die Schaltfl\u00e4che \u201eBezahlen\u201c klicken, erkl\u00e4ren Sie sich mit den ',
    tosTextBeforeForFordPayGuestId:
      'Indem Sie unten auf die Schaltfl\u00e4che {{paymentButtonText}} klicken, erkl\u00e4ren Sie sich mit den ',
    tosLinkText: 'Zahlungsbedingungen und Datenschutzbestimmungen',
    tosTextAfter: '.',
    useNewCard: 'Neue Karte verwenden',
    useNewCardDropdownText: 'Neue Karte hinzuf\u00fcgen',
    useNewCardTextBefore: 'Mit gespeicherter Karte zur Kasse gehen oder ',
    useNewCardLinkText: 'neue Zahlungsmethode verwenden',
    useNewCardTextAfter: '.',
    cardSelect: 'Kreditkarte ausw\u00e4hlen',
    checkoutFaster: 'Schneller auschecken mit FordPay.',
    checkout: 'Kasse',
    nameOnCard: 'Name auf der Karte',
    fullName: 'Vor- und Nachname',
    cardNumber: 'Kartennummer',
    expirationDate: 'EXP Datum',
    securityCode: 'Sicherheitscode',
    invalidCCNMessage: 'Ihre Kartennummer ist nicht g\u00fcltig.',
    invalidCVVMessage: 'Ihr Sicherheitscode ist nicht g\u00fcltig.',
    invalidExpDateMessage: 'Das Ablaufdatum ist ung\u00fcltig.',
    emptyCCNMessage: 'Ihre Kartennummer ist leer.',
    emptyCVVMessage: 'Ihr Sicherheitscode ist leer.',
    emptyExpDateMessage: 'Ihr Ablaufdatum ist leer.',
    unsupportedCardMessage:
      'Ihre Kartennummer wird nicht unterst\u00fctzt. Bitte w\u00e4hlen Sie VISA, MasterCard oder AMEX.',
    paymentNotProcessed: 'Ihre Bezahlung konnte nicht bearbeitet werden',
    redirectStatusFailure:
      'Ihre Bezahlung konnte nicht bearbeitet werden. Bitte versuchen Sie es erneut.\u00a0',
    continue: 'Weitermachen {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Indem Sie unten auf die Schaltfl\u00e4che \u201eWeitermachen\u201c klicken, erkl\u00e4ren Sie sich mit den ',
    authCheckoutStatement:
      'Bezahlen Sie mit der gespeicherten Karte, verwenden Sie eine neue Zahlungsmethode oder nutzen Sie \u201ePay Per Bank\u201c.',
    useNewCardTextBeforePayByBank: 'Bezahlen Sie mit der gespeicherten Karte, ',
    usePayByBank: ', oder nutzen Sie ',
    payByBankLinkText: 'Pay Per Bank',
    payByBankHeader: 'Verwenden Sie \u201eBezahlen per Bank\u201c.',
    use: 'verwenden',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Neue Zahlungsmethode verwenden',
    useNewPaymentDropdownText: 'neue Zahlungsmethode verwenden',
    useNewPaymentTextBefore:
      'Zur Kasse gehen mit gespeicherter Zahlungsmethode oder ',
    useNewPaymentLinkText: 'neue Zahlungsmethode verwenden.',
    useNewPaymentTextBeforePayByBank:
      'Zur Kasse gehen mit gespeicherter Zahlungsart, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Sie haben derzeit keine Zahlungsmethoden hinterlegt. Legen Sie los, indem Sie Ihre erste Zahlungsmethode hinzuf\u00fcgen.',
    addPaymentMethod: 'Zahlungsmethode hinzuf\u00fcgen',
    makeDefault: 'Als Standard ausw\u00e4hlen',
    changeDefaultPMSuccess:
      'Ihre Standard-Zahlungsmethode wurde ge\u00e4ndert.',
    makeDefaultSuccessPM: 'Zahlungsmethode als Standard hinzugef\u00fcgt.',
    makeDefaultErrorPM:
      'Fehler beim Einstellen der Zahlungsmethode als Standard',
    removePMSuccess: 'Die Zahlungsmethode wurde aus Ihrem Konto entfernt.',
    removePMError: 'Fehler beim Entfernen der Zahlungsmethode.',
    removePM: 'Zahlungsmethode entfernen',
    removePMQuestion: 'Zahlungsmethode entfernen?',
    removePMText:
      'Sind Sie sicher, dass Sie die Zahlungsmethode {{cardNumber}} aus Ihrer Wallet entfernen m\u00f6chten? Alle aktiven Abonnements, die mit dieser Zahlungsmethode verkn\u00fcpft sind, schlagen fehl, wenn die Zahlungsmethode entfernt wird.',
    defaultCard: 'Standardkarte',
    defaultPM: 'Standardzahlungsmethode\u00a0',
    defaultCardQuestion: 'Standardkarte?',
    defaultPMQuestion: 'Standardzahlungsmethode?',
    defaultCardText:
      'Sind Sie sicher, dass Sie Ihre Standardkarte zu {{cardNumber}} \u00e4ndern m\u00f6chten?',
    defaultPMText:
      'Sind Sie sicher, dass Sie Ihre Standardzahlungsmethode auf {{cardNumber}} \u00e4ndern m\u00f6chten?',
    navigationLabel: 'Kontoverwaltungsnavigation',
    removeDefaultPMSuccess:
      'Standardzahlungsmethode aus der Ford.com Wallet entfernt',
    removeDefaultCardError: 'Fehler beim Entfernen der Standardkarte.',
    removeDefaultPMError: 'Fehler beim Entfernen der Standardzahlungsmethode.',
    removeDefaultCard: 'Entfernen Sie Ihre Standardkarte',
    removeDefaultPM: 'Standardzahlungsmethode entfernen?\u00a0',
    removeDefaultPMText:
      'Zahlungsmethode {{cardNumber}} ist Ihre Standardzahlungsmethode. Bitte w\u00e4hlen Sie unten eine neue Standardzahlungsmethode aus.\u00a0Alle aktiven Abonnements, die mit dieser Zahlungsmethode verkn\u00fcpft sind, schlagen fehl, wenn die Zahlungsmethode entfernt wird.',
    removeDefaultPMButton:
      'Zahlungsmethode entfernen und Standard aktualisieren',
    removeDefaultSelectPM: 'Neue Standardzahlungsmethode ausw\u00e4hlen',
    title: 'Konto',
    expiration: 'Ablaufdatum\u00a0{{date}}',
    expires: 'L\u00e4uft ab am {{date}}',
    stripeDefaultError:
      'Es gab ein technisches Problem. Bitte versuchen Sie es erneut.\u00a0',
    paymentMethod: 'Zahlungsmethode',
    tosTextBefore:
      'Indem Sie unten auf die Schaltfl\u00e4che \u201eKarte speichern\u201c klicken, erkl\u00e4ren Sie sich mit den ',
    tosTextBeforePM:
      'Durch Klicken auf die Schaltfl\u00e4che "Zahlungsmethode speichern" stimmen Sie ',
    tosLinkText: 'unseren Zahlungsbedingungen zu',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Alle',
    amount: 'Betrag',
    date: 'Datum',
    description: 'Beschreibung',
    displayingAll: 'Alle Transaktionen anzeigen.',
    displayingDays: 'Transaktionen der letzten {{numberOfDays}} Tage anzeigen.',
    displayingYear: 'Transaktionen von {{year}} anzeigen.',
    filter: 'Letzte {{numberOfDays}} Tage',
    loadAll: 'Alle laden',
    loadMore: '{{x}} mehr laden',
    noTransactions: 'Es werden keine Transaktionen angezeigt.',
    paymentMethod: 'Zahlungsmethode',
    refund: 'R\u00fcckerstattung',
    searchBar: 'Suche nach Beschreibung',
    timeFilter: 'Zeitrahmen f\u00fcr Transaktionshistorie ausw\u00e4hlen',
    title: 'Transaktionen',
    pending: 'Ausstehend'
  },
  mit: {
    confirmPurchase: 'Best\u00e4tige den Kauf',
    paymentSuccessful: 'Zahlung erfolgreich',
    saveCardAlert:
      'Um diese Karte f\u00fcr zuk\u00fcnftige Zahlungen zu verwenden, muss sie in Ihrem Ford.com-Wallet gespeichert sein.',
    title: 'Auswahl der Zahlungsmethode',
    tosTextBefore:
      'Indem Sie unten auf die Schaltfl\u00e4che {{paymentButtonText}} klicken, erkl\u00e4ren Sie sich mit den ',
    tosLinkText: 'Zahlungsbedingungen einverstanden',
    tosTextAfter: '.',
    useNewCard: 'Neue Karte verwenden',
    useNewCardDropDownItem: 'Neue Karte verwenden',
    useNewCardTextBefore: 'Gespeicherte Karte w\u00e4hlen oder ',
    useNewCardLinkText: 'neue Karte verwenden',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Neue Zahlungsmethode verwenden',
    useNewPaymentDropdownText: 'neue Zahlungsmethode verwenden',
    useNewPaymentTextBefore:
      'Zur Kasse gehen mit gespeicherter Zahlungsmethode oder ',
    useNewPaymentLinkText: 'neue Zahlungsmethode verwenden',
    successful: 'Erfolgreich',
    savePaymentMethodAlert:
      'Um diese Zahlungsmethode f\u00fcr zuk\u00fcnftige Zahlungen nutzen zu k\u00f6nnen, muss sie in Ihrem Wallet gespeichert werden.'
  }
};
