import { HeadersBuilder } from '@fpc/utils/headerBuilder';
import { PaymentMethodDetail } from '@fpc/utils/dispatchEvent';

export interface SetupIntentResponse {
  id: string;
  paymentMethodDetails?: PaymentMethodDetail;
  paymentMethodId?: string;
  status?: string;
  setupIntentClientSecret?: string;
}
export async function setupIntent(
  confirmationToken: string,
  paymentInfo: string,
  digitalSignature?: string,
  paymentMethodTypes?: string[],
  fordPayGuestId?: string
): Promise<SetupIntentResponse> {
  let paymentInfoPayload: string = JSON.parse(paymentInfo).paymentInfo;
  const request: Request = getCreateAndConfirmSetupIntent(
    confirmationToken,
    paymentInfoPayload,
    digitalSignature,
    fordPayGuestId,
    paymentMethodTypes
  );
  return fetch(request)
    .then((res: Response) => {
      if (res.ok) {
        return res.json().then((data) => {
          return data;
        });
      } else {
        return res.json().then((data) => {
          return Promise.reject(data);
        });
      }
    })
    .catch((err) => {
      console.error('Error submitting SetupIntent request: ', err);
      return Promise.reject(err);
    });
}

function getCreateAndConfirmSetupIntent(
  confirmationToken: string,
  paymentInfoPayload: string,
  digitalSignature?: string | null,
  forPayGuestId?: string,
  paymentMethodTypes?: string[]
): Request {
  let request;
  let headers: Headers = HeadersBuilder();
  headers.set('Authorization', `${digitalSignature}`);
  const requestUrl = `${process.env.PAYMENT_DIGITAL_SIGNATURE_SERVER}/api/setup-intent`;
  request = new Request(`${requestUrl}`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      confirmationToken: confirmationToken,
      paymentInfo: paymentInfoPayload,
      fordPayGuestId: forPayGuestId,
      paymentMethodTypes: paymentMethodTypes
    })
  });
  return request;
}
