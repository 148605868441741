import { PaymentIntentConfirmRequest } from './PaymentIntentCreationRequest';
import { PaymentResponse } from '@fpc/api/paymentapp/MakeAuthenticatedPayment';
import httpClient, { getHeadersByAuthTokens } from '@fpc/common/HttpClient';
import { getApiBaseUrl } from '../../helpers/http';
import { useAuthContextStore } from '../../stores/auth-context.store';
import { AppDomain } from '../../types/app-domain';

export async function confirmPaymentIntentConnectAccount(
  paymentIntent: PaymentIntentConfirmRequest
): Promise<PaymentResponse> {
  const { customerAuthTokenType, bffBaseUrl } = useAuthContextStore.getState();

  const baseUrl = getApiBaseUrl(
    AppDomain.PAYMENT_APP,
    customerAuthTokenType,
    bffBaseUrl
  );

  return httpClient.post(
    `${baseUrl}/confirm-payment`,
    paymentIntent,
    getHeadersByAuthTokens()
  );
}
