import { enUS, TRANSLATION_MISSING } from './en-US';

export const csCZ: typeof enUS = {
  common: {
    default: 'v\u00fdchoz\u00ed',
    endingIn: 'kon\u010d\u00ed za',
    close: 'Zav\u0159\u00edt',
    makeDefaultCard: 'Nastavit kartu jako v\u00fdchoz\u00ed',
    makeDefaultInfoHover:
      'Tato karta se automaticky stane va\u0161\u00ed v\u00fdchoz\u00ed kartou, proto\u017ee je to prvn\u00ed karta ve va\u0161\u00ed Pen\u011b\u017eence.',
    processing: 'Prob\u00edh\u00e1 zpracov\u00e1n\u00ed\u2026',
    saveCardCheck: 'Ulo\u017eit kartu pro budouc\u00ed transakce',
    saveCardSuccess:
      'Karta byla p\u0159id\u00e1na do Pen\u011b\u017eenky Ford.com',
    technicalErrorPayment:
      'Je n\u00e1m l\u00edto! Do\u0161lo k technick\u00e9mu probl\u00e9mu a va\u0161e platba nebyla zpracov\u00e1na. Zkuste to pros\u00edm znovu.',
    timeoutSorry: 'Je n\u00e1m l\u00edto\u2026',
    timeoutError:
      'M\u00e1me pr\u00e1v\u011b n\u011bjak\u00e9 technick\u00e9 probl\u00e9my.',
    timeoutTryAgain: 'Zkusit znovu',
    savePaymentMethodCheck:
      'Ulo\u017eit platebn\u00ed metodu pro budouc\u00ed transakce',
    makeDefaultPaymentMethod:
      'Nastavit jako v\u00fdchoz\u00ed platebn\u00ed metodu',
    savePaymentMethodButton: 'Ulo\u017eit zp\u016fsob platby',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Toto se automaticky stane va\u0161\u00ed v\u00fdchoz\u00ed platebn\u00ed metodou, proto\u017ee je to prvn\u00ed platebn\u00ed metoda ve va\u0161\u00ed pen\u011b\u017eence.',
    savePaymentMethodSuccess:
      'Platebn\u00ed metoda byla p\u0159id\u00e1na do Pen\u011b\u017eenky.'
  },
  checkout: {
    payButton: 'Zaplatit {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      'Je n\u00e1m l\u00edto! Do\u0161lo k technick\u00e9mu probl\u00e9mu a va\u0161e platba nebyla zpracov\u00e1na. Obnovte pros\u00edm str\u00e1nku a zkuste to znovu.',
    technicalErrorPaymentDetachFail:
      'Je n\u00e1m l\u00edto! Do\u0161lo k technick\u00e9mu probl\u00e9mu a va\u0161e platba nebyla zpracov\u00e1na. Va\u0161e karta byla ulo\u017eena do Pen\u011b\u017eenky. Zkuste to pros\u00edm znovu.',
    tosTextBefore:
      'Kliknut\u00edm na n\u00ed\u017ee uveden\u00e9 tla\u010d\u00edtko \u201eZaplatit\u201c vyjad\u0159ujete souhlas s ',
    tosTextBeforeForFordPayGuestId:
      'Kliknut\u00edm na n\u00ed\u017ee uveden\u00e9 tla\u010d\u00edtko {{paymentButtonText}} vyjad\u0159ujete souhlas s ',
    tosLinkText:
      'Platebn\u00edmi podm\u00ednkami a Z\u00e1sadami ochrany osobn\u00edch \u00fadaj\u016f',
    tosTextAfter: '.',
    useNewCard: 'Pou\u017e\u00edt novou kartu',
    useNewCardDropdownText: 'P\u0159idat novou kartu',
    useNewCardTextBefore: 'Zaplatit ulo\u017eenou kartou nebo ',
    useNewCardLinkText: 'pou\u017e\u00edt novou platebn\u00ed metodu',
    useNewCardTextAfter: '.',
    cardSelect: 'Vybrat kreditn\u00ed kartu',
    checkoutFaster: 'Pla\u0165te rychleji s FordPay.',
    checkout: 'Platba',
    nameOnCard: 'Jm\u00e9no na kart\u011b',
    fullName: 'Jm\u00e9no a p\u0159\u00edjmen\u00ed',
    cardNumber: '\u010c\u00edslo karty',
    expirationDate: 'Platnost do',
    securityCode: 'CVC',
    invalidCCNMessage: 'Zadan\u00e9 \u010d\u00edslo karty je neplatn\u00e9.',
    invalidCVVMessage: 'Zadan\u00fd CVC je neplatn\u00fd.',
    invalidExpDateMessage: 'Zadan\u00e1 platnost karty je neplatn\u00e1.',
    emptyCCNMessage: 'Pole \u010c\u00edslo karty je pr\u00e1zdn\u00e9.',
    emptyCVVMessage: 'Pole CVC je pr\u00e1zdn\u00e9.',
    emptyExpDateMessage: 'Pole Platnost karty je pr\u00e1zdn\u00e9.',
    unsupportedCardMessage:
      'Zadan\u00e9 \u010d\u00edslo karty nen\u00ed podporov\u00e1no. Zadejte pros\u00edm VISA, MasterCard nebo AMEX.',
    paymentNotProcessed: 'Platba nemohla b\u00fdt zpracov\u00e1na',
    redirectStatusFailure:
      'Platba nemohla b\u00fdt zpracov\u00e1na. Zkuste to pros\u00edm znovu',
    continue: 'Pokra\u010dovat {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Kliknut\u00edm na n\u00ed\u017ee uveden\u00e9 tla\u010d\u00edtko \u201ePokra\u010dovat \u201c vyjad\u0159ujete souhlas s ',
    authCheckoutStatement:
      'Platit pomoc\u00ed ulo\u017een\u00e9 karty, pou\u017e\u00edt novou platebn\u00ed metodu nebo pou\u017e\u00edt Pay By Bank.',
    useNewCardTextBeforePayByBank:
      'Platit pomoc\u00ed ulo\u017een\u00e9 karty, ',
    usePayByBank: ', nebo pou\u017e\u00edt ',
    payByBankLinkText: 'Pay By Bank',
    payByBankHeader: 'Pou\u017eijt Pay By Bank',
    use: 'Pou\u017e\u00edt ',
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment: 'Pou\u017e\u00edt novou platebn\u00ed metodu',
    useNewPaymentDropdownText: 'pou\u017e\u00edt novou platebn\u00ed metodu',
    useNewPaymentTextBefore:
      'Zkontrolujte pomoc\u00ed ulo\u017een\u00e9 platebn\u00ed metody nebo ',
    useNewPaymentLinkText: 'pou\u017e\u00edt novou platebn\u00ed metodu',
    useNewPaymentTextBeforePayByBank:
      'Zkontrolujte pomoc\u00ed ulo\u017een\u00e9 platebn\u00ed metody, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'V t\u00e9to chv\u00edli v pen\u011b\u017eence nem\u00e1te \u017e\u00e1dn\u00e9 platby. Za\u010dn\u011bte t\u00edm, \u017ee p\u0159id\u00e1te prvn\u00ed platebn\u00ed metodu do va\u0161\u00ed pen\u011b\u017eenky.',
    addPaymentMethod: 'P\u0159idat platebn\u00ed metodu',
    makeDefault: 'Nastavit jako v\u00fdchoz\u00ed',
    changeDefaultPMSuccess:
      'Va\u0161e v\u00fdchoz\u00ed platebn\u00ed metoda se zm\u011bnila.',
    makeDefaultSuccessPM:
      'Metoda platby p\u0159id\u00e1na jako v\u00fdchoz\u00ed.',
    makeDefaultErrorPM:
      'Chyba p\u0159i nastaven\u00ed metody platby jako v\u00fdchoz\u00ed.',
    removePMSuccess:
      'Metoda platby odebr\u00e1na z Ford.com Pen\u011b\u017eenky',
    removePMError: 'Chyba p\u0159i odebr\u00e1n\u00ed metody platby.',
    removePM: 'Odebrat metodu platby',
    removePMQuestion: 'Odebrat metodu platby?',
    removePMText:
      'Jste si jisti, \u017ee chcete odebrat metodu platby {{cardNumber}} z va\u0161\u00ed Pen\u011b\u017eenky? V\u0161echna aktivn\u00ed p\u0159edplatn\u00e1 spojen\u00e1 s touto platebn\u00ed metodou sel\u017eou, pokud bude platebn\u00ed metoda odstran\u011bna.',
    defaultCard: 'V\u00fdchoz\u00ed karta',
    defaultPM: 'V\u00fdchoz\u00ed metoda platby',
    defaultCardQuestion: 'V\u00fdchoz\u00ed karta?',
    defaultPMQuestion: 'V\u00fdchoz\u00ed metoda platby?',
    defaultCardText:
      'Opravdu chcete zm\u011bnit v\u00fdchoz\u00ed kartu na {{cardNumber}}?',
    defaultPMText:
      'Jste si jisti, \u017ee chcete zm\u011bnit va\u0161i v\u00fdchoz\u00ed metodu platby na {{cardNumber}}?\u00a0',
    navigationLabel: 'Navigace pro spr\u00e1vu \u00fa\u010dtu',
    removeDefaultPMSuccess:
      'V\u00fdchoz\u00ed metoda platby odebr\u00e1na z Ford.com Pen\u011b\u017eenky',
    removeDefaultCardError:
      'P\u0159i odeb\u00edr\u00e1n\u00ed v\u00fdchoz\u00ed karty do\u0161lo k chyb\u011b.',
    removeDefaultPMError:
      'Chyba p\u0159i odebr\u00e1n\u00ed v\u00fdchoz\u00ed metody platby.',
    removeDefaultCard: 'Odebrat v\u00fdchoz\u00ed kartu',
    removeDefaultPM: 'Odebrat va\u0161i v\u00fdchoz\u00ed metodu platby?',
    removeDefaultPMText:
      'Metoda platby {{cardNumber}} je va\u0161\u00ed v\u00fdchoz\u00ed metodou platby. Pros\u00edm, vyberte novou v\u00fdchoz\u00ed metodu platby n\u00ed\u017ee. V\u0161echna aktivn\u00ed p\u0159edplatn\u00e1 spojen\u00e1 s touto platebn\u00ed metodou sel\u017eou, pokud je platebn\u00ed metoda odstran\u011bna.',
    removeDefaultPMButton:
      'Odebrat metodu platby a aktualizovat v\u00fdchoz\u00ed',
    removeDefaultSelectPM: 'Vyberte novou v\u00fdchoz\u00ed metodu platby',
    title: 'Pen\u011b\u017eenka',
    expiration: 'Platn.\u00a0{{date}}',
    expires: 'Platnost do {{date}}.',
    stripeDefaultError:
      'Do\u0161lo k technick\u00e9mu probl\u00e9mu. Zkuste to pros\u00edm znovu.\u00a0',
    paymentMethod: 'Platebn\u00ed metoda',
    tosTextBefore:
      'Kliknut\u00edm na tla\u010d\u00edtko \u201eUlo\u017eit kartu\u201c n\u00ed\u017ee vyjad\u0159ujete souhlas s ',
    tosTextBeforePM:
      'Kliknut\u00edm na tla\u010d\u00edtko \u201eUlo\u017eit metodu platby\u201c n\u00ed\u017ee souhlas\u00edte s',
    tosLinkText:
      'Platebn\u00edmi podm\u00ednkami a Z\u00e1sadami ochrany osobn\u00edch \u00fadaj\u016f',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'V\u0161e',
    amount: '\u010c\u00e1stka',
    date: 'Datum',
    description: 'Popis',
    displayingAll: 'Zobrazeny v\u0161echny transakce.',
    displayingDays:
      'Zobrazeny transakce za posledn\u00edch {{numberOfDays}} dn\u00ed.',
    displayingYear: 'Zobrazeny transakce od roku {{year}}.',
    filter: 'Posledn\u00edch {{numberOfDays}} dn\u00ed',
    loadAll: 'Na\u010d\u00edst v\u0161e',
    loadMore: 'Na\u010d\u00edst dal\u0161\u00edch {{x}}',
    noTransactions: '\u017d\u00e1dn\u00e9 transakce ke zobrazen\u00ed.',
    paymentMethod: 'Platebn\u00ed metoda',
    refund: 'Vr\u00e1cen\u00ed pen\u011bz',
    searchBar: 'Hledat dle popisu',
    timeFilter:
      'Vyberte \u010dasov\u00e9 rozmez\u00ed pro historii transakc\u00ed',
    title: 'Transakce',
    pending: '\u010dekaj\u00edc\u00ed'
  },
  mit: {
    confirmPurchase: 'Potvr\u010fte n\u00e1kup',
    paymentSuccessful: 'Platba prob\u011bhla \u00fasp\u011b\u0161n\u011b',
    saveCardAlert:
      'Chcete-li pou\u017e\u00edt tuto kartu pro budouc\u00ed platby, mus\u00ed b\u00fdt ulo\u017eena do va\u0161\u00ed Ford.com pen\u011b\u017eenky.',
    title: 'V\u00fdb\u011br platebn\u00ed metody',
    tosTextBefore:
      'Kliknut\u00edm na n\u00ed\u017ee uveden\u00e9 tla\u010d\u00edtko {{paymentButtonText}} vyjad\u0159ujete souhlas s ',
    tosLinkText:
      'Platebn\u00edmi podm\u00ednkami a Z\u00e1sadami ochrany osobn\u00edch \u00fadaj\u016f',
    tosTextAfter: '.',
    useNewCard: 'Pou\u017e\u00edt novou kartu',
    useNewCardDropDownItem: 'Pou\u017e\u00edt novou kartu',
    useNewCardTextBefore: 'Vybrat ulo\u017eenou kartu nebo ',
    useNewCardLinkText: 'pou\u017e\u00edt novou kartu',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Pou\u017e\u00edt novou platebn\u00ed metodu ',
    useNewPaymentDropdownText: 'pou\u017e\u00edt novou platebn\u00ed metodu',
    useNewPaymentTextBefore:
      'Zkontrolujte pomoc\u00ed ulo\u017een\u00e9 platebn\u00ed metody nebo ',
    useNewPaymentLinkText: 'pou\u017e\u00edt novou platebn\u00ed metodu',
    successful: '\u00dasp\u011b\u0161n\u00fd',
    savePaymentMethodAlert:
      'Chcete-li tuto platebn\u00ed metodu pou\u017e\u00edvat pro budouc\u00ed platby, mus\u00edte ji ulo\u017eit do pen\u011b\u017eenky.'
  }
};
