import {
  Stripe,
  StripeElements,
  StripeExpressCheckoutElementConfirmEvent
} from '@stripe/stripe-js';
import { useContext } from 'react';
import {
  CHECKOUT_ELEMENT,
  StripeCheckoutContext
} from '@fpc/reactutils/checkoutContextProvider';
import {
  AuthPaymentRequest,
  makeAuthenticatedPayment
} from '@fpc/api/paymentapp/MakeAuthenticatedPayment';
import { ErrorCondition } from '@fpc/common/ErrorHandler';
import { dispatchAuthorizationFailureEvent } from '@fpc/utils/dispatchEvent';
import { buildManualRedirectUrl } from '@fpc/utils/buildManualRedirectUrl';
import {
  ExpressCheckoutButton,
  ExpressCheckoutOptions
} from '@fpc/common/components/ExpressCheckoutButton';
import { isLocal } from '../../flags';
import { PAYMENT_INITIATOR } from '@fpc/common';
import { StripeTransactionDetails } from '@fpc/common/transactionInterfaces';

interface AuthPaymentRequestProps {
  stripeConnect: Stripe;
  elements: StripeElements;
  fordCustomerId: string;
  isReadyHandler: (isReady: boolean) => void;
}

export function AuthPaymentRequestButton(props: AuthPaymentRequestProps) {
  const {
    transaction,
    tokens,
    stripeCustomerId,
    isPreAuth,
    redirectUrl,
    errorDispatch,
    bffBaseUrl
  } = useContext(StripeCheckoutContext);

  const expressCheckoutOptions: ExpressCheckoutOptions = {
    amount: transaction.amount,
    currency: transaction.currency,
    googlePay: transaction.alternativePaymentMethodTypes.includes('GOOGLE_PAY'),
    applePay: transaction.alternativePaymentMethodTypes.includes('APPLE_PAY'),
    klarna: transaction.alternativePaymentMethodTypes.includes('KLARNA')
  };

  function handleErrorDispatch(err: any) {
    if (err.unrecoverable) {
      errorDispatch(ErrorCondition.Unrecoverable);
      dispatchAuthorizationFailureEvent(CHECKOUT_ELEMENT);
    }
    console.warn(
      'There was an error trying to make payment with wallet pay',
      err
    );
  }

  const onExpressPaymentCallback = async (
    event: StripeExpressCheckoutElementConfirmEvent,
    elements: StripeElements
  ): Promise<void> => {
    props.isReadyHandler(false);

    let paymentMethodId = null;
    let paymentMethodType = '';
    let confirmationId;
    let redirectionUrl;
    let err;

    // @ts-ignore
    const isKlarna = event.expressPaymentType === 'klarna';

    if (!isKlarna) {
      const { paymentMethod, error } =
        await props.stripeConnect.createPaymentMethod({ elements });
      paymentMethodId = paymentMethod?.id ?? null;
      paymentMethodType = paymentMethod?.type ?? '';
      err = error;
    } else {
      const { confirmationToken, error } =
        await props.stripeConnect.createConfirmationToken({ elements });
      confirmationId = confirmationToken?.id;
      paymentMethodType = event.expressPaymentType;
      redirectionUrl = redirectUrl;
      err = error;
    }

    if (paymentMethodId || confirmationId) {
      const authPaymentRequest: AuthPaymentRequest = {
        checkoutTokens: tokens,
        stripeCustomerId: stripeCustomerId!,
        paymentMethodId: paymentMethodId,
        paymentMethodType: paymentMethodType,
        isSavePaymentMethod: false,
        setPaymentMethodAsDefault: false,
        fordCustomerId: props.fordCustomerId,
        paymentInitiator: PAYMENT_INITIATOR.CUSTOMER,
        isPreAuth,
        bffBaseUrl: bffBaseUrl ?? '',
        isConnectFlow: true,
        confirmationId: confirmationId,
        redirectUrl: redirectionUrl ?? null
      };
      makeAuthenticatedPayment(authPaymentRequest)
        .then((confirmResult) => {
          window.location.href = buildManualRedirectUrl(
            redirectUrl,
            (transaction as StripeTransactionDetails).merchantAccountId,
            confirmResult.paymentIntentClientSecret,
            paymentMethodType
          );
        })
        .catch((err) => {
          handleErrorDispatch(err);
          event.paymentFailed({ reason: 'fail' });
          props.isReadyHandler(true);
        });
    } else {
      if (isLocal) {
        console.warn(err);
      }
      event.paymentFailed({ reason: 'fail' });
      props.isReadyHandler(true);
    }
  };

  return (
    <ExpressCheckoutButton
      stripe={props.stripeConnect}
      options={expressCheckoutOptions}
      onPaymentConfirmCallback={onExpressPaymentCallback}
      finishedLoadingHandler={props.isReadyHandler}
      isGuestMit={false}
    />
  );
}
