import { Currency } from '@fpc/common/format';
import { Psp } from '@fpc/common/Psp';
import {
  PayuCurrencies,
  TwocTwopCurrencies
} from '@fpc/reactutils/checkoutContextProvider';
import { MultiMerchantStripeTransactionDetails } from '@fpc/checkout/features/multi-merchant/types/PaymentInfoToken';

export interface TransactionDetails {
  currency: Currency;
  amount: number;
  psp: string;
}

export interface StripeTransactionDetails extends TransactionDetails {
  merchantAccountId: string;
  merchantCountry: string;
  paymentMethodTypes: string[];
  paymentMethodTypesExcluded?: string[];
  alternativePaymentMethodTypes: string[];
  recurringPaymentRequest?: RecurringPaymentRequest;
  blockedCardBrands?: string[];
  consumerId?: string;
  orderId?: string;
  description?: string;
  metadata?: Map<string, string>;
}

export interface PayUTransactionDetails extends TransactionDetails {
  pspAuthKey: PspAuthKey;
  merchantAccountId: string;
}
export interface TwocTwopTransactionDetails extends TransactionDetails {
  merchantAccountId: string;
  paymentMethodTypes: string[];
  alternativePaymentMethodTypes: string[];
}

export interface PspAuthKey {
  publicKey: string;
}

export enum IntervalUnit {
  Year = 'year',
  Month = 'month',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute'
}

export interface RecurringPaymentRequest {
  paymentDescription: string;
  managementURL: string;
  regularBilling: RegularBilling;
}

export interface RegularBilling {
  amount: number;
  label: string;
  startDate: Date;
  endDate: Date;
  intervalUnit: IntervalUnit;
  intervalCount: number;
}

export function isStripeTransaction(
  input: TransactionDetails
): input is StripeTransactionDetails {
  return (
    ((input as StripeTransactionDetails).merchantAccountId != null &&
      (input as StripeTransactionDetails).merchantCountry != null) ||
    ((input as MultiMerchantStripeTransactionDetails) != null &&
      'version' in (input as MultiMerchantStripeTransactionDetails))
  );
}

export function isPayUTransaction(
  input: TransactionDetails
): input is PayUTransactionDetails {
  const transactionDetails: PayUTransactionDetails =
    input as PayUTransactionDetails;
  return (
    transactionDetails.psp != null &&
    transactionDetails.psp.toUpperCase() === Psp.Payu &&
    PayuCurrencies.includes(transactionDetails.currency.toUpperCase())
  );
}
export function isTwocTwopTransaction(
  input: TransactionDetails
): input is TwocTwopTransactionDetails {
  const transactionDetails: TwocTwopTransactionDetails =
    input as TwocTwopTransactionDetails;
  return (
    transactionDetails.psp != null &&
    transactionDetails.psp.toUpperCase() === Psp.TwocTwop &&
    TwocTwopCurrencies.includes(transactionDetails.currency.toUpperCase())
  );
}

export type PaymentObjectType = 'JWT' | 'DIGITALSIGN';
