import React, { useContext } from 'react';
import { LoadingSpinner, UnstyledButton } from '@fpc/common';
import WalletPaymentInput from './WalletPaymentInput';
import { PAYMENT_METHODS } from '@fpc/checkout/twoctwop/AlternatePaymentMethodConfig';
import { DEFAULT_BUTTON_STYLES } from '@fpc/common/components/UnstyledButton';
import { PaymentMethodType } from '../../../types/payment';
import { TwocTwopCheckoutContext } from '@fpc/reactutils/checkoutContextProvider';

interface AlternativePaymentMethodsProps {
  alternativePaymentMethodTypes: string[];
  handleWalletButtonClick: (walletType: PaymentMethodType) => void;
}

export const AlternatePaymentMethods: React.FC<
  AlternativePaymentMethodsProps
> = ({ alternativePaymentMethodTypes, handleWalletButtonClick }) => {
  const { paymentProcessing, updatePaymentProcessing } = useContext(
    TwocTwopCheckoutContext
  );

  const availablePaymentMethods = alternativePaymentMethodTypes
    .map((type) => PAYMENT_METHODS[type.toLowerCase()])
    .filter(Boolean);

  const processAvailablePaymentMethodOnClick = (
    methodType: PaymentMethodType
  ) => {
    updatePaymentProcessing(methodType.toString());
    handleWalletButtonClick(methodType);
  };

  if (availablePaymentMethods.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1rem',
        flexWrap: 'wrap'
      }}
    >
      {availablePaymentMethods.map((method) => (
        <UnstyledButton
          key={method.type}
          data-testid={`wallet-payment-${method.type}`}
          onClick={() => processAvailablePaymentMethodOnClick(method.type)}
          style={{
            ...DEFAULT_BUTTON_STYLES,
            backgroundColor: method.backgroundColor,
            flex: '1 1 40%'
          }}
          disabled={paymentProcessing !== '' ? true : false}
        >
          {paymentProcessing === method.type.toString() ? (
            <>
              {' '}
              <WalletPaymentInput walletName={method.type} />{' '}
              <LoadingSpinner
                size={'1.7em'}
                style={{
                  marginLeft: '0.75rem',
                  paddingTop: '0.25rem'
                }}
              />{' '}
            </>
          ) : (
            <WalletPaymentInput walletName={method.type} />
          )}
        </UnstyledButton>
      ))}
    </div>
  );
};
