import { create } from 'zustand/index';
import { Currency } from '@fpc/common';
import { ComponentName } from '../types/component';

export type PaymentContextStore = {
  currencyCode: Currency;
  elementName: ComponentName;
};

export const usePaymentContextStore = create<PaymentContextStore>(
  (set, get) => ({
    currencyCode: 'USD',
    elementName: '' as ComponentName
  })
);

export default usePaymentContextStore;
