import { enUS, TRANSLATION_MISSING } from './en-US';

export const esUS: typeof enUS = {
  common: {
    default: 'predeterminada',
    endingIn: 'que termina en',
    close: 'Cerrar',
    makeDefaultCard: 'Establecer tarjeta como predeterminada',
    makeDefaultInfoHover:
      'Esta se convertir\u00e1 autom\u00e1ticamente en tu tarjeta predeterminada, porque es la primera tarjeta en tu cartera.',
    processing: 'Procesando...',
    saveCardCheck: 'Guardar tarjeta para transacciones futuras',
    saveCardSuccess: 'Tarjeta agregada a la cartera de Ford.com.',
    technicalErrorPayment:
      '\u00a1Lo sentimos! Se produjo un problema t\u00e9cnico y no se proces\u00f3 tu pago. Int\u00e9ntalo de nuevo',
    timeoutSorry: 'Lo sentimos...',
    timeoutError:
      'Estamos presentando dificultades t\u00e9cnicas en este momento.',
    timeoutTryAgain: 'Int\u00e9ntalo de nuevo',
    savePaymentMethodCheck:
      'Guarda el m\u00e9todo de pago para transacciones futuras',
    makeDefaultPaymentMethod: 'Establecer m\u00e9todo de pago predeterminado',
    savePaymentMethodButton: 'Guardar m\u00e9todo de pago',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      'Este se convertir\u00e1 autom\u00e1ticamente en su m\u00e9todo de pago predeterminado, porque es el primer m\u00e9todo de pago en su billetera.',
    savePaymentMethodSuccess: 'El m\u00e9todo de pago se ha agregado a Wallet.'
  },
  checkout: {
    payButton: 'Pagar {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      '\u00a1Lo sentimos! Se produjo un problema t\u00e9cnico y tu pago no se proces\u00f3. Actualiza la p\u00e1gina e int\u00e9ntalo de nuevo.',
    technicalErrorPaymentDetachFail:
      '\u00a1Lo sentimos! Se produjo un problema t\u00e9cnico y no se proces\u00f3 tu pago. Tu tarjeta se guard\u00f3 en tu cartera. Int\u00e9ntalo de nuevo',
    tosTextBefore:
      'Al hacer clic en el bot\u00f3n \u201cPagar\u201d a continuaci\u00f3n, aceptas los ',
    tosTextBeforeForFordPayGuestId:
      'Al hacer clic en el bot\u00f3n {{paymentButtonText}} a continuaci\u00f3n, aceptas los ',
    tosLinkText: 'T\u00e9rminos y condiciones de pago',
    tosTextAfter: '.',
    useNewCard: 'Usar una nueva tarjeta',
    useNewCardDropdownText: 'Agregar nueva tarjeta',
    useNewCardTextBefore: 'Pagar con el m\u00e9todo de pago guardado o ',
    useNewCardLinkText: 'usar nuevo m\u00e9todo de pago',
    useNewCardTextAfter: '.',
    cardSelect: 'Seleccionar tarjeta de cr\u00e9dito',
    checkoutFaster: 'Pagar m\u00e1s r\u00e1pido con FordPay.',
    checkout: 'Pagar',
    nameOnCard: 'Nombre que figura en la tarjeta',
    fullName: 'Nombre completo',
    cardNumber: 'N\u00famero de tarjeta',
    expirationDate: 'Vencimiento Fecha',
    securityCode: 'C\u00f3digo de seguridad',
    invalidCCNMessage: 'Tu n\u00famero de tarjeta no es v\u00e1lido.',
    invalidCVVMessage: 'Tu c\u00f3digo de seguridad no es v\u00e1lido.',
    invalidExpDateMessage: 'La fecha de vencimiento no es v\u00e1lida.',
    emptyCCNMessage: 'Tu n\u00famero de tarjeta est\u00e1 vac\u00edo.',
    emptyCVVMessage: 'Tu c\u00f3digo de seguridad est\u00e1 vac\u00edo.',
    emptyExpDateMessage: 'La fecha de vencimiento est\u00e1 vac\u00eda.',
    unsupportedCardMessage:
      'Tu n\u00famero de tarjeta no es compatible. Ingresa VISA, MasterCard o AMEX.',
    paymentNotProcessed: 'No se pudo procesar tu pago',
    redirectStatusFailure:
      'No se pudo procesar tu pago. Int\u00e9ntalo de nuevo\u00a0',
    continue: 'Continuar {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      'Al hacer clic en el bot\u00f3n \u00abContinuar\u00bb que se muestra a continuaci\u00f3n, aceptas los ',
    authCheckoutStatement:
      'Pague con la tarjeta guardada, use un nuevo m\u00e9todo de pago o use Pay By Bank.',
    useNewCardTextBeforePayByBank: 'Pague con la tarjeta guardada, ',
    usePayByBank: ' o use ',
    payByBankLinkText: 'Pay By Bank',
    payByBankHeader: 'Usar Pay By Bank',
    use: 'Usar ',
    achOnlyInfoTitle: 'S\u00f3lo se aceptan pagos bancarios',
    achOnlyInfoMsg:
      'Las tarjetas en su billetera no se pueden usar para esta compra.',
    useNewPayment: 'Usar nuevo m\u00e9todo de pago',
    useNewPaymentDropdownText: 'usar nuevo m\u00e9todo de pago',
    useNewPaymentTextBefore: 'Pague con el m\u00e9todo de pago guardado o ',
    useNewPaymentLinkText: 'usar nuevo m\u00e9todo de pago',
    useNewPaymentTextBeforePayByBank:
      'Pague con el m\u00e9todo de pago guardado, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText:
      'Actualmente no tienes ning\u00fan m\u00e9todo de pago en tu billetera. Empieza a\u00f1adiendo el primer m\u00e9todo de pago a tu billetera.',
    addPaymentMethod: 'Agregar m\u00e9todo de pago',
    makeDefault: 'Establecer como predeterminada',
    changeDefaultPMSuccess:
      'Su m\u00e9todo de pago predeterminado ha cambiado.',
    makeDefaultSuccessPM:
      'M\u00e9todo de pago a\u00f1adido como predeterminado.',
    makeDefaultErrorPM:
      'Error al establecer el m\u00e9todo de pago como predeterminado.',
    removePMSuccess: 'M\u00e9todo de pago eliminado de la Cartera de Ford.com',
    removePMError: 'M\u00e9todo de pago eliminado de la Cartera de Ford.com',
    removePM: 'Eliminar m\u00e9todo de pago',
    removePMQuestion: '\u00bfEliminar m\u00e9todo de pago?',
    removePMText:
      '\u00bfEst\u00e1s seguro de que quieres eliminar el m\u00e9todo de pago {{cardNumber}} de tu Cartera? Todas las suscripciones activas asociadas con este m\u00e9todo de pago fallar\u00e1n si se elimina el m\u00e9todo de pago.',
    defaultCard: 'Tarjeta predeterminada',
    defaultPM: 'M\u00e9todo de pago predeterminado',
    defaultCardQuestion: '\u00bfTarjeta predeterminada?',
    defaultPMQuestion: '\u00bfM\u00e9todo de pago predeterminado?',
    defaultCardText:
      '\u00bfEst\u00e1s seguro de que quieres cambiar la tarjeta predeterminada a {{cardNumber}}?',
    defaultPMText:
      '\u00bfEst\u00e1s seguro de que quieres cambiar tu m\u00e9todo de pago predeterminado a {{cardNumber}}?',
    navigationLabel: 'Navegaci\u00f3n en Gesti\u00f3n de cuentas',
    removeDefaultPMSuccess:
      'M\u00e9todo de pago predeterminado eliminado de la Cartera de Ford.com',
    removeDefaultCardError: 'Error al eliminar la tarjeta predeterminada.',
    removeDefaultPMError:
      'M\u00e9todo de pago predeterminado eliminado de la Cartera de Ford.com',
    removeDefaultCard: 'Eliminar tu tarjeta predeterminada',
    removeDefaultPM: '\u00bfEliminar tu m\u00e9todo de pago predeterminado?',
    removeDefaultPMText:
      'El m\u00e9todo de pago {{cardNumber}} es su m\u00e9todo de pago predeterminado. Por favor, elija un nuevo m\u00e9todo de pago predeterminado a continuaci\u00f3n. Todas las suscripciones activas asociadas con este m\u00e9todo de pago fallar\u00e1n si se elimina el m\u00e9todo de pago.',
    removeDefaultPMButton:
      'Eliminar m\u00e9todo de pago y actualizar predeterminado',
    removeDefaultSelectPM:
      'Seleccionar nuevo m\u00e9todo de pago predeterminado',
    title: 'Cartera',
    expiration: 'Cad.\u00a0{{date}}',
    expires: 'Vence el {{date}}',
    stripeDefaultError:
      'Se produjo un problema t\u00e9cnico. Int\u00e9ntalo de nuevo\u00a0',
    paymentMethod: 'M\u00e9todo de pago',
    tosTextBefore:
      'Al hacer clic en el bot\u00f3n \u201cGuardar tarjeta\u201d a continuaci\u00f3n, aceptas los ',
    tosTextBeforePM:
      'Al hacer clic en el bot\u00f3n "Guardar m\u00e9todo de pago" a continuaci\u00f3n, aceptas los',
    tosLinkText: 'T\u00e9rminos y condiciones de pago',
    tosTextAfter: '.'
  },
  transaction: {
    all: 'Todos',
    amount: 'Cantidad',
    date: 'Fecha',
    description: 'Descripci\u00f3n',
    displayingAll: 'Visualizaci\u00f3n de todas las transacciones.',
    displayingDays:
      'Visualizaci\u00f3n de las transacciones de los \u00faltimos {{numberOfDays}} d\u00edas.',
    displayingYear:
      'Visualizaci\u00f3n de las transacciones a partir de {{year}}.',
    filter: '\u00daltimos {{numberOfDays}} d\u00edas',
    loadAll: 'Cargar todo',
    loadMore: 'Cargar {{x}} m\u00e1s',
    noTransactions: 'No hay transacciones para mostrar.',
    paymentMethod: 'M\u00e9todo de pago',
    refund: 'Reembolso',
    searchBar: 'Buscar por descripci\u00f3n',
    timeFilter: 'Seleccionar per\u00edodo para el historial de transacciones',
    title: 'Transacciones',
    pending: 'Pendiente'
  },
  mit: {
    confirmPurchase: 'Confirmar compra',
    paymentSuccessful: 'Pago exitoso',
    saveCardAlert:
      'Para usar esta tarjeta en pagos futuros, debes guardarla en tu cartera de Ford.com.',
    title: 'Selecci\u00f3n del m\u00e9todo de pago',
    tosTextBefore:
      'Al hacer clic en el bot\u00f3n {{paymentButtonText}} a continuaci\u00f3n, aceptas los ',
    tosLinkText: 'T\u00e9rminos y condiciones de pago',
    tosTextAfter: '.',
    useNewCard: 'Usar una nueva tarjeta',
    useNewCardDropDownItem: 'Usar una nueva tarjeta',
    useNewCardTextBefore: 'Seleccionar la tarjeta guardada o ',
    useNewCardLinkText: 'usar una nueva tarjeta',
    useNewCardTextAfter: '.',
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment: 'Usar nuevo m\u00e9todo de pago',
    useNewPaymentDropdownText: 'usar nuevo m\u00e9todo de pago',
    useNewPaymentTextBefore: 'Pague con el m\u00e9todo de pago guardado o ',
    useNewPaymentLinkText: 'usar nuevo m\u00e9todo de pago',
    successful: 'Exitoso',
    savePaymentMethodAlert:
      'Para utilizar este m\u00e9todo de pago para pagos futuros, debe guardarlo en su billetera.'
  }
};
