import { ReactComponent as GooglePay } from '../icons/creditcards/googlepay.svg';
import { ReactComponent as ApplePay } from '../icons/creditcards/apple.svg';
import { ReactComponent as Gcash } from '../icons/creditcards/gcash.svg';
import { ReactComponent as PayMaya } from '../icons/creditcards/paymaya.svg';

import { CSSProperties } from 'react';

export function getWalletIcon(input: string, style?: CSSProperties) {
  switch (input.toLowerCase()) {
    case 'google_pay':
      return <GooglePay style={style} />;
    case 'apple_pay':
      return <ApplePay style={style} />;
    case 'paymaya':
      return <PayMaya style={style} />;
    case 'gcash':
      return <Gcash style={style} />;
    default:
      return <></>;
  }
}
