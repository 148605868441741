import { create } from 'zustand';
import { CustomerTokenType } from '../types/auth';

export type AuthContextState = {
  /** @deprecated -- no Azure tokens will be used in the web-components anymore */
  bearerToken: string;
  customerAuthToken: string;
  customerAuthTokenType: CustomerTokenType;
  bffBaseUrl: string | null;
};

export const useAuthContextStore = create<AuthContextState>(() => ({
  bearerToken: '',
  customerAuthToken: '',
  customerAuthTokenType: '' as CustomerTokenType,
  bffBaseUrl: null
}));
