import HttpService from '../services/HttpService';
import {
  requestMonitoringErrorInterceptor,
  requestMonitoringInterceptor,
  responseMonitoringErrorInterceptor,
  responseMonitoringInterceptor
} from '@fpc/common/monitoring/interceptor';
import { useAuthContextStore } from '../stores/auth-context.store';
import { HttpHeader, HttpHeaders } from '../types/http';
import { traceId } from '@fpc/reactutils/TraceIdContext';
import { CustomerTokenType } from '../types/auth';

const httpService = new HttpService();

httpService.addRequestInterceptor(
  requestMonitoringInterceptor,
  requestMonitoringErrorInterceptor
);
httpService.addResponseInterceptor(
  responseMonitoringInterceptor,
  responseMonitoringErrorInterceptor
);

export function getCatTokenAndAuthHeaders(): HttpHeaders {
  const authState = useAuthContextStore.getState();

  return {
    [HttpHeader.AUTH_TOKEN]: authState.customerAuthToken,
    [HttpHeader.CAT_TOKEN_TYPE]: authState.customerAuthTokenType,
    [HttpHeader.TRACEPARENT]: traceId
  };
}

export function getHeadersByAuthTokens(): HttpHeaders {
  const authState = useAuthContextStore.getState();

  let headers: HttpHeaders = {};

  if (authState.bffBaseUrl) {
    return headers;
  } else if (authState.customerAuthToken) {
    if (authState.customerAuthTokenType === CustomerTokenType.CAT) {
      headers[HttpHeader.AUTH_TOKEN] = authState.customerAuthToken;
    } else {
      headers[HttpHeader.AUTHORIZATION] =
        'Bearer ' + authState.customerAuthToken;
    }
  } else {
    headers[HttpHeader.AUTHORIZATION] = 'Bearer ' + authState.bearerToken;
  }

  return headers;
}

export default httpService;
