import { enUS, TRANSLATION_MISSING } from './en-US';

export const thTH: typeof enUS = {
  common: {
    default:
      '\u0e04\u0e48\u0e32\u0e40\u0e23\u0e34\u0e48\u0e21\u0e15\u0e49\u0e19',
    endingIn: '\u0e2a\u0e34\u0e49\u0e19\u0e2a\u0e38\u0e14\u0e43\u0e19',
    close: '\u0e1b\u0e34\u0e14',
    makeDefaultCard:
      '\u0e2a\u0e23\u0e49\u0e32\u0e07\u0e01\u0e32\u0e23\u0e4c\u0e14\u0e40\u0e23\u0e34\u0e48\u0e21\u0e15\u0e49\u0e19',
    makeDefaultInfoHover:
      '\u0e1a\u0e31\u0e15\u0e23\u0e19\u0e35\u0e49\u0e08\u0e30\u0e01\u0e25\u0e32\u0e22\u0e40\u0e1b\u0e47\u0e19\u0e1a\u0e31\u0e15\u0e23\u0e40\u0e23\u0e34\u0e48\u0e21\u0e15\u0e49\u0e19\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e42\u0e14\u0e22\u0e2d\u0e31\u0e15\u0e42\u0e19\u0e21\u0e31\u0e15\u0e34 \u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e40\u0e1b\u0e47\u0e19\u0e1a\u0e31\u0e15\u0e23\u0e43\u0e1a\u0e41\u0e23\u0e01\u0e43\u0e19\u0e01\u0e23\u0e30\u0e40\u0e1b\u0e4b\u0e32\u0e40\u0e07\u0e34\u0e19\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13',
    processing:
      '\u0e01\u0e33\u0e25\u0e31\u0e07\u0e1b\u0e23\u0e30\u0e21\u0e27\u0e25\u0e1c\u0e25 ...',
    saveCardCheck:
      '\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e1a\u0e31\u0e15\u0e23\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e01\u0e32\u0e23\u0e17\u0e33\u0e18\u0e38\u0e23\u0e01\u0e23\u0e23\u0e21\u0e43\u0e19\u0e2d\u0e19\u0e32\u0e04\u0e15',
    saveCardSuccess:
      '\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e1a\u0e31\u0e15\u0e23\u0e25\u0e07\u0e43\u0e19 Ford.com Wallet \u0e41\u0e25\u0e49\u0e27',
    technicalErrorPayment:
      '\u0e02\u0e2d\u0e2d\u0e20\u0e31\u0e22\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e08\u0e2d\u0e07\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e33\u0e40\u0e23\u0e47\u0e08 \u0e01\u0e23\u0e38\u0e13\u0e32\u0e15\u0e23\u0e27\u0e08\u0e2a\u0e2d\u0e1a\u0e01\u0e32\u0e23\u0e25\u0e07\u0e17\u0e30\u0e40\u0e1a\u0e35\u0e22\u0e19 3DS \u0e1a\u0e31\u0e15\u0e23\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07 \u0e2b\u0e32\u0e01\u0e21\u0e35\u0e02\u0e49\u0e2d\u0e2a\u0e07\u0e2a\u0e31\u0e22\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e40\u0e15\u0e34\u0e21 \u0e42\u0e1b\u0e23\u0e14\u0e15\u0e34\u0e14\u0e15\u0e48\u0e2d\u0e18\u0e19\u0e32\u0e04\u0e32\u0e23\u0e1c\u0e39\u0e49\u0e2d\u0e2d\u0e01\u0e1a\u0e31\u0e15\u0e23\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13',
    timeoutSorry: '\u0e02\u0e2d\u0e42\u0e17\u0e29 ...',
    timeoutError:
      '\u0e40\u0e23\u0e32\u0e01\u0e33\u0e25\u0e31\u0e07\u0e1b\u0e23\u0e30\u0e2a\u0e1a\u0e1b\u0e31\u0e0d\u0e2b\u0e32\u0e17\u0e32\u0e07\u0e40\u0e17\u0e04\u0e19\u0e34\u0e04\u0e43\u0e19\u0e02\u0e13\u0e30\u0e19\u0e35\u0e49',
    timeoutTryAgain:
      '\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07',
    savePaymentMethodCheck:
      '\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e01\u0e32\u0e23\u0e17\u0e33\u0e18\u0e38\u0e23\u0e01\u0e23\u0e23\u0e21\u0e43\u0e19\u0e2d\u0e19\u0e32\u0e04\u0e15',
    makeDefaultPaymentMethod:
      '\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e40\u0e23\u0e34\u0e48\u0e21\u0e15\u0e49\u0e19',
    savePaymentMethodButton:
      '\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19',
    technicalErrorDuplicatePayment: TRANSLATION_MISSING,
    makeDefaultInfoHoverPaymentMethod:
      '\u0e27\u0e34\u0e18\u0e35\u0e19\u0e35\u0e49\u0e08\u0e30\u0e40\u0e1b\u0e47\u0e19\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e40\u0e23\u0e34\u0e48\u0e21\u0e15\u0e49\u0e19\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e42\u0e14\u0e22\u0e2d\u0e31\u0e15\u0e42\u0e19\u0e21\u0e31\u0e15\u0e34 \u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e40\u0e1b\u0e47\u0e19\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e27\u0e34\u0e18\u0e35\u0e41\u0e23\u0e01\u0e43\u0e19\u0e01\u0e23\u0e30\u0e40\u0e1b\u0e4b\u0e32\u0e40\u0e07\u0e34\u0e19\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13',
    savePaymentMethodSuccess:
      '\u0e40\u0e1e\u0e34\u0e48\u0e21\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e25\u0e07\u0e43\u0e19 Wallet \u0e41\u0e25\u0e49\u0e27'
  },
  checkout: {
    payButton:
      '\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19 {{formattedCurrency}}',
    technicalErrorPaymentRefresh:
      '\u0e02\u0e2d\u0e2d\u0e20\u0e31\u0e22! \u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e40\u0e2b\u0e15\u0e38\u0e23\u0e30\u0e1a\u0e1a\u0e02\u0e31\u0e14\u0e02\u0e49\u0e2d\u0e07  \u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e33\u0e40\u0e23\u0e47\u0e08 \u0e42\u0e1b\u0e23\u0e14\u0e23\u0e35\u0e40\u0e1f\u0e23\u0e0a\u0e2b\u0e19\u0e49\u0e32\u0e40\u0e27\u0e47\u0e1a\u0e44\u0e0b\u0e04\u0e4c\u0e41\u0e25\u0e30\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07',
    technicalErrorPaymentDetachFail:
      '\u0e02\u0e2d\u0e2d\u0e20\u0e31\u0e22! \u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e08\u0e32\u0e01\u0e40\u0e2b\u0e15\u0e38\u0e23\u0e30\u0e1a\u0e1a\u0e02\u0e31\u0e14\u0e02\u0e49\u0e2d\u0e07  \u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e44\u0e21\u0e48\u0e2a\u0e33\u0e40\u0e23\u0e47\u0e08  \u0e1a\u0e31\u0e15\u0e23\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e16\u0e39\u0e01\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e44\u0e27\u0e49\u0e43\u0e19\u0e27\u0e2d\u0e25\u0e40\u0e25\u0e47\u0e17\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13 \u0e42\u0e1b\u0e23\u0e14\u0e23\u0e35\u0e40\u0e1f\u0e23\u0e0a\u0e2b\u0e19\u0e49\u0e32\u0e40\u0e27\u0e47\u0e1a\u0e44\u0e0b\u0e04\u0e4c\u0e41\u0e25\u0e30\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07',
    tosTextBefore:
      "\u0e04\u0e25\u0e34\u0e01\u0e1b\u0e38\u0e48\u0e21 '\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19' \u0e14\u0e49\u0e32\u0e19\u0e25\u0e48\u0e32\u0e07 \u0e04\u0e38\u0e13\u0e15\u0e01\u0e25\u0e07\u0e17\u0e35\u0e48\u0e08\u0e30 ",
    tosTextBeforeForFordPayGuestId: TRANSLATION_MISSING,
    tosLinkText:
      '\u0e02\u0e49\u0e2d\u0e01\u0e33\u0e2b\u0e19\u0e14\u0e41\u0e25\u0e30\u0e40\u0e07\u0e37\u0e48\u0e2d\u0e19\u0e44\u0e02\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19',
    tosTextAfter: '.',
    useNewCard:
      '\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e43\u0e2b\u0e21\u0e48',
    useNewCardDropdownText:
      '\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e43\u0e2b\u0e21\u0e48',
    useNewCardTextBefore:
      '\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e14\u0e49\u0e27\u0e22\u0e1a\u0e31\u0e15\u0e23\u0e17\u0e35\u0e48\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e44\u0e27\u0e49 \u0e2b\u0e23\u0e37\u0e2d',
    useNewCardLinkText:
      '\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e43\u0e2b\u0e21\u0e48',
    useNewCardTextAfter: '.',
    cardSelect:
      '\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1a\u0e31\u0e15\u0e23\u0e40\u0e04\u0e23\u0e14\u0e34\u0e15\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13',
    checkoutFaster:
      '\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e23\u0e27\u0e14\u0e40\u0e23\u0e47\u0e27\u0e14\u0e49\u0e27\u0e22\u0e1f\u0e2d\u0e23\u0e4c\u0e14\u0e40\u0e1e\u0e22\u0e4c',
    checkout: '\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19',
    nameOnCard:
      '\u0e0a\u0e37\u0e48\u0e2d\u0e1c\u0e39\u0e49\u0e16\u0e37\u0e2d\u0e1a\u0e31\u0e15\u0e23',
    fullName:
      '\u0e0a\u0e37\u0e48\u0e2d-\u0e19\u0e32\u0e21\u0e2a\u0e01\u0e38\u0e25',
    cardNumber:
      '\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e25\u0e02\u0e1a\u0e31\u0e15\u0e23',
    expirationDate:
      '\u0e27\u0e31\u0e19\u0e2b\u0e21\u0e14\u0e2d\u0e32\u0e22\u0e38',
    securityCode: 'CVV',
    invalidCCNMessage:
      '\u0e02\u0e2d\u0e2d\u0e20\u0e31\u0e22 \u0e17\u0e48\u0e32\u0e19\u0e01\u0e23\u0e2d\u0e01\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e25\u0e02\u0e1a\u0e31\u0e15\u0e23\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07',
    invalidCVVMessage:
      '\u0e02\u0e2d\u0e2d\u0e20\u0e31\u0e22 \u0e17\u0e48\u0e32\u0e19\u0e01\u0e23\u0e2d\u0e01\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e25\u0e02 CVV \u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07',
    invalidExpDateMessage:
      '\u0e02\u0e2d\u0e2d\u0e20\u0e31\u0e22 \u0e17\u0e48\u0e32\u0e19\u0e01\u0e23\u0e2d\u0e01\u0e27\u0e31\u0e19\u0e2b\u0e21\u0e14\u0e2d\u0e32\u0e22\u0e38\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07',
    emptyCCNMessage:
      '\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e25\u0e02\u0e1a\u0e31\u0e15\u0e23',
    emptyCVVMessage:
      '\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e40\u0e25\u0e02\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e25\u0e02 CVV',
    emptyExpDateMessage:
      '\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e27\u0e31\u0e19\u0e2b\u0e21\u0e14\u0e2d\u0e32\u0e22\u0e38\u0e1a\u0e31\u0e15\u0e23',
    unsupportedCardMessage:
      '\u0e02\u0e2d\u0e2d\u0e20\u0e31\u0e22 \u0e23\u0e30\u0e1a\u0e1a\u0e22\u0e31\u0e07\u0e44\u0e21\u0e48\u0e23\u0e2d\u0e07\u0e23\u0e31\u0e1a\u0e1b\u0e23\u0e30\u0e40\u0e20\u0e17\u0e1a\u0e31\u0e15\u0e23\u0e02\u0e2d\u0e07\u0e17\u0e48\u0e32\u0e19 \u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e25\u0e02\u0e1a\u0e31\u0e15\u0e23 VISA,MasterCard \u0e2b\u0e23\u0e37\u0e2d AMEX',
    paymentNotProcessed:
      '\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e17\u0e33\u0e01\u0e32\u0e23\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e44\u0e14\u0e49',
    redirectStatusFailure:
      '\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e17\u0e33\u0e01\u0e32\u0e23\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13\u0e44\u0e14\u0e49 \u0e01\u0e23\u0e38\u0e13\u0e32\u0e25\u0e2d\u0e07\u0e43\u0e2b\u0e21\u0e48\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07',
    continue:
      '\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e15\u0e48\u0e2d {{formattedCurrency}}',
    disclaimer: TRANSLATION_MISSING,
    tosTextBeforeContinue:
      "\u0e01\u0e32\u0e23\u0e04\u0e25\u0e34\u0e01\u0e1b\u0e38\u0e48\u0e21 '\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e15\u0e48\u0e2d' \u0e14\u0e49\u0e32\u0e19\u0e25\u0e48\u0e32\u0e07 \u0e41\u0e2a\u0e14\u0e07\u0e27\u0e48\u0e32\u0e04\u0e38\u0e13\u0e22\u0e2d\u0e21\u0e23\u0e31\u0e1a",
    authCheckoutStatement: TRANSLATION_MISSING,
    useNewCardTextBeforePayByBank: TRANSLATION_MISSING,
    usePayByBank: TRANSLATION_MISSING,
    payByBankLinkText: TRANSLATION_MISSING,
    payByBankHeader: TRANSLATION_MISSING,
    use: TRANSLATION_MISSING,
    achOnlyInfoTitle: 'Only bank payments are accepted',
    achOnlyInfoMsg: 'Cards in your wallet cannot be used for this purchase.',
    useNewPayment:
      '\u0e43\u0e0a\u0e49\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e43\u0e2b\u0e21\u0e48',
    useNewPaymentDropdownText:
      '\u0e43\u0e0a\u0e49\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e43\u0e2b\u0e21\u0e48',
    useNewPaymentTextBefore:
      '\u0e15\u0e23\u0e27\u0e08\u0e2a\u0e2d\u0e1a\u0e14\u0e49\u0e27\u0e22\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e17\u0e35\u0e48\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e44\u0e27\u0e49\u0e2b\u0e23\u0e37\u0e2d ',
    useNewPaymentLinkText:
      '\u0e43\u0e0a\u0e49\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e43\u0e2b\u0e21\u0e48',
    useNewPaymentTextBeforePayByBank:
      '\u0e15\u0e23\u0e27\u0e08\u0e2a\u0e2d\u0e1a\u0e14\u0e49\u0e27\u0e22\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e17\u0e35\u0e48\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e44\u0e27\u0e49\u0e2b\u0e23\u0e37\u0e2d, ',
    emptyNameMessage: TRANSLATION_MISSING,
    unsupportedCardErrorMessage: TRANSLATION_MISSING,
    preAuthorizedDebit: TRANSLATION_MISSING,
    card: TRANSLATION_MISSING,
    nextButton: TRANSLATION_MISSING,
    continueToDeposit: TRANSLATION_MISSING
  },
  wallet: {
    addNewPaymentMethodText: TRANSLATION_MISSING,
    addPaymentMethod: TRANSLATION_MISSING,
    makeDefault: TRANSLATION_MISSING,
    changeDefaultPMSuccess: TRANSLATION_MISSING,
    makeDefaultSuccessPM: TRANSLATION_MISSING,
    makeDefaultErrorPM: TRANSLATION_MISSING,
    removePMSuccess: TRANSLATION_MISSING,
    removePMError: TRANSLATION_MISSING,
    removePM: TRANSLATION_MISSING,
    removePMQuestion: TRANSLATION_MISSING,
    removePMText: TRANSLATION_MISSING,
    defaultCard: TRANSLATION_MISSING,
    defaultPM: TRANSLATION_MISSING,
    defaultCardQuestion: TRANSLATION_MISSING,
    defaultPMQuestion: TRANSLATION_MISSING,
    defaultCardText: TRANSLATION_MISSING,
    defaultPMText: TRANSLATION_MISSING,
    navigationLabel: TRANSLATION_MISSING,
    removeDefaultPMSuccess: TRANSLATION_MISSING,
    removeDefaultCardError: TRANSLATION_MISSING,
    removeDefaultPMError: TRANSLATION_MISSING,
    removeDefaultCard: TRANSLATION_MISSING,
    removeDefaultPM: TRANSLATION_MISSING,
    removeDefaultPMText: TRANSLATION_MISSING,
    removeDefaultPMButton: TRANSLATION_MISSING,
    removeDefaultSelectPM: TRANSLATION_MISSING,
    title: TRANSLATION_MISSING,
    expiration: TRANSLATION_MISSING,
    expires: TRANSLATION_MISSING,
    stripeDefaultError: TRANSLATION_MISSING,
    paymentMethod: TRANSLATION_MISSING,
    tosTextBefore: TRANSLATION_MISSING,
    tosTextBeforePM: TRANSLATION_MISSING,
    tosLinkText: TRANSLATION_MISSING,
    tosTextAfter: TRANSLATION_MISSING
  },
  transaction: {
    all: TRANSLATION_MISSING,
    amount: TRANSLATION_MISSING,
    date: TRANSLATION_MISSING,
    description: TRANSLATION_MISSING,
    displayingAll: TRANSLATION_MISSING,
    displayingDays: TRANSLATION_MISSING,
    displayingYear: TRANSLATION_MISSING,
    filter: TRANSLATION_MISSING,
    loadAll: TRANSLATION_MISSING,
    loadMore: TRANSLATION_MISSING,
    noTransactions: TRANSLATION_MISSING,
    paymentMethod: TRANSLATION_MISSING,
    refund: TRANSLATION_MISSING,
    searchBar: TRANSLATION_MISSING,
    timeFilter: TRANSLATION_MISSING,
    title: TRANSLATION_MISSING,
    pending: TRANSLATION_MISSING
  },
  mit: {
    confirmPurchase: TRANSLATION_MISSING,
    paymentSuccessful: TRANSLATION_MISSING,
    saveCardAlert: TRANSLATION_MISSING,
    title: TRANSLATION_MISSING,
    tosTextBefore: TRANSLATION_MISSING,
    tosLinkText: TRANSLATION_MISSING,
    tosTextAfter: '.',
    useNewCard: TRANSLATION_MISSING,
    useNewCardDropDownItem: TRANSLATION_MISSING,
    useNewCardTextBefore: TRANSLATION_MISSING,
    useNewCardLinkText: TRANSLATION_MISSING,
    useNewCardTextAfter: TRANSLATION_MISSING,
    walletSaveDisclosure: TRANSLATION_MISSING,
    useNewPayment:
      '\u0e43\u0e0a\u0e49\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e43\u0e2b\u0e21\u0e48',
    useNewPaymentDropdownText:
      '\u0e43\u0e0a\u0e49\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e43\u0e2b\u0e21\u0e48',
    useNewPaymentTextBefore:
      '\u0e15\u0e23\u0e27\u0e08\u0e2a\u0e2d\u0e1a\u0e14\u0e49\u0e27\u0e22\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e17\u0e35\u0e48\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e44\u0e27\u0e49\u0e2b\u0e23\u0e37\u0e2d ',
    useNewPaymentLinkText:
      '\u0e43\u0e0a\u0e49\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e43\u0e2b\u0e21\u0e48',
    successful:
      '\u0e1b\u0e23\u0e30\u0e2a\u0e1a\u0e04\u0e27\u0e32\u0e21\u0e2a\u0e33\u0e40\u0e23\u0e47\u0e08',
    savePaymentMethodAlert:
      '\u0e2b\u0e32\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e01\u0e32\u0e23\u0e43\u0e0a\u0e49\u0e27\u0e34\u0e18\u0e35\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e19\u0e35\u0e49\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e01\u0e32\u0e23\u0e0a\u0e33\u0e23\u0e30\u0e40\u0e07\u0e34\u0e19\u0e43\u0e19\u0e2d\u0e19\u0e32\u0e04\u0e15 \u0e08\u0e30\u0e15\u0e49\u0e2d\u0e07\u0e1a\u0e31\u0e19\u0e17\u0e36\u0e01\u0e44\u0e27\u0e49\u0e43\u0e19\u0e01\u0e23\u0e30\u0e40\u0e1b\u0e4b\u0e32\u0e40\u0e07\u0e34\u0e19\u0e02\u0e2d\u0e07\u0e04\u0e38\u0e13'
  }
};
